var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay",
    attrs: {
      "id": "overlay-project"
    }
  }, [_c('div', {
    staticClass: "panel-wrapper",
    attrs: {
      "id": "panel-wrapper-project"
    }
  }, [_vm.loadingSave ? _c('div', {
    staticClass: "loader-overlay"
  }, [_c('div', {
    staticClass: "loader units-add-loader"
  })]) : _vm._e(), _c('div', {
    attrs: {
      "id": "content-wrapper"
    }
  }, [_c('div', {
    staticClass: "sticky-header"
  }, [_c('v-row', {
    staticClass: "top-row",
    staticStyle: {
      "height": "24px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('h4', {
    staticStyle: {
      "height": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddUnits")) + " ")])]), _c('v-row', {
    staticStyle: {
      "width": "100%",
      "height": "42px",
      "margin-top": "32px",
      "padding-left": "32px"
    }
  }, [_c('div', {
    class: {
      'active-view': _vm.activeView === 'single',
      '': _vm.activeView !== 'single'
    },
    attrs: {
      "id": "singleView"
    },
    on: {
      "click": _vm.changeToSingle
    }
  }, [_vm.activeView === 'single' ? _c('p', {
    staticClass: "content-small view-link",
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddSingleUnit")) + " ")]) : _vm._e(), _vm.activeView !== 'single' ? _c('p', {
    staticClass: "content-small view-link",
    staticStyle: {
      "font-weight": "400"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddSingleUnit")) + " ")]) : _vm._e()]), _c('div', {
    class: {
      'active-view': _vm.activeView === 'bulk',
      '': _vm.activeView !== 'bulk'
    },
    attrs: {
      "id": "bulkView"
    },
    on: {
      "click": _vm.changeToBulk
    }
  }, [_vm.activeView === 'bulk' ? _c('p', {
    staticClass: "content-small view-link",
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddBulk")) + " ")]) : _vm._e(), _vm.activeView !== 'bulk' ? _c('p', {
    staticClass: "content-small view-link",
    staticStyle: {
      "font-weight": "400"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddBulk")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "separator-row-main",
    staticStyle: {
      "border": "0.1px solid #e6e8ec",
      "width": "560px"
    }
  })], 1), _vm.activeView === 'single' ? _c('div', [_c('v-row', {
    staticStyle: {
      "padding-top": "168px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("UnitDetails")))])]), _c('v-row', {
    staticStyle: {
      "margin-top": "24px",
      "padding-left": "32px",
      "padding-right": "32px",
      "gap": "12px"
    }
  }, [_vm.selectBuilding ? _c('BaseDropdown', {
    attrs: {
      "items": _vm.buildingItems,
      "show-dropdown-arrow": true,
      "dropdown-allow-null": false,
      "placeholder": _vm.$t('SelectBuilding'),
      "type": 'dropdown',
      "required": "true",
      "error": _vm.showErrors && !_vm.isFieldValid('selectedBuilding') ? 'not-valid' : '',
      "error-message": _vm.$t('FormErrors.' + _vm.errors.selectedBuilding)
    },
    model: {
      value: _vm.form.selectedBuilding,
      callback: function ($$v) {
        _vm.$set(_vm.form, "selectedBuilding", $$v);
      },
      expression: "form.selectedBuilding"
    }
  }) : _vm._e(), _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('Floor'),
      "type": "number",
      "required": true,
      "error": _vm.showErrors && !_vm.isFieldValid('floor') ? 'not-valid' : '',
      "error-message": _vm.$t('FormErrors.' + _vm.errors.floor)
    },
    model: {
      value: _vm.form.floor,
      callback: function ($$v) {
        _vm.$set(_vm.form, "floor", $$v);
      },
      expression: "form.floor"
    }
  })], 1), _c('v-row', {
    staticClass: "field-row",
    staticStyle: {
      "margin-top": "14px !important",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('UnitSize'),
      "type": "number",
      "icon-right": 'm2_20.svg'
    },
    model: {
      value: _vm.form.unitSize,
      callback: function ($$v) {
        _vm.$set(_vm.form, "unitSize", $$v);
      },
      expression: "form.unitSize"
    }
  })], 1), _c('v-row', {
    staticClass: "field-row",
    staticStyle: {
      "margin-top": "14px !important",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('BaseDropdown', {
    attrs: {
      "items": _vm.leasables,
      "show-dropdown-arrow": true,
      "dropdown-allow-null": false,
      "placeholder": _vm.$t('Leasable'),
      "type": 'dropdown'
    },
    model: {
      value: _vm.form.leasable,
      callback: function ($$v) {
        _vm.$set(_vm.form, "leasable", $$v);
      },
      expression: "form.leasable"
    }
  })], 1), _c('div', {
    staticClass: "buttons-container"
  }, [_c('v-divider'), _c('div', {
    staticClass: "buttons-row"
  }, [_c('button', {
    staticClass: "cancel",
    on: {
      "click": function ($event) {
        return _vm.closeModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _c('button', {
    staticClass: "add-button",
    on: {
      "click": function ($event) {
        return _vm.saveUnits(_vm.form);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddUnit")) + " ")])])], 1)], 1) : _vm._e(), _vm.activeView === 'bulk' ? _c('div', [_c('v-row', {
    staticStyle: {
      "padding-top": "168px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("UnitDetails")))])]), _c('v-row', {
    staticStyle: {
      "margin-top": "14px !important",
      "padding-left": "32px",
      "padding-right": "32px",
      "gap": "12px"
    }
  }, [_vm.selectBuilding ? _c('BaseDropdown', {
    attrs: {
      "items": _vm.buildingItems,
      "show-dropdown-arrow": true,
      "dropdown-allow-null": false,
      "placeholder": _vm.$t('SelectBuilding'),
      "type": 'dropdown',
      "required": "true",
      "error": _vm.showBulkErrors && !_vm.isFieldValidBulk('selectedBuilding') ? 'not-valid' : '',
      "error-message": _vm.$t('FormErrors.' + _vm.bulkErrors.selectedBuilding)
    },
    model: {
      value: _vm.formBulk.selectedBuilding,
      callback: function ($$v) {
        _vm.$set(_vm.formBulk, "selectedBuilding", $$v);
      },
      expression: "formBulk.selectedBuilding"
    }
  }) : _vm._e(), _c('v-row', {
    staticStyle: {
      "flex-wrap": "nowrap",
      "gap": "12px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('FirstFloor'),
      "type": "number",
      "required": true,
      "error": _vm.showBulkErrors && !_vm.isFieldValidBulk('floorFrom') ? 'not-valid' : '',
      "error-message": _vm.$t('FormErrors.' + _vm.bulkErrors.floorFrom)
    },
    model: {
      value: _vm.formBulk.floorFrom,
      callback: function ($$v) {
        _vm.$set(_vm.formBulk, "floorFrom", $$v);
      },
      expression: "formBulk.floorFrom"
    }
  }), _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('LastFloor'),
      "type": "number",
      "required": true,
      "error": _vm.showBulkErrors && !_vm.isFieldValidBulk('floorTo') ? 'not-valid' : '',
      "error-message": _vm.$t('FormErrors.' + _vm.bulkErrors.floorTo)
    },
    model: {
      value: _vm.formBulk.floorTo,
      callback: function ($$v) {
        _vm.$set(_vm.formBulk, "floorTo", $$v);
      },
      expression: "formBulk.floorTo"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "margin-top": "0px",
      "gap": "12px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('UnitsPerFloor'),
      "type": "number",
      "required": true,
      "error": _vm.showBulkErrors && !_vm.isFieldValidBulk('unitsPerFloor') ? 'not-valid' : '',
      "error-message": _vm.$t('FormErrors.' + _vm.bulkErrors.unitsPerFloor)
    },
    model: {
      value: _vm.formBulk.unitsPerFloor,
      callback: function ($$v) {
        _vm.$set(_vm.formBulk, "unitsPerFloor", $$v);
      },
      expression: "formBulk.unitsPerFloor"
    }
  }), _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('UnitSize'),
      "type": "number",
      "icon-right": 'm2_20.svg'
    },
    model: {
      value: _vm.formBulk.unitSize,
      callback: function ($$v) {
        _vm.$set(_vm.formBulk, "unitSize", $$v);
      },
      expression: "formBulk.unitSize"
    }
  })], 1), _c('BaseDropdown', {
    attrs: {
      "items": _vm.leasables,
      "show-dropdown-arrow": true,
      "dropdown-allow-null": false,
      "placeholder": _vm.$t('Leasable'),
      "type": 'dropdown'
    },
    model: {
      value: _vm.formBulk.leasable,
      callback: function ($$v) {
        _vm.$set(_vm.formBulk, "leasable", $$v);
      },
      expression: "formBulk.leasable"
    }
  })], 1), _c('div', {
    staticClass: "buttons-container"
  }, [_c('v-divider'), _c('div', {
    staticClass: "buttons-row"
  }, [_c('button', {
    staticClass: "cancel",
    on: {
      "click": function ($event) {
        return _vm.closeModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _c('button', {
    staticClass: "add-button",
    on: {
      "click": _vm.saveUnits
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddUnit")) + " ")])])], 1)], 1) : _vm._e()])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }