<template>
  <div @click="closePanel" class="user-panel-overlay" id="user-overlay">
    <div class="user-panel">
      <div class="property-panel-header" id="userpanel-header">
        <v-row
            style="
            flex-wrap: nowrap;
            gap: 4px;
            display: flex;
            flex-direction: column;
          "
        >
          <h4>{{ contract.contactPerson.contactName }}</h4>
          <p class="content">{{ contract.contractNumber }}</p>
        </v-row>

        <v-row style="display: contents">
          <EditButton @clicked="editContract(contract)"/>
        </v-row>
      </div>

      <div class="user-panel-body" id="user-body">
        <div class="main-info-section">
          <div class="main-info-section">
            <div
                v-if="
                contract.paymentInterval ||
                contract.rentPrice ||
                contract.rentPricePerSquareMeter
              "
                style="
                border-radius: 8px;
                width: 496px;
                height: 112px;
                border: 1px solid #e6e8ec;
                margin-bottom: 32px;
              "
            >
              <v-row
                  class="user-field-row"
                  style="
                  height: 56px;
                  border-bottom: 1px solid #e6e8ec;
                  display: flex;
                  align-content: center;
                  padding-left: 16px;
                "
              >
                <p class="field-name">
                  {{ findPaymentInterval(contract.paymentInterval) }}
                </p>

                <p
                    v-if="contract.rentPrice"
                    class="field-value font-20-medium-black"
                    style="font-size: 20px"
                >
                  {{ contract.rentPrice }} €
                </p>
              </v-row>
              <v-row
                  v-if="contract.paymentDate && contract.paymentInterval"
                  class="user-field-row"
                  style="
                  height: 56px;
                  display: flex;
                  align-content: center;
                  padding-left: 16px;
                  margin-top: 0px;
                "
              >
                <p class="field-name">{{ $t("NextPaymentDate") }}</p>
                <p class="field-value font-20-medium-black" style="font-size: 20px">
                  {{
                    nextPaymentDate(
                        contract.paymentDate,
                        contract.paymentInterval
                    )
                  }}
                </p>
              </v-row>
              <v-row
                  v-if="!contract.paymentDate || !contract.paymentInterval"
                  class="user-field-row"
                  style="
                  height: 56px;
                  display: flex;
                  align-content: center;
                  padding-left: 16px;
                  margin-top: 0px;
                "
              >
                <p class="field-name">{{ $t("NextPaymentDate") }}</p>
                <p class="field-value font-20-medium-black" style="font-size: 20px">
                  -
                </p>
              </v-row>
            </div>
          </div>
          <!--  Need to implement table with chosenInterval and value of it, also calculate nextPaymentDate depend on chosenInterval -->
          <h5 class="main-info-title">{{ $t("Subblock.GeneralData") }}</h5>
          <v-row
              v-if="contract.contactPerson.company_name"
              class="user-field-row"
          >
            <p class="field-name">{{ $t("Tenant") }}</p>
            <p
                class="field-value link"
                @click="openOrganization(contract.contactPerson)"
                style="
                text-align: initial;
                word-break: break-word;
                cursor: pointer;
              "
            >
              {{ contract.contactPerson.company_name }}
            </p>
            <!--   <p
              v-if="contract.contactPerson.contactName"
              class="field-value link"
              style="text-align: initial; word-break: break-word"
              @click="openCustomerDetailpanel(contract.contactPerson)"
            >
              {{ contract.contactPerson.contactName }}
            </p> -->
          </v-row>
          <v-row
              v-if="contract.contactPerson.contactName"
              class="user-field-row"
          >
            <p class="field-name">{{ $t("Client") }}</p>
            <p
                class="field-value link"
                style="
                text-align: initial;
                word-break: break-word;
                cursor: pointer;
              "
                @click="openCustomerDetailpanel(contract.contactPerson)"
            >
              {{ contract.contactPerson.contactName }}
            </p>
          </v-row>

          <v-row v-if="building.addressObjects" class="user-field-row">
            <p class="field-name">{{ $t("Address") }}</p>
            <p class="field-value">{{ building.addressObjects[0].address }}</p>
          </v-row>
          <v-row v-if="contract.contractNumber" class="user-field-row">
            <p class="field-name">{{ $t("ContractNumber") }}</p>
            <p class="field-value">
              {{ contract.contractNumber }}
            </p>
          </v-row>
          <v-row v-if="contract.signingDate" class="user-field-row">
            <p class="field-name">{{ $t("SigningDate") }}</p>
            <p class="field-value">
              {{ formatDate(contract.signingDate) }}
            </p>
          </v-row>
          <v-row v-if="contract.transferDate" class="user-field-row">
            <p class="field-name">{{ $t("TransferDate") }}</p>
            <p class="field-value">
              {{ formatDate(contract.transferDate) }}
            </p>
          </v-row>
          <v-row v-if="contract.startOfRentPayments" class="user-field-row">
            <p class="field-name">{{ $t("StartOfRentPayments") }}</p>
            <p class="field-value">
              {{ formatDate(contract.startOfRentPayments) }}
            </p>
          </v-row>
          <v-row v-if="contract.endOfRentalPeriod" class="user-field-row">
            <p class="field-name">{{ $t("EndOfRentalPeriod") }}</p>
            <p class="field-value">
              {{ formatDate(contract.endOfRentalPeriod) }}
            </p>
          </v-row>
          <v-row v-if="contract.guaranteeEnds" class="user-field-row">
            <p class="field-name">{{ $t("GuaranteeEnds") }}</p>
            <p class="field-value">{{ formatDate(contract.guaranteeEnds) }}</p>
          </v-row>
          <v-row v-if="contract.insuranceEnds" class="user-field-row">
            <p class="field-name">{{ $t("InsuranceEnds") }}</p>
            <p class="field-value">{{ formatDate(contract.insuranceEnds) }}</p>
          </v-row>
        </div>
        <div style="margin-top: 32px" class="main-info-section">
          <h5 class="main-info-title">{{ $t("RentalUnits") }}</h5>
          <v-row v-if="building.buildingName" class="user-field-row">
            <p class="field-name">{{ $t("Building") }}</p>
            <p class="field-value">{{ building.buildingName }}</p>
          </v-row>
          <v-row v-if="unit.unitName" class="user-field-row">
            <p class="field-name">{{ $t("Unit") }}</p>
            <p class="field-value">
              {{ unit.unitName }}
            </p>
          </v-row>
          <v-row v-if="unit.floor" class="user-field-row">
            <p class="field-name">{{ $t("Location") }}</p>
            <p class="field-value">
              {{ $t("Floor") + " " + unit.floor }}
            </p>
          </v-row>
        </div>
        <div style="margin-top: 32px" class="main-info-section">
          <h5 class="main-info-title">{{ $t("RentAndFees") }}</h5>
          <v-row v-if="unit.unitSize" class="user-field-row">
            <p class="field-name">{{ $t("RentalSpace") }}</p>
            <p class="field-value">{{ unit.unitSize }} m²</p>
          </v-row>
          <v-row
              v-if="contract.rentPerM2 && contract.rentPrice"
              class="user-field-row"
          >
            <p class="field-name">{{ $t("RentPerM2") }}</p>
            <p class="field-value">{{ contract.rentPricePerSquareMeter }} €/m²</p>
          </v-row>
          <v-row
              v-if="!contract.rentPerM2 && contract.rentPrice"
              class="user-field-row"
          >
            <p class="field-name">{{ $t("RentPrice") }}</p>
            <p class="field-value">{{ contract.rentPrice }} €</p>
          </v-row>
          <v-row
              v-if="contract.targetPrice && !contract.rentPerM2"
              class="user-field-row"
          >
            <p class="field-name">{{ $t("TargetPrice") }}</p>
            <p class="field-value">
              {{ contract.targetPrice }}
            </p>
          </v-row>
          <v-row
              v-if="contract.targetPrice && contract.rentPerM2"
              class="user-field-row"
          >
            <p class="field-name">{{ $t("Target rent") }}</p>
            <p class="field-value">
              {{ calculatePerSquareMeter(contract.targetPrice) }} €/m²
            </p>
          </v-row>
          <v-row v-if="contract.specialFee" class="user-field-row">
            <p class="field-name">{{ $t("SpecialFee") }}</p>
            <p class="field-value">{{ contract.specialFee }} €</p>
          </v-row>
          <v-row v-if="contract.cleaningFee" class="user-field-row">
            <p class="field-name">{{ $t("CleaningFee") }}</p>
            <p class="field-value">{{ contract.cleaningFee }} €</p>
          </v-row>
          <v-row v-if="contract.paymentInterval" class="user-field-row">
            <p class="field-name">{{ $t("PaymentInterval") }}</p>
            <p class="field-value">
              {{ formatPaymentInterval(contract.paymentInterval) }}
            </p>
          </v-row>
          <v-row v-if="contract.paymentDate" class="user-field-row">
            <p class="field-name">{{ $t("PaymentDate") }}</p>
            <p class="field-value">
              {{ getOnlyDayNumber(contract.paymentDate) }}
            </p>
          </v-row>
        </div>
      </div>
    </div>
    <OrganizationSidepanel
        :selected-organization="clickedOrg"
        v-if="orgDetailOpen"
        :project-view="true"
        @panelClosed="orgDetailClose()"
    />
    <ClientSidepanel
        v-if="detailPanelOpen"
        @panelClosed="closeCustomerDetailPanel()"
        :customerData="sidePanelData"
        :from-project-general="true"
    />
  </div>
</template>

<script>
//import axios from "axios";
import "lightgallery.js";
import "lightgallery.js/dist/css/lightgallery.css";
import moment from "moment";
import EditButton from "@/components/common/EditButton";
import OrganizationSidepanel from "@/components/Organization/OrganizationSidepanel";
import ClientSidepanel from "@/components/Kliendiregister/ClientSidepanel.vue";

export default {
  name: "ContractDetailPanel",
  props: ["contract", "building", "unit", "contractId"],
  components: {
    EditButton,
    OrganizationSidepanel,
    ClientSidepanel,
  },
  data: () => ({
    orgDetailOpen: false,
    clickedOrg: null,
    detailPanelOpen: false,
    sidePanelData: null,
  }),
  created() {
    if (this.contract) {
      this.stickyHeader();
    }
  },
  mounted() {
    document.onkeydown = (evt) => {
      evt = evt || window.event;
      if (evt.key === "Escape") {
        this.closePanel();
        document.onkeydown = null;
      }
    };
    document.documentElement.style.overflow = "hidden";
  },
  beforeDestroy() {
    document.onkeydown = null;
    document.documentElement.style.overflow = "visible";
  },
  computed: {},
  watch: {},
  methods: {
    editContract(contract) {
      this.$emit("editContract", {contract: contract, unitItem: this.unit});
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    closePanel(e) {
      if (!e) {
        this.$emit("overlayClicked");
      }
      if (e.target !== e.currentTarget) return;
      this.$emit("overlayClicked");
    },
    async stickyHeader() {
      let headerHeight = null;
      setTimeout(() => {
        headerHeight = document.getElementById("userpanel-header").offsetHeight;
        let marginTop = headerHeight + "px";
        const checkForBodyLoading = setInterval(() => {
          let bodyElement = document.getElementById("user-body");
          if (bodyElement) {
            document.getElementById("user-body").style.marginTop = marginTop;
            clearInterval(checkForBodyLoading);
          }
        }, 50);
        console.log(headerHeight);
      }, 50);
    },

    formatPaymentInterval(paymentInterval) {
      if (paymentInterval == "weekly") {
        return this.$t("Weekly");
      } else if (paymentInterval == "monthly") {
        return this.$t("Monthly");
      } else if (paymentInterval == "quarterly") {
        return this.$t("Quarterly");
      } else if (paymentInterval == "yearly") {
        return this.$t("Yearly");
      }
    },
    nextPaymentDate(paymentDate, paymentInterval) {
      if (paymentInterval === "monthly") {
        paymentDate = moment(paymentDate).add(1, "months").format("DD.MM.YYYY");
      } else if (paymentInterval === "yearly") {
        paymentDate = moment(paymentDate).add(1, "years").format("DD.MM.YYYY");
      } else if (paymentInterval === "quarterly") {
        paymentDate = moment(paymentDate).add(3, "months").format("DD.MM.YYYY");
      } else if (paymentInterval === "weekly") {
        paymentDate = moment(paymentDate).add(7, "days").format("DD.MM.YYYY");
      }
      return paymentDate;
    },
    findPaymentInterval(paymentInterval) {
      if (paymentInterval === "monthly") {
        paymentInterval = this.$t("MonthlyRent");
      } else if (paymentInterval === "yearly") {
        paymentInterval = this.$t("YearlyRent");
      } else if (paymentInterval === "quarterly") {
        paymentInterval = this.$t("QuarterlyRent");
      } else if (paymentInterval === "weekly") {
        paymentInterval = this.$t("WeeklyRent");
      }
      return paymentInterval;
    },
    openOrganization(orgRegCode) {
      if (/^\d+$/.test(orgRegCode.reg_code)) {
        this.clickedOrg = orgRegCode.reg_code;
        this.orgDetailOpen = true;
        document.documentElement.style.overflow = "hidden";
      } else {
        this.clickedOrg = orgRegCode;
        this.orgDetailOpen = true;
        document.documentElement.style.overflow = "hidden";
      }
    },
    orgDetailClose() {
      this.orgDetailOpen = false;
      //document.documentElement.style.overflow = "hidden";
    },
    openCustomerDetailpanel(client) {
      this.sidePanelData = client;
      this.detailPanelOpen = true;
    },
    closeCustomerDetailPanel() {
      document.documentElement.style.overflow = "hidden";
      this.detailPanelOpen = false;
    },
    getOnlyDayNumber(paymentDate) {
      var day = moment(paymentDate).format("D");
      /*  var j = day % 10,
        k = day % 100;
      if (j == 1 && k != 11) {
        return day + "st";
      }
      if (j == 2 && k != 12) {
        return day + "nd";
      }
      if (j == 3 && k != 13) {
        return day + "rd";
      } */
      return day;
    },
    calculatePerSquareMeter(target) {
      console.log("CALCULATING", target);
      return (target / this.unit.unitSize).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.user-panel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 8;
}

.user-panel {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  bottom: 0;
  background-color: #ffffff;
  z-index: 7;
  width: 560px;
  overflow-x: hidden;
  overflow-y: scroll;
  border: 1px solid #e6e8ec;
}

.property-panel-header {
  display: flex;
  position: fixed;
  background: white;
  padding: 48px 32px 24px;
  width: 560px;
  border-bottom: 1px solid #e6e8ec;

  h4 {
    max-width: 392px;
    /*overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;*/
  }
}

.user-panel-body {
  padding: 32px 32px 64px;
  overflow: hidden;
}

.main-info {
  padding-bottom: 32px;
  border-bottom: 1px solid #e6e8ec;
}

.user-field-row {
  gap: 24px;
}

.field-name {
  color: #75787a;
  font-family: "Inter", sans-serif;
}

.field-name,
.field-value {
  width: 208px;
  font-size: 14px;
  line-height: 20px;
}

.grey-950 {
  color: #75787a;
}

.user-status-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
}

.statusLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px;
  color: white;
  height: 20px;
  max-width: fit-content;
  border-radius: 4px;
  cursor: default;
  white-space: nowrap;
}

.main-info-title {
  margin-bottom: 24px;
}

.profile-picture {
  max-width: 140px;
  height: auto;
  cursor: pointer;
  border-radius: 8px;
}

.re-invite-button {
  cursor: pointer;
}
</style>
