var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user-panel-overlay",
    attrs: {
      "id": "user-overlay"
    },
    on: {
      "click": _vm.closePanel
    }
  }, [_vm.propertyLoaded ? _c('div', {
    staticClass: "user-panel"
  }, [_c('div', {
    staticClass: "property-panel-header",
    attrs: {
      "id": "userpanel-header"
    }
  }, [_c('v-row', [_c('h4', [_vm._v(_vm._s(_vm.property.name))])]), _c('v-row', {
    staticStyle: {
      "display": "contents"
    }
  }, [_c('EditButton', {
    on: {
      "clicked": function ($event) {
        return _vm.editProperty(_vm.property);
      }
    }
  })], 1)], 1), _c('div', {
    staticClass: "user-panel-body",
    attrs: {
      "id": "user-body"
    }
  }, [_c('div', {
    staticClass: "main-info-section"
  }, [_c('h5', {
    staticClass: "main-info-title"
  }, [_vm._v(_vm._s(_vm.$t("Subblock.GeneralData")))]), _vm.property.name ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("PropertyName")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.property.name))])]) : _vm._e(), _vm.property.address ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Address")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.property.address))])]) : _vm._e(), _vm.property.value ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Value")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.property.value))])]) : _vm._e(), _vm.property.area ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("PropertyArea")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.property.area) + " m²")])]) : _vm._e(), _vm.property.closedNetArea ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("ClosedNetArea")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.property.closedNetArea) + " m²")])]) : _vm._e()], 1), _c('div', {
    staticClass: "main-info-section",
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h5', {
    staticClass: "main-info-title"
  }, [_vm._v(_vm._s(_vm.$t("AssetType")))]), _vm.property.businessId ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("BusinessId")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.property.businessId))])]) : _vm._e(), _vm.property.assetType ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("AssetType")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.formatPropertyType(_vm.property.assetType)) + " ")])]) : _vm._e(), _vm.property.assetClass ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("AssetClass")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.formatAssetClass(_vm.property.assetClass)) + " ")])]) : _vm._e()], 1)])]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }