var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user-panel-overlay",
    attrs: {
      "id": "user-overlay"
    },
    on: {
      "click": _vm.closePanel
    }
  }, [_c('div', {
    staticClass: "user-panel"
  }, [_c('div', {
    staticClass: "property-panel-header",
    attrs: {
      "id": "userpanel-header"
    }
  }, [_c('v-row', {
    staticStyle: {
      "flex-wrap": "nowrap",
      "gap": "4px",
      "display": "flex",
      "flex-direction": "column"
    }
  }, [_c('h4', [_vm._v(_vm._s(_vm.contract.contactPerson.contactName))]), _c('p', {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.contract.contractNumber))])]), _c('v-row', {
    staticStyle: {
      "display": "contents"
    }
  }, [_c('EditButton', {
    on: {
      "clicked": function ($event) {
        return _vm.editContract(_vm.contract);
      }
    }
  })], 1)], 1), _c('div', {
    staticClass: "user-panel-body",
    attrs: {
      "id": "user-body"
    }
  }, [_c('div', {
    staticClass: "main-info-section"
  }, [_c('div', {
    staticClass: "main-info-section"
  }, [_vm.contract.paymentInterval || _vm.contract.rentPrice || _vm.contract.rentPricePerSquareMeter ? _c('div', {
    staticStyle: {
      "border-radius": "8px",
      "width": "496px",
      "height": "112px",
      "border": "1px solid #e6e8ec",
      "margin-bottom": "32px"
    }
  }, [_c('v-row', {
    staticClass: "user-field-row",
    staticStyle: {
      "height": "56px",
      "border-bottom": "1px solid #e6e8ec",
      "display": "flex",
      "align-content": "center",
      "padding-left": "16px"
    }
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.findPaymentInterval(_vm.contract.paymentInterval)) + " ")]), _vm.contract.rentPrice ? _c('p', {
    staticClass: "field-value font-20-medium-black",
    staticStyle: {
      "font-size": "20px"
    }
  }, [_vm._v(" " + _vm._s(_vm.contract.rentPrice) + " € ")]) : _vm._e()]), _vm.contract.paymentDate && _vm.contract.paymentInterval ? _c('v-row', {
    staticClass: "user-field-row",
    staticStyle: {
      "height": "56px",
      "display": "flex",
      "align-content": "center",
      "padding-left": "16px",
      "margin-top": "0px"
    }
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("NextPaymentDate")))]), _c('p', {
    staticClass: "field-value font-20-medium-black",
    staticStyle: {
      "font-size": "20px"
    }
  }, [_vm._v(" " + _vm._s(_vm.nextPaymentDate(_vm.contract.paymentDate, _vm.contract.paymentInterval)) + " ")])]) : _vm._e(), !_vm.contract.paymentDate || !_vm.contract.paymentInterval ? _c('v-row', {
    staticClass: "user-field-row",
    staticStyle: {
      "height": "56px",
      "display": "flex",
      "align-content": "center",
      "padding-left": "16px",
      "margin-top": "0px"
    }
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("NextPaymentDate")))]), _c('p', {
    staticClass: "field-value font-20-medium-black",
    staticStyle: {
      "font-size": "20px"
    }
  }, [_vm._v(" - ")])]) : _vm._e()], 1) : _vm._e()]), _c('h5', {
    staticClass: "main-info-title"
  }, [_vm._v(_vm._s(_vm.$t("Subblock.GeneralData")))]), _vm.contract.contactPerson.company_name ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Tenant")))]), _c('p', {
    staticClass: "field-value link",
    staticStyle: {
      "text-align": "initial",
      "word-break": "break-word",
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.openOrganization(_vm.contract.contactPerson);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.contract.contactPerson.company_name) + " ")])]) : _vm._e(), _vm.contract.contactPerson.contactName ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Client")))]), _c('p', {
    staticClass: "field-value link",
    staticStyle: {
      "text-align": "initial",
      "word-break": "break-word",
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.openCustomerDetailpanel(_vm.contract.contactPerson);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.contract.contactPerson.contactName) + " ")])]) : _vm._e(), _vm.building.addressObjects ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Address")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.building.addressObjects[0].address))])]) : _vm._e(), _vm.contract.contractNumber ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("ContractNumber")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.contract.contractNumber) + " ")])]) : _vm._e(), _vm.contract.signingDate ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("SigningDate")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.contract.signingDate)) + " ")])]) : _vm._e(), _vm.contract.transferDate ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("TransferDate")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.contract.transferDate)) + " ")])]) : _vm._e(), _vm.contract.startOfRentPayments ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("StartOfRentPayments")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.contract.startOfRentPayments)) + " ")])]) : _vm._e(), _vm.contract.endOfRentalPeriod ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("EndOfRentalPeriod")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.contract.endOfRentalPeriod)) + " ")])]) : _vm._e(), _vm.contract.guaranteeEnds ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("GuaranteeEnds")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.formatDate(_vm.contract.guaranteeEnds)))])]) : _vm._e(), _vm.contract.insuranceEnds ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("InsuranceEnds")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.formatDate(_vm.contract.insuranceEnds)))])]) : _vm._e()], 1), _c('div', {
    staticClass: "main-info-section",
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h5', {
    staticClass: "main-info-title"
  }, [_vm._v(_vm._s(_vm.$t("RentalUnits")))]), _vm.building.buildingName ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Building")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.building.buildingName))])]) : _vm._e(), _vm.unit.unitName ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Unit")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.unit.unitName) + " ")])]) : _vm._e(), _vm.unit.floor ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Location")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.$t("Floor") + " " + _vm.unit.floor) + " ")])]) : _vm._e()], 1), _c('div', {
    staticClass: "main-info-section",
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h5', {
    staticClass: "main-info-title"
  }, [_vm._v(_vm._s(_vm.$t("RentAndFees")))]), _vm.unit.unitSize ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("RentalSpace")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.unit.unitSize) + " m²")])]) : _vm._e(), _vm.contract.rentPerM2 && _vm.contract.rentPrice ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("RentPerM2")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.contract.rentPricePerSquareMeter) + " €/m²")])]) : _vm._e(), !_vm.contract.rentPerM2 && _vm.contract.rentPrice ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("RentPrice")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.contract.rentPrice) + " €")])]) : _vm._e(), _vm.contract.targetPrice && !_vm.contract.rentPerM2 ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("TargetPrice")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.contract.targetPrice) + " ")])]) : _vm._e(), _vm.contract.targetPrice && _vm.contract.rentPerM2 ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Target rent")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.calculatePerSquareMeter(_vm.contract.targetPrice)) + " €/m² ")])]) : _vm._e(), _vm.contract.specialFee ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("SpecialFee")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.contract.specialFee) + " €")])]) : _vm._e(), _vm.contract.cleaningFee ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("CleaningFee")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.contract.cleaningFee) + " €")])]) : _vm._e(), _vm.contract.paymentInterval ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("PaymentInterval")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.formatPaymentInterval(_vm.contract.paymentInterval)) + " ")])]) : _vm._e(), _vm.contract.paymentDate ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("PaymentDate")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.getOnlyDayNumber(_vm.contract.paymentDate)) + " ")])]) : _vm._e()], 1)])]), _vm.orgDetailOpen ? _c('OrganizationSidepanel', {
    attrs: {
      "selected-organization": _vm.clickedOrg,
      "project-view": true
    },
    on: {
      "panelClosed": function ($event) {
        return _vm.orgDetailClose();
      }
    }
  }) : _vm._e(), _vm.detailPanelOpen ? _c('ClientSidepanel', {
    attrs: {
      "customerData": _vm.sidePanelData,
      "from-project-general": true
    },
    on: {
      "panelClosed": function ($event) {
        return _vm.closeCustomerDetailPanel();
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }