<template>
  <div class="overlay" id="overlay-property">
    <div id="panel-wrapper-property" class="modal-wrapper">
      <!--  <div
        @click="closeContactAdd"
        style="padding-left: 24px"
        id="wrapped-header-property"
      >
        <h3 style="align-self: center">
          {{
            isEdit
              ? $t("Edit") + " " + $t("Property").toLowerCase()
              : $t("AddNewProperty")
          }}
        </h3>
      </div> -->

      <div class="content-overlay" id="content-wrapper">
        <div class="sticky-header" style="z-index: 126">
          <v-row
            class="top-row"
            style="height: 24px; padding-left: 32px; padding-right: 32px"
            ><h4 v-if="!isEdit" style="height: 24px">
              {{ $t("AddNewBuilding") }}
            </h4>
            <h4 v-if="isEdit" style="height: 24px">
              {{ $t("ChangeNewBuilding") }}
            </h4>
          </v-row>
        </div>
        <v-divider
          style="top: 97px; z-index: 126; position: fixed; width: 560px"
        />
        <div style="padding: 121px 0px">
          <v-row
            v-if="!isEdit"
            style="margin-top: 0px; padding-left: 32px; padding-right: 32px"
          >
            <h6>{{ $t("Address") }}</h6>
          </v-row>

          <v-row
            v-if="!isEdit"
            style="
              gap: 8px;
              margin-top: 24px;
              padding-left: 32px;
              padding-right: 32px;
            "
          >
            <!--   <div style="margin-bottom: 16px">
              <BaseToggle
                @click="fillFieldWithPropertyAddress"
                :label="$t('UseMyPropertyAddress')"
              />
            </div> -->
            <!--  <BaseInput
              :placeholder="$t('BuildingAddress')"
              v-model="form.buildingAddress"
            /> -->
            <div style="z-index: 125; width: 100%">
              <AddressFinder
                v-if="!isEdit"
                :object="form.addressObjects ? form.addressObjects[0] : ''"
                :placeholder="$t('Address')"
                :show-error="showErrors && !isFieldValid('addressObject')"
                :error-message="
                  errors && errors.addressObject ? errors.addressObject : ''
                "
                :disabled="isEdit"
                @setObject="setAddressObject"
                @removeObject="removeAddressObject"
              />
            </div>
          </v-row>

          <v-row
            v-bind:class="{
              'regular-wrapper': !isEdit,
              'edit-wrapper': isEdit,
            }"
          >
            <h6>{{ $t("GeneralInformation") }}</h6>
          </v-row>
          <v-row style="gap: 8px; padding-left: 32px; padding-right: 32px">
            <BaseInput
              :id="'property-owner-name-field'"
              :placeholder="$t('BuildingName')"
              v-model="form.buildingName"
            />
            <!--  <v-row style="flex-wrap: nowrap; gap: 8px">
              <BaseInput
                :id="'property-owner-address-field'"
                :placeholder="$t('Floors')"
                v-model="form.floors" />
              <BaseInput
                :id="'property-owner-value-field'"
                :placeholder="$t('Units')"
                v-model="form.units"
            /></v-row>
 -->
            <BaseDropdown
              icon-left="calendar_20_20.svg"
              :placeholder="$t('ConstructionYear')"
              v-model="form.constructionYear"
              :show-dropdown-arrow="true"
              type="date"
            />
            <BaseDropdown
              icon-left="calendar_20_20.svg"
              :placeholder="$t('RefurbishmentYear')"
              v-model="form.refurbishmentYear"
              :show-dropdown-arrow="true"
              type="date"
            />
            <BaseInput
              :id="'property-owner-size-field'"
              :placeholder="$t('BuildingCostCenter')"
              v-model="form.buildingCostCenter"
            />
            <BaseInput
              :id="'property-owner-closedNetArea-field'"
              :placeholder="$t('AddOnFactor')"
              v-model="form.addOnFactor"
              :icon-right="'percentage.svg'"
            />
            <!--   <BaseTextarea
              :id="'property-add-description'"
              v-model="form.description"
              :placeholder="$t('Decription')"
            /> -->
          </v-row>

          <v-row
            style="margin-top: 32px; padding-left: 32px; padding-right: 32px"
          >
            <h6>{{ $t("Features") }}</h6>
          </v-row>

          <v-row
            style="
              gap: 8px;
              margin-top: 24px;
              padding-left: 32px;
              padding-right: 32px;
            "
          >
            <BaseDropdown
              v-model="form.buildingHeating"
              :items="buildingHeatings"
              :show-dropdown-arrow="true"
              :dropdown-allow-null="false"
              :placeholder="$t('BuildingHeating')"
              :type="'dropdown'"
            /><!-- <BaseDropdown
              v-if="form.buildingHeating === 'yes'"
              v-model="form.buildingHeatingType"
              :items="buildingHeatingTypes"
              :show-dropdown-arrow="true"
              :dropdown-allow-null="false"
              :placeholder="$t('BuildingHeatingType')"
              :type="'dropdown'"
            /> -->
            <BaseInput
              v-if="form.buildingHeating === 'yes'"
              :placeholder="$t('BuildingHeatingType')"
              v-model="form.buildingHeatingType"
            /><BaseDropdown
              v-model="form.buildingCooling"
              :items="buildingCoolings"
              :show-dropdown-arrow="true"
              :dropdown-allow-null="false"
              :placeholder="$t('BuildingCooling')"
              :type="'dropdown'"
            />
            <!--  <BaseDropdown
              v-if="form.buildingCooling === 'yes'"
              v-model="form.coolingType"
              :items="coolingTypes"
              :show-dropdown-arrow="true"
              :dropdown-allow-null="false"
              :placeholder="$t('CoolingType')"
              :type="'dropdown'"
            /> -->
            <BaseInput
              v-if="form.buildingCooling === 'yes'"
              :placeholder="$t('CoolingType')"
              v-model="form.coolingType"
            />
            <BaseDropdown
              v-model="form.buildingVentilationType"
              :items="buildingVentilationTypes"
              :show-dropdown-arrow="true"
              :dropdown-allow-null="false"
              :placeholder="$t('BuildingVentilationType')"
              :type="'dropdown'"
            />
          </v-row>
          <v-row
            style="margin-top: 32px; padding-left: 32px; padding-right: 32px"
          >
            <h6>{{ $t("Size") }}</h6>
          </v-row>

          <v-row
            style="
              gap: 8px;
              margin-top: 24px;
              padding-left: 32px;
              padding-right: 32px;
            "
          >
            <BaseInput
              :placeholder="$t('NetFloorArea')"
              v-model="form.netFloorArea"
              :icon-right="'m2_20.svg'"
            />
            <BaseInput
              :placeholder="$t('TotalArea')"
              v-model="form.totalArea"
              :icon-right="'m2_20.svg'"
            />
            <BaseInput
              :placeholder="$t('FloorArea')"
              v-model="form.floorArea"
              :icon-right="'m2_20.svg'"
            />
            <BaseInput
              :placeholder="$t('GrossLettableArea')"
              v-model="form.grossLettableArea"
              :icon-right="'m2_20.svg'"
            />
          </v-row>
          <div class="modal-footer">
            <v-divider
              style="z-index: 111; position: fixed !important; width: 560px"
            ></v-divider>
            <div class="buttons-row" style="padding-left: 24px">
              <button
                v-if="isEdit"
                class="delete-button"
                color="blue darken-1"
                text
                @click="isDeleteModalOpen = true"
              >
                <img
                  style="padding-right: 8px"
                  src="../../assets/images/TrashBin.svg"
                  alt=""
                />
                {{ $t("Delete") }}
              </button>
              <button v-if="!isEdit" @click="closeModal()" class="cancel">
                {{ $t("Cancel") }}
              </button>
              <button v-if="isEdit" @click="closeModal()" class="cancel">
                {{ $t("Cancel") }}
              </button>
              <button
                v-if="isEdit"
                class="save-button"
                color="blue darken-1"
                text
                @click="updateBuilding()"
              >
                <img
                  style="padding-right: 8px"
                  src="../../assets/images/Submit.svg"
                  alt=""
                />
                {{ $t("Submit") }}
              </button>
              <button
                v-if="!isEdit"
                class="save-button"
                @click="saveBuilding()"
              >
                <img
                  style="padding-right: 8px"
                  src="../../assets/images/Submit.svg"
                  alt=""
                />
                {{ $t("Submit") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal
      :removing="false"
      @canceled="isDeleteModalOpen = false"
      @approved="deleteBuilding"
      v-if="isDeleteModalOpen"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import nanoid from "nanoid";
import ClickOutside from "vue-click-outside";
import DeleteModal from "@/components/common/DeleteModal";
import BaseDropdown from "@/components/common/BaseDropdown";
import AddressFinder from "@/components/Deals/AddressFinder";

import BaseInput from "@/components/common/BaseInput";
//import BaseTextarea from "@/components/common/BaseTextarea";
//import BaseToggle from "@/components/common/BaseToggle";

export default {
  components: {
    DeleteModal,
    BaseDropdown,
    BaseInput,
    AddressFinder,
    //BaseTextarea,
    //BaseToggle,
  },
  name: "SidepanelAdd",
  props: [
    "buildingData",
    "isEdit",
    "noRefresh",
    "fromAnotherPanel",
    "property",
  ],
  directives: {
    ClickOutside,
  },
  data() {
    return {
      showErrors: false,
      clientAddOpen: false,
      isDeleteModalOpen: false,
      noteContent: "",
      validate: ["addressObject"],
      buildingHeatings: [
        { name: this.$t("Yes"), value: "yes" },
        { name: this.$t("No"), value: "no" },
      ],
      buildingCoolings: [
        { name: this.$t("Yes"), value: "yes" },
        { name: this.$t("No"), value: "no" },
      ],
      buildingVentilationTypes: [
        {
          name: this.$t("MechanicalInAndOuttake"),
          value: "mechanical_in_and_outtake",
        },
        {
          name: this.$t("MechanicalAirExhaust"),
          value: "mechanical_air_exhaust",
        },
        { name: this.$t("Gravitational"), value: "gravitational" },
        { name: this.$t("Other"), value: "other" },
      ],
      dateChanged: false,
      errors: null,
      addressObject: null,
      form: {
        buildingName: "",
        floors: null,
        units: [],
        constructionYear: null,
        refurbishmentYear: null,
        buildingCostCenter: null,
        addOnFactor: null,
        description: null,
        buildingHeating: null,
        buildingHeatingType: null,
        buildingCooling: null,
        coolingType: null,
        buildingVentilationType: null,
        netFloorArea: null,
        totalArea: null,
        floorArea: null,
        grossLettableArea: null,
        buildingAddress: "",
        addressObjects: "",
        zipCode: null,
      },
    };
  },
  created() {
    //oli mountedis
    //this.mapToFormData();
    if (this.isEdit) {
      this.mapToFormData();
    }
  },
  mounted() {
    document.documentElement.style.overflow = "hidden";

    this.hideOverlay();
    this.errors = this.getEmptyErrors();
    this.mapToFormData();
  },
  watch: {},
  beforeDestroy() {
    if (!this.isEdit) {
      document.documentElement.style.overflow = "visible";
    }
    //document.documentElement.style.overflow = "visible";
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["changeSidepanelStateProperties"]),
    closeModal() {
      this.$emit("closed");
      this.changeSidepanelStateProperties(0);
    },
    async saveBuilding() {
      console.log("saving");
      if (!this.isFormValid()) {
        this.showErrors = true;
        return;
      }
      let building = this.form;
      building._id = nanoid(8);
      this.property.buildings.push(building);
      //building = this.mapProperty(building);
      let response = await axios.post(
        `/api/property/buildings/${this.property._id}`,
        this.property.buildings
      );
      if (response.data === 0) {
        this.toastError(this.$t("ErrorCreating"));
      } else {
        this.$emit("saveResponse", response.data);
        this.closeModal();
        if (!this.noRefresh) {
          await this.$router.go(0);
        }
        this.toastSuccess(
          this.$t("Building") + " " + this.$t("Created").toLowerCase()
        );
      }
    },
    fillFieldWithPropertyAddress(value) {
      if (value) {
        this.form.buildingAddress = this.property.address;
      } else {
        this.form.buildingAddress = "";
      }
    },
    async updateBuilding() {
      if (!this.isFormValid()) {
        this.showErrors = true;
        return;
      }
      let building = this.form;
      console.log(
        this.property.buildings.findIndex(
          (oldBuilding) => oldBuilding._id === building._id
        )
      );
      const newBuildingIndex = this.property.buildings.findIndex(
        (oldBuilding) => oldBuilding._id === building._id
      );
      console.log("here", newBuildingIndex);
      this.property.buildings[newBuildingIndex] = building;

      //property = this.mapProperty(property);
      const response = await axios.post(
        `/api/property/buildings/${this.property._id}`,
        this.property.buildings
      );
      if (response.data === 0) {
        this.toastError(this.$t("ErrorUpdating"));
        return;
      } else {
        //await this.changeSidepanelStateProperties(0);
        if (!this.noRefresh && !this.isEdit) {
          await this.$router.go(0);
        }
        // location.reload();
        await this.$emit("closed");
        this.toastSuccess(
          this.$t("Building") + " " + this.$t("Updated").toLowerCase()
        );
      }
    },
    async deleteBuilding() {
      console.log(this.buildingData);
      console.log(this.property);
      let updatedBuildings = this.property.buildings;
      updatedBuildings = updatedBuildings.filter(
        (building) => building._id !== this.buildingData._id
      );

      const deleteResponse = await axios.post(
        `/api/property/buildings/${this.property._id}`,
        updatedBuildings
      );
      if (deleteResponse.data === 0) {
        this.toastError(this.$t("ErrorDeleting"));
        return;
      } else {
        //await this.$router.push({ name: "PropertyOwner" });
        await this.$router.go(0);
        this.toastSuccess(
          this.$t("Building") + " " + this.$t("Deleted").toLowerCase()
        );
      }
    },
    isFormValid() {
      this.errors = this.getEmptyErrors();
      return this.validate
        .map((field) => {
          return this.isFieldValid(field);
        })
        .every((item) => item === true);
    },
    isFieldValid(fieldName) {
      console.log(fieldName);

      switch (fieldName) {
        case "addressObject":
          if (!this.addressObject) {
            this.errors.addressObject = "FieldRequired";
            return false;
          }
          break;
      }
      return true;
    },

    mapToFormData() {
      if (this.isEdit && this.buildingData) {
        this.form = this.buildingData;
        if (this.buildingData.addressObjects) {
          this.addressObject = this.buildingData.addressObjects[0];
        }
      }
      console.log("ADDRESS", this.addressObject);
    },
    getEmptyErrors() {
      return {
        addressObject: "",
      };
    },
    setAddressObject(object) {
      this.form.addressObjects = [];
      this.addressObject = object;
      this.form.addressObjects.push(this.addressObject);
    },
    removeAddressObject() {
      this.object = null;
      this.form.addressObjects.pop();
    },
    putImages(e) {
      this.form.images = e;
    },
    hideOverlay() {
      document.documentElement.style.overflow = "hidden";

      window.onclick = (e) => {
        if (e.target.classList[0] === "overlay") {
          this.closeModal();
          e.target.style.display = "none";
        }
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/global_variables";

#panel-wrapper-property {
  -webkit-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
}

#wrapped-header-property {
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  flex-direction: column;
  justify-content: flex-start;
  padding: 48px 24px 24px;
  border-right: 1px solid $grey500;

  h4 {
    white-space: nowrap;

    overflow: hidden;
    text-overflow: ellipsis;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    text-align: center;
    position: relative;
    justify-self: center;
    align-self: center;
  }
}

.deal-type-lable {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #75787a !important;
}

.v-text-field--outlined .v-label {
  top: 20px;
}

::v-deep .delete {
  padding: 0 !important;
}

::v-deep .plus {
  display: none;
}

.calender-label {
  margin-top: -5px;
  left: auto;
  right: auto;
  position: absolute;
  font-size: 12px;
  font-family: Inter;
  background-color: white;
  margin-left: 12px;
}

.sticky-header {
  justify-content: flex-end;
  z-index: 111;
  padding-top: 40px;
  position: fixed;
  background: white;
  height: 97px;
  width: 560px;
}

.textarea:focus {
  border: 1px solid #e6e8ec;
  outline: none;
  border-radius: 8px;
}

.top-row {
  width: 100%;
  justify-content: space-between;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 9;
}
.flex-column-gen {
  margin-top: 17px !important;
}

::v-deep .mainMessage {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  //line-height: 20px;
  text-align: center;
  color: #000000;
}

::v-deep .beforeUpload .icon {
  height: 20px;
}

.image-uploader {
  //min-width: 896px;
  min-height: 96px;
  border: 1px dashed #c3c4c6;
  box-sizing: border-box;
  border-radius: 8px;
}

.project-type-selected-con-Sale {
  background: $lightgreen !important;
  color: $green !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-LeaseOut {
  background: $lightpink !important;
  color: $magenta !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-Purchase {
  background: $lightblue !important;
  color: $blue !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-AquireLease {
  background: $beige !important;
  color: $orange !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-Referral {
  background: $lightbrown !important;
  color: $brown !important;
  border: 0 !important;
  font-weight: bold !important;
}
.regular-wrapper {
  padding-bottom: 12px;
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 32px !important;
}
.edit-wrapper {
  padding-bottom: 12px;
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 12px;
}
.project-type-con {
  font-style: normal;
  font-weight: normal;
  border: 1px solid $grey500;
  box-sizing: border-box;
  border-radius: 8px;

  background: #fff;
  color: #000;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
}
.modal-wrapper {
  position: absolute;
  right: 0;
  top: 0%;
  bottom: 0%;
  background-color: #ffffff;
  padding-left: 0px;
  z-index: 7;
  width: 560px;
  border: 1px solid #e6e8ec;
  overflow-y: scroll;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

.modal-header {
  justify-content: flex-end;
  z-index: 111;
  padding-top: 40px;
  position: fixed;
  background: white;
  height: 97px;
  width: 560px;
}

.modal-content {
  font-size: 14px;
  padding: 110px 32px 140px 32px;
}

.modal-footer {
  justify-content: flex-end;
  padding-bottom: 64px;
  z-index: 110;
  position: fixed;
  background: white;
  bottom: 0;
  height: 104px;
  width: 560px;
}

.faded-title {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $grey950;
}

.buttons-row {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 24px 50px 64px;
  height: 112px;
  font-family: Inter !important;
  color: #000000;
  width: 560px;
  background-color: white;

  .cancel {
    background-color: transparent !important;
    box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
    text-transform: none;
    padding: 12px;
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
}

::v-deep .theme--light.v-select .v-select__selections {
  color: #000000;
  line-height: 20px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  z-index: 55;
  left: 16px;
  cursor: pointer;
}

input::-webkit-datetime-edit {
  position: relative;
  left: 18px;
}

input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
  left: 18px;
}

.birth-class {
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  width: 49.2%;
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  height: 56px;
  padding: 0px !important;

  &:focus {
    outline: none !important;
  }
}

.save-button {
  color: #ffffff;
  background: #000000;
  border: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  line-height: 16px;
  text-transform: none;
  width: 103px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.cancel-button {
  background-color: transparent !important;
  box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400 !important;
  line-height: 16px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}

.close-button {
  background: #f4f5f7;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
}

.textarea {
  width: 100%;
  height: 96px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #e6e8ec;
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
}

.delete-button {
  margin-right: 168px;
  background-color: transparent !important;
  box-shadow: 0px 0 2px #ff1e24;
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  border-radius: 8px;
  color: #ff1e24;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 40px;
}

::v-deep .not-valid fieldset {
  border: 2px solid #ff1e24 !important;
}

.input-edit {
  margin-top: 12px;
}

::v-deep .v-messages {
  display: none;
}

::v-deep .radio-group-label .v-label {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}
</style>
