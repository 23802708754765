<template>
  <div @click="closePanel" class="user-panel-overlay" id="user-overlay">
    <div v-if="propertyLoaded" class="user-panel">
      <div class="property-panel-header" id="userpanel-header">
        <v-row>
          <h4>{{ property.name }}</h4>
        </v-row>
        <v-row style="display: contents">
          <EditButton @clicked="editProperty(property)" />
        </v-row>
      </div>
      <div class="user-panel-body" id="user-body">
        <div class="main-info-section">
          <h5 class="main-info-title">{{ $t("Subblock.GeneralData") }}</h5>
          <v-row v-if="property.name" class="user-field-row">
            <p class="field-name">{{ $t("PropertyName") }}</p>
            <p class="field-value">{{ property.name }}</p>
          </v-row>
          <v-row v-if="property.address" class="user-field-row">
            <p class="field-name">{{ $t("Address") }}</p>
            <p class="field-value">{{ property.address }}</p>
          </v-row>
          <v-row v-if="property.value" class="user-field-row">
            <p class="field-name">{{ $t("Value") }}</p>
            <p class="field-value">{{ property.value }}</p>
          </v-row>
          <v-row v-if="property.area" class="user-field-row">
            <p class="field-name">{{ $t("PropertyArea") }}</p>
            <p class="field-value">{{ property.area }} m²</p>
          </v-row>
          <v-row v-if="property.closedNetArea" class="user-field-row">
            <p class="field-name">{{ $t("ClosedNetArea") }}</p>
            <p class="field-value">{{ property.closedNetArea }} m²</p>
          </v-row>
        </div>
        <div style="margin-top: 32px" class="main-info-section">
          <h5 class="main-info-title">{{ $t("AssetType") }}</h5>
          <v-row v-if="property.businessId" class="user-field-row">
            <p class="field-name">{{ $t("BusinessId") }}</p>
            <p class="field-value">{{ property.businessId }}</p>
          </v-row>
          <v-row v-if="property.assetType" class="user-field-row">
            <p class="field-name">{{ $t("AssetType") }}</p>
            <p class="field-value">
              {{ formatPropertyType(property.assetType) }}
            </p>
          </v-row>
          <v-row v-if="property.assetClass" class="user-field-row">
            <p class="field-name">{{ $t("AssetClass") }}</p>
            <p class="field-value">
              {{ formatAssetClass(property.assetClass) }}
            </p>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import "lightgallery.js";
import "lightgallery.js/dist/css/lightgallery.css";

import EditButton from "@/components/common/EditButton";

export default {
  name: "PropertyDetailPanel",
  props: ["propertyId", "contract", "contractId"],
  components: {
    EditButton,
  },
  data: () => ({
    property: null,
    propertyLoaded: false,
  }),
  created() {
    this.getProperty();
  },
  mounted() {
    document.onkeydown = (evt) => {
      evt = evt || window.event;
      if (evt.key === "Escape") {
        this.closePanel();
        document.onkeydown = null;
      }
    };
    document.documentElement.style.overflow = "hidden";
  },
  beforeDestroy() {
    document.onkeydown = null;
    document.documentElement.style.overflow = "visible";
  },
  computed: {},
  watch: {
    propertyLoaded() {
      this.stickyHeader();
    },
  },
  methods: {
    async getProperty() {
      const response = await axios.get(`/api/property/${this.propertyId}`);
      this.property = response.data;
      this.propertyLoaded = true;
    },
    editProperty(property) {
      this.$emit("editProperty", property);
    },
    formatUserRole(isAdmin) {
      if (isAdmin) {
        return "Admin";
      } else {
        return this.$t("Broker");
      }
    },
    closePanel(e) {
      if (!e) {
        this.$emit("overlayClicked");
      }
      if (e.target !== e.currentTarget) return;
      this.$emit("overlayClicked");
    },
    async stickyHeader() {
      let headerHeight = null;
      setTimeout(() => {
        headerHeight = document.getElementById("userpanel-header").offsetHeight;
        let marginTop = headerHeight + "px";
        const checkForBodyLoading = setInterval(() => {
          let bodyElement = document.getElementById("user-body");
          if (bodyElement) {
            document.getElementById("user-body").style.marginTop = marginTop;
            clearInterval(checkForBodyLoading);
          }
        }, 100);
        console.log(headerHeight);
      }, 100);
    },
    formatPropertyType(type) {
      if (type == "gross_rent") {
        return this.$t("GrossRent");
      } else if (type == "net_rent") {
        return this.$t("NetRent");
      } else if (type == "triple_net") {
        return this.$t("TripleNet");
      }
    },

    formatAssetClass(assetClass) {
      if (assetClass == "rental_plot") {
        return this.$t("RentalPlot");
      } else if (assetClass == "residential_building") {
        return this.$t("ResidentialBuilding");
      } else if (assetClass == "apartment") {
        return this.$t("Apartment");
      } else if (assetClass == "commercial_property") {
        return this.$t("CommercialProperty");
      } else if (assetClass == "shop_premise") {
        return this.$t("ShopPremise");
      } else if (assetClass == "forest_land") {
        return this.$t("ForestLand");
      } else if (assetClass == "arable") {
        return this.$t("Arable");
      } else if (assetClass == "indirectly_owned_holding") {
        return this.$t("IndirectlyOwnedHolding");
      } else if (assetClass == "other") {
        return this.$t("Other");
      } else if (assetClass == "parking") {
        return this.$t("Parking");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-panel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 8;
}

.user-panel {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  bottom: 0;
  background-color: #ffffff;
  z-index: 7;
  width: 560px;
  overflow-x: hidden;
  overflow-y: scroll;
  border: 1px solid #e6e8ec;
}

.property-panel-header {
  display: flex;
  position: fixed;
  background: white;
  padding: 48px 32px 24px;
  width: 560px;
  border-bottom: 1px solid #e6e8ec;

  h4 {
    max-width: 392px;
    /*overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;*/
  }
}

.user-panel-body {
  padding: 32px 32px 64px;
  overflow: hidden;
}

.main-info {
  padding-bottom: 32px;
  border-bottom: 1px solid #e6e8ec;
}

.user-field-row {
  gap: 24px;
}

.field-name {
  color: #75787a;
  font-family: "Inter", sans-serif;
}

.field-name,
.field-value {
  width: 208px;
  font-size: 14px;
  line-height: 20px;
}

.grey-950 {
  color: #75787a;
}

.user-status-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
}
.statusLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px;
  color: white;
  height: 20px;
  max-width: fit-content;
  border-radius: 4px;
  cursor: default;
  white-space: nowrap;
}
.main-info-title {
  margin-bottom: 24px;
}
.profile-picture {
  max-width: 140px;
  height: auto;
  cursor: pointer;
  border-radius: 8px;
}
.re-invite-button {
  cursor: pointer;
}
</style>
