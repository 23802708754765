var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay",
    attrs: {
      "id": "overlay-property"
    }
  }, [_c('div', {
    staticClass: "modal-wrapper",
    attrs: {
      "id": "panel-wrapper-property"
    }
  }, [_c('div', {
    staticClass: "content-overlay",
    attrs: {
      "id": "content-wrapper"
    }
  }, [_c('div', {
    staticClass: "sticky-header",
    staticStyle: {
      "z-index": "126"
    }
  }, [_c('v-row', {
    staticClass: "top-row",
    staticStyle: {
      "height": "24px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [!_vm.isEdit ? _c('h4', {
    staticStyle: {
      "height": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddNewBuilding")) + " ")]) : _vm._e(), _vm.isEdit ? _c('h4', {
    staticStyle: {
      "height": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ChangeNewBuilding")) + " ")]) : _vm._e()])], 1), _c('v-divider', {
    staticStyle: {
      "top": "97px",
      "z-index": "126",
      "position": "fixed",
      "width": "560px"
    }
  }), _c('div', {
    staticStyle: {
      "padding": "121px 0px"
    }
  }, [!_vm.isEdit ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("Address")))])]) : _vm._e(), !_vm.isEdit ? _c('v-row', {
    staticStyle: {
      "gap": "8px",
      "margin-top": "24px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('div', {
    staticStyle: {
      "z-index": "125",
      "width": "100%"
    }
  }, [!_vm.isEdit ? _c('AddressFinder', {
    attrs: {
      "object": _vm.form.addressObjects ? _vm.form.addressObjects[0] : '',
      "placeholder": _vm.$t('Address'),
      "show-error": _vm.showErrors && !_vm.isFieldValid('addressObject'),
      "error-message": _vm.errors && _vm.errors.addressObject ? _vm.errors.addressObject : '',
      "disabled": _vm.isEdit
    },
    on: {
      "setObject": _vm.setAddressObject,
      "removeObject": _vm.removeAddressObject
    }
  }) : _vm._e()], 1)]) : _vm._e(), _c('v-row', {
    class: {
      'regular-wrapper': !_vm.isEdit,
      'edit-wrapper': _vm.isEdit
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("GeneralInformation")))])]), _c('v-row', {
    staticStyle: {
      "gap": "8px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": 'property-owner-name-field',
      "placeholder": _vm.$t('BuildingName')
    },
    model: {
      value: _vm.form.buildingName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "buildingName", $$v);
      },
      expression: "form.buildingName"
    }
  }), _c('BaseDropdown', {
    attrs: {
      "icon-left": "calendar_20_20.svg",
      "placeholder": _vm.$t('ConstructionYear'),
      "show-dropdown-arrow": true,
      "type": "date"
    },
    model: {
      value: _vm.form.constructionYear,
      callback: function ($$v) {
        _vm.$set(_vm.form, "constructionYear", $$v);
      },
      expression: "form.constructionYear"
    }
  }), _c('BaseDropdown', {
    attrs: {
      "icon-left": "calendar_20_20.svg",
      "placeholder": _vm.$t('RefurbishmentYear'),
      "show-dropdown-arrow": true,
      "type": "date"
    },
    model: {
      value: _vm.form.refurbishmentYear,
      callback: function ($$v) {
        _vm.$set(_vm.form, "refurbishmentYear", $$v);
      },
      expression: "form.refurbishmentYear"
    }
  }), _c('BaseInput', {
    attrs: {
      "id": 'property-owner-size-field',
      "placeholder": _vm.$t('BuildingCostCenter')
    },
    model: {
      value: _vm.form.buildingCostCenter,
      callback: function ($$v) {
        _vm.$set(_vm.form, "buildingCostCenter", $$v);
      },
      expression: "form.buildingCostCenter"
    }
  }), _c('BaseInput', {
    attrs: {
      "id": 'property-owner-closedNetArea-field',
      "placeholder": _vm.$t('AddOnFactor'),
      "icon-right": 'percentage.svg'
    },
    model: {
      value: _vm.form.addOnFactor,
      callback: function ($$v) {
        _vm.$set(_vm.form, "addOnFactor", $$v);
      },
      expression: "form.addOnFactor"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "margin-top": "32px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("Features")))])]), _c('v-row', {
    staticStyle: {
      "gap": "8px",
      "margin-top": "24px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('BaseDropdown', {
    attrs: {
      "items": _vm.buildingHeatings,
      "show-dropdown-arrow": true,
      "dropdown-allow-null": false,
      "placeholder": _vm.$t('BuildingHeating'),
      "type": 'dropdown'
    },
    model: {
      value: _vm.form.buildingHeating,
      callback: function ($$v) {
        _vm.$set(_vm.form, "buildingHeating", $$v);
      },
      expression: "form.buildingHeating"
    }
  }), _vm.form.buildingHeating === 'yes' ? _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('BuildingHeatingType')
    },
    model: {
      value: _vm.form.buildingHeatingType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "buildingHeatingType", $$v);
      },
      expression: "form.buildingHeatingType"
    }
  }) : _vm._e(), _c('BaseDropdown', {
    attrs: {
      "items": _vm.buildingCoolings,
      "show-dropdown-arrow": true,
      "dropdown-allow-null": false,
      "placeholder": _vm.$t('BuildingCooling'),
      "type": 'dropdown'
    },
    model: {
      value: _vm.form.buildingCooling,
      callback: function ($$v) {
        _vm.$set(_vm.form, "buildingCooling", $$v);
      },
      expression: "form.buildingCooling"
    }
  }), _vm.form.buildingCooling === 'yes' ? _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('CoolingType')
    },
    model: {
      value: _vm.form.coolingType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "coolingType", $$v);
      },
      expression: "form.coolingType"
    }
  }) : _vm._e(), _c('BaseDropdown', {
    attrs: {
      "items": _vm.buildingVentilationTypes,
      "show-dropdown-arrow": true,
      "dropdown-allow-null": false,
      "placeholder": _vm.$t('BuildingVentilationType'),
      "type": 'dropdown'
    },
    model: {
      value: _vm.form.buildingVentilationType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "buildingVentilationType", $$v);
      },
      expression: "form.buildingVentilationType"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "margin-top": "32px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("Size")))])]), _c('v-row', {
    staticStyle: {
      "gap": "8px",
      "margin-top": "24px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('NetFloorArea'),
      "icon-right": 'm2_20.svg'
    },
    model: {
      value: _vm.form.netFloorArea,
      callback: function ($$v) {
        _vm.$set(_vm.form, "netFloorArea", $$v);
      },
      expression: "form.netFloorArea"
    }
  }), _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('TotalArea'),
      "icon-right": 'm2_20.svg'
    },
    model: {
      value: _vm.form.totalArea,
      callback: function ($$v) {
        _vm.$set(_vm.form, "totalArea", $$v);
      },
      expression: "form.totalArea"
    }
  }), _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('FloorArea'),
      "icon-right": 'm2_20.svg'
    },
    model: {
      value: _vm.form.floorArea,
      callback: function ($$v) {
        _vm.$set(_vm.form, "floorArea", $$v);
      },
      expression: "form.floorArea"
    }
  }), _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('GrossLettableArea'),
      "icon-right": 'm2_20.svg'
    },
    model: {
      value: _vm.form.grossLettableArea,
      callback: function ($$v) {
        _vm.$set(_vm.form, "grossLettableArea", $$v);
      },
      expression: "form.grossLettableArea"
    }
  })], 1), _c('div', {
    staticClass: "modal-footer"
  }, [_c('v-divider', {
    staticStyle: {
      "z-index": "111",
      "position": "fixed !important",
      "width": "560px"
    }
  }), _c('div', {
    staticClass: "buttons-row",
    staticStyle: {
      "padding-left": "24px"
    }
  }, [_vm.isEdit ? _c('button', {
    staticClass: "delete-button",
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.isDeleteModalOpen = true;
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/TrashBin.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Delete")) + " ")]) : _vm._e(), !_vm.isEdit ? _c('button', {
    staticClass: "cancel",
    on: {
      "click": function ($event) {
        return _vm.closeModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]) : _vm._e(), _vm.isEdit ? _c('button', {
    staticClass: "cancel",
    on: {
      "click": function ($event) {
        return _vm.closeModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]) : _vm._e(), _vm.isEdit ? _c('button', {
    staticClass: "save-button",
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.updateBuilding();
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/Submit.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")]) : _vm._e(), !_vm.isEdit ? _c('button', {
    staticClass: "save-button",
    on: {
      "click": function ($event) {
        return _vm.saveBuilding();
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/Submit.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")]) : _vm._e()])], 1)], 1)], 1)]), _vm.isDeleteModalOpen ? _c('DeleteModal', {
    attrs: {
      "removing": false
    },
    on: {
      "canceled": function ($event) {
        _vm.isDeleteModalOpen = false;
      },
      "approved": _vm.deleteBuilding
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }