<template>
  <div class="overlay" id="overlay-contract">
    <div id="panel-wrapper-contract" class="modal-wrapper">
      <div
        @click="closeContactAdd"
        style="padding-left: 24px"
        id="wrapped-header-contract"
      >
        <h4 style="align-self: center">
          {{ isEdit ? $t("EditContract") : $t("AddContract") }}
        </h4>
      </div>
      <div class="content-overlay" id="content-wrapper">
        <div class="sticky-header" style="z-index: 126">
          <v-row
            class="top-row"
            style="height: 24px; padding-left: 32px; padding-right: 32px"
            ><h4 v-if="!isEdit" style="height: 24px">
              {{ $t("AddContract") }}
            </h4>
            <h4 v-if="isEdit" style="height: 24px">
              {{ $t("ChangeContract") }}
            </h4>
          </v-row>
        </div>
        <v-divider
          style="top: 97px; z-index: 126; position: fixed; width: 560px"
        />
        <div style="padding: 121px 0px">
          <v-row
            v-bind:class="{
              'regular-wrapper': !isEdit,
              'edit-wrapper': isEdit,
            }"
          >
            <h6>{{ $t("GeneralInformation") }}</h6>
          </v-row>
          <v-row style="gap: 8px; padding-left: 32px; padding-right: 32px">
            <BaseDropdown
              v-if="selectItems"
              v-model="form.selectedBuilding"
              :items="buildingItems"
              :show-dropdown-arrow="true"
              :dropdown-allow-null="false"
              :placeholder="$t('SelectBuilding')"
              :type="'dropdown'"
              :error="
                showErrors && !isFieldValid('selectedBuilding')
                  ? 'not-valid'
                  : ''
              "
              :error-message="$t('FormErrors.' + errors.selectedBuilding)"
            />
            <BaseDropdown
              v-if="showUnitSelection && unitItems.length > 0"
              v-model="form.selectedUnit"
              :items="unitItems"
              :show-dropdown-arrow="true"
              :dropdown-allow-null="false"
              :placeholder="$t('SelectUnit')"
              :type="'dropdown'"
              :key="updateUnitList"
              :error="
                showErrors && !isFieldValid('selectedUnit') ? 'not-valid' : ''
              "
              :error-message="$t('FormErrors.' + errors.selectedUnit)"
            />
            <BaseContactSearch
              :icon-left="'search20.svg'"
              v-model="form.contactPerson"
              :show-dropdown-arrow="true"
              :placeholder="$t('Tenant')"
              @addNew="openContactAdd"
              :required="true"
              :dropdown-offset-y="52"
              :error="showErrors && !isFieldValid('contactPerson')"
              :error-message="
                errors ? $t('FormErrors.' + errors.contactPerson) : ''
              "
            />
            <BaseInput
              :id="'property-owner-size-field'"
              :placeholder="$t('ContractNumber')"
              v-model="form.contractNumber"
              :required="true"
              :error="
                showErrors && !isFieldValid('contractNumber') ? 'not-valid' : ''
              "
              :error-message="$t('FormErrors.' + errors.contractNumber)"
            />
            <BaseInput
              :id="'property-owner-closedNetArea-field'"
              :placeholder="$t('BillingEmail')"
              v-model="form.billingEmail"
            />

            <!--  <v-row style="flex-wrap: nowrap; gap: 8px">
              <BaseInput
                :id="'property-owner-address-field'"
                :placeholder="$t('Floors')"
                v-model="form.floors" />
              <BaseInput
                :id="'property-owner-value-field'"
                :placeholder="$t('Units')"
                v-model="form.units"
            /></v-row>
 -->
            <v-row style="flex-wrap: nowrap; gap: 8px; margin-top: 0px">
              <BaseDropdown
                icon-left="calendar_20_20.svg"
                :placeholder="$t('SigningDate')"
                v-model="form.signingDate"
                :show-dropdown-arrow="true"
                type="date"
              />
              <BaseDropdown
                icon-left="calendar_20_20.svg"
                :placeholder="$t('TransferDate')"
                v-model="form.transferDate"
                :show-dropdown-arrow="true"
                type="date"
              />
            </v-row>
            <v-row style="flex-wrap: nowrap; gap: 8px; margin-top: 0px">
              <BaseDropdown
                icon-left="calendar_20_20.svg"
                :placeholder="$t('StartOfRentPayments')"
                v-model="form.startOfRentPayments"
                :show-dropdown-arrow="true"
                type="date"
              />
              <BaseDropdown
                icon-left="calendar_20_20.svg"
                :placeholder="$t('EndOfRentalPeriod')"
                v-model="form.endOfRentalPeriod"
                :show-dropdown-arrow="true"
                type="date"
              />
            </v-row>
            <!--  <v-row style="flex-wrap: nowrap; gap: 8px; margin-top: 0px">
              <BaseDropdown
                :items="guarantees"
                :placeholder="$t('Guarantee')"
                v-model="form.guarantee"
                :show-dropdown-arrow="true"
              /> -->
            <BaseDropdown
              icon-left="calendar_20_20.svg"
              :placeholder="$t('GuaranteeEnds')"
              v-model="form.guaranteeEnds"
              :show-dropdown-arrow="true"
              type="date"
            />
            <!-- </v-row> -->
            <!--  <v-row style="flex-wrap: nowrap; gap: 8px; margin-top: 0px">
              <BaseDropdown
                :items="insuranceTypes"
                :placeholder="$t('InsuranceType')"
                v-model="form.insuranceType"
                :show-dropdown-arrow="true"
              /> -->
            <BaseDropdown
              icon-left="calendar_20_20.svg"
              :placeholder="$t('InsuranceEnds')"
              v-model="form.insuranceEnds"
              :show-dropdown-arrow="true"
              type="date"
            />
            <!-- </v-row> -->
          </v-row>

          <v-row
            style="margin-top: 32px; padding-left: 32px; padding-right: 32px"
          >
            <h6>{{ $t("RentAndFees") }}</h6>
          </v-row>

          <v-row
            style="
              gap: 8px;
              margin-top: 24px;
              padding-left: 32px;
              padding-right: 32px;
            "
          >
            <BaseToggle
              v-model="form.rentPerM2"
              @click="calculateRentPerMeter2"
              :label="$t('RentPerM2')"
            />
            <v-row style="flex-wrap: nowrap; gap: 8px; margin-top: 0px">
              <BaseDropdown
                :items="paymentIntervals"
                :placeholder="$t('PaymentInterval')"
                v-model="form.paymentInterval"
                :show-dropdown-arrow="true"
              />
              <BaseDropdown
                icon-left="calendar_20_20.svg"
                :placeholder="$t('PaymentDate')"
                v-model="form.paymentDate"
                :show-dropdown-arrow="true"
                type="date"
              />
            </v-row>
            <BaseInput
              v-if="!form.rentPerM2"
              :placeholder="$t('RentPrice')"
              v-model="form.rentPrice"
              :icon-right="'euroSign.svg'"
              :id="'rent'"
            />
            <BaseInput
              v-if="form.rentPerM2"
              :placeholder="$t('RentPrice')"
              v-model="form.rentPricePerSquareMeter"
              :icon-right="'euroSign.svg'"
              :id="'rent'"
            />
            <BaseInput
              :placeholder="$t('TargetRent')"
              v-model="form.targetPrice"
              :icon-right="'euroSign.svg'"
            />
            <BaseInput
              :placeholder="$t('CleaningFee')"
              v-model="form.cleaningFee"
              :icon-right="'euroSign.svg'"
            />
            <BaseInput
              :placeholder="$t('SpecialFee')"
              v-model="form.specialFee"
              :icon-right="'euroSign.svg'"
            />
          </v-row>

          <div class="modal-footer">
            <v-divider
              style="z-index: 111; position: fixed !important; width: 560px"
            ></v-divider>
            <div class="buttons-row" style="padding-left: 24px">
              <button
                v-if="isEdit"
                class="delete-button"
                color="blue darken-1"
                text
                @click="isDeleteModalOpen = true"
              >
                <img
                  style="padding-right: 8px"
                  src="../../assets/images/TrashBin.svg"
                  alt=""
                />
                {{ $t("Delete") }}
              </button>
              <button v-if="!isEdit" @click="closeModal()" class="cancel">
                {{ $t("Cancel") }}
              </button>
              <button v-if="isEdit" @click="closeModal()" class="cancel">
                {{ $t("Cancel") }}
              </button>
              <button
                v-if="isEdit"
                class="save-button"
                color="blue darken-1"
                text
                @click="updateContract()"
              >
                <img
                  style="padding-right: 8px"
                  src="../../assets/images/Submit.svg"
                  alt=""
                />
                {{ $t("Submit") }}
              </button>
              <button
                v-if="!isEdit"
                class="save-button"
                @click="saveContract()"
              >
                <img
                  style="padding-right: 8px"
                  src="../../assets/images/Submit.svg"
                  alt=""
                />
                {{ $t("Submit") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal
      :removing="false"
      @canceled="isDeleteModalOpen = false"
      @approved="deleteContract"
      v-if="isDeleteModalOpen"
    />
    <AddModal
      style="z-index: 120"
      @newAdded="chooseNewClient"
      @closed="closeContactAdd"
      :not-contact-view-route="true"
      :accordion="true"
      v-if="clientAddOpen"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import nanoid from "nanoid";
import ClickOutside from "vue-click-outside";
import DeleteModal from "@/components/common/DeleteModal";
import BaseDropdown from "@/components/common/BaseDropdown";
import BaseInput from "@/components/common/BaseInput";
import BaseToggle from "@/components/common/BaseToggle";
import BaseContactSearch from "@/components/common/BaseContactSearch";
import AddModal from "@/components/Kliendiregister/AddModal";
import contactApi from "@/http/contact";

export default {
  components: {
    DeleteModal,
    BaseDropdown,
    BaseInput,
    BaseContactSearch,
    BaseToggle,
    AddModal,
  },
  name: "SidepanelAdd",
  props: [
    "contractData",
    "isEdit",
    "noRefresh",
    "property",
    "contactPerson",
    "unitId",
    "buildingId",
    "propertyId",
    "unit",
    "buildings",
    "selectItems",
  ],
  directives: {
    ClickOutside,
  },
  data() {
    return {
      showErrors: false,
      clientAddOpen: false,
      isDeleteModalOpen: false,
      noteContent: "",
      previousPrice: null,
      buildingItems: [],
      updateUnitList: 0,
      validate: [
        "contactPerson",
        "contractNumber",
        "selectedBuilding",
        "selectedUnit",
      ],
      /*  guarantees: [
        { name: this.$t("Bank"), value: "bank" },
        { name: this.$t("Other"), value: "other" },
      ],
      insuranceTypes: [
        { name: this.$t("Something"), value: "something" },
        { name: this.$t("Other"), value: "other" },
      ], */
      paymentIntervals: [
        { name: this.$t("Weekly"), value: "weekly" },
        { name: this.$t("Monthly"), value: "monthly" },
        { name: this.$t("Quarterly"), value: "quarterly" },
        { name: this.$t("Yearly"), value: "yearly" },
      ],
      dateChanged: false,
      errors: null,
      showUnitSelection: false,
      unitItems: [],
      form: {
        contactPerson: null,
        contractNumber: null,
        billingEmail: null,
        signingDate: null,
        transferDate: null,
        startOfRentPayments: null,
        endOfRentalPeriod: null,
        guarantee: null,
        guaranteeEnds: null,
        insuranceType: null,
        insuranceEnds: null,
        paymentInterval: null,
        paymentDate: null,
        rentPrice: null,
        targetPrice: null,
        cleaningFee: null,
        specialFee: null,
        rentPerM2: null,
        statusCode: null,
        rentPricePerSquareMeter: null,
        selectedBuilding: null,
        selectedUnit: null,
      },
    };
  },
  created() {
    console.log("UNITID", this.unitId);
    console.log("BuildingId", this.buildingId);
    console.log("PropertyId", this.propertyId);
    console.log("UNIT", this.unit);
    if (this.buildings) {
      this.parseBuildings();
    }
    //oli mountedis
    //this.mapToFormData();
    if (this.isEdit) {
      this.mapToFormData();
    }
  },
  mounted() {
    document.documentElement.style.overflow = "hidden";
    this.hideOverlay();
    this.errors = this.getEmptyErrors();
    this.mapToFormData();
    //for editing
    /* if (this.contactPerson) {
      this.chosenNewClient = this.contactPerson;
    } */
  },
  watch: {
    "form.selectedBuilding": function () {
      if (this.form.selectedBuilding) {
        console.log("SEELCTED", this.form.selectedBuilding);
        this.showUnitSelection = true;
        this.parseUnits(this.form.selectedBuilding);
      }
    },
    "form.selectedUnit": function () {
      if (this.form.selectedUnit) {
        console.log("SEELCTED UNIT", this.form.selectedUnit);
      }
    },
  },
  beforeDestroy() {
    if (!this.isEdit) {
      document.documentElement.style.overflow = "visible";
    }
  },
  "form.rentPerM2": function () {
    this.calculateRentPerMeter2();
  },
  computed: {
    ...mapGetters(["user", "customers"]),
  },
  methods: {
    closeModal() {
      this.$emit("closed");
    },
    ...mapActions(["getCustomers"]),

    parseBuildings() {
      this.buildings.forEach((building) => {
        if (building.units.length > 0) {
          building.units.forEach((unit) => {
            if (
              unit.contract.every((contract) => contract.statusCode === 0) ||
              unit.contract.length === 0 /*  &&
              this.buildingItems.any((item) => item.value !== building._id) */
            ) {
              if (building.buildingName) {
                this.buildingItems.push({
                  name: building.buildingName,
                  value: building._id,
                });
              } else {
                this.buildingItems.push({
                  name: building.addressObjects[0].address,
                  value: building._id,
                });
              }
            }
          });
        }
      });
      const uniqueValuesSet = new Set();
      const filteredArr = this.buildingItems.filter((obj) => {
        const isPresentInSet = uniqueValuesSet.has(obj.value);
        uniqueValuesSet.add(obj.value);
        return !isPresentInSet;
      });
      this.buildingItems = filteredArr;
      if (this.buildingItems.length === 0) {
        this.toastError(this.$t("CantAddContractPleaseAddUnitsBefore"));
        this.$emit("closed");
      } else {
        console.log("LEIDIS BUILDINGUID", this.form.selectedBuilding);
        this.parseUnits(this.form.selectedBuilding, true);
      }
      console.log("BUILDING ITEMS", this.buildingItems);
    },
    parseUnits(buildingId, bool) {
      console.log("BuildingId", buildingId);
      this.unitItems = [];
      if (!bool) {
        this.buildings.forEach((building) => {
          if (building._id === buildingId) {
            building.units.forEach((unit) => {
              if (
                unit.leasable &&
                (unit.contract.length === 0 ||
                  unit.contract.every((contract) => contract.statusCode === 0))
              ) {
                if (unit.unitSize) {
                  this.unitItems.push({
                    name: unit.name
                      ? unit.name
                      : "Unnamed unit" +
                        " (" +
                        this.$t("Floor") +
                        ": " +
                        unit.floor +
                        ", " +
                        this.$t("Size") +
                        ": " +
                        unit.unitSize +
                        ")",
                    value: unit._id,
                  });
                } else {
                  this.unitItems.push({
                    name: unit.name
                      ? unit.name
                      : this.$t("UnnamedUnit") +
                        " (" +
                        this.$t("Floor") +
                        ": " +
                        unit.floor +
                        ")",
                    value: unit._id,
                  });
                }
              }
            });
          }
        });
      } else {
        return 0;
      }

      console.log("Unit ITEMS", this.unitItems);

      if (this.unitItems.length === 0) {
        this.toastError(this.$t("CantAddContractPleaseAddLeasableUnitBefore"));
        this.$emit("closed");
      }
      this.updateUnitList++;
    },
    //for adding new tenant aka contactPerson
    async chooseNewClient(client) {
      const object = await contactApi.getContactById(client);
      this.form.contactPerson = {
        _id: object._id,
        contactId: object.customerId,
        contactName: object.customerName
          ? object.customerName
          : object.contactName,
        createdAt: object.createdAt,
      };
      this.chosenNewClient = object;
      this.autocompleteRefresher++;
      this.closeContactSearch++;
    },
    openContactAdd() {
      let panel = document.getElementById("panel-wrapper-contract");
      let panelContent = document.getElementById("content-wrapper");
      let overlay = document.getElementById("overlay-contract");
      panelContent.style.display = "none";
      panel.style.overflow = "hidden";
      panel.style.minWidth = "94px";
      panel.style.width = "94px";
      panel.style.right = "638px";
      panel.style.zIndex = "121";
      //panel.style.boxShadow = "0px 0 1px rgba(28, 14, 3, 0.2)";
      overlay.style.zIndex = "11";

      let wrappedHeader = document.getElementById("wrapped-header-contract");
      wrappedHeader.style.display = "flex";
      wrappedHeader.style.opacity = "1";
      this.clientAddOpen = true;
    },
    closeContactAdd() {
      let panel = document.getElementById("panel-wrapper-contract");
      let panelContent = document.getElementById("content-wrapper");
      let overlay = document.getElementById("overlay-contract");
      let wrappedHeader = document.getElementById("wrapped-header-contract");

      wrappedHeader.style.display = "none";
      wrappedHeader.style.opacity = "0";
      panelContent.style.display = "initial";
      panel.style.overflow = "scroll";
      panel.style.minWidth = "560px";
      panel.style.width = "560px";
      panel.style.right = "0";
      overlay.style.zIndex = "101";
      window.onclick = (e) => {
        if (e.target.classList[0] === "overlay") {
          this.closeModal();
          e.target.style.display = "none";
        }
      };

      //waiting for animations
      setTimeout(async () => {
        this.clientAddOpen = false;
      }, 500);
    },
    async saveContract() {
      console.log("saving");
      this.showErrors = false;
      if (!this.isFormValid()) {
        this.showErrors = true;
        return;
      }
      let contract = this.form;
      contract._id = nanoid(8);
      //this.property.buildings.push(building);
      //building = this.mapContract(building);
      let response = null;
      if (this.unitId) {
        console.log("UNIIT ID OLEMAS");
        response = await axios.post(
          `/api/property/building/unit/contract/${this.unitId}`,
          {
            propertyId: this.propertyId,
            buildingId: this.buildingId,
            contract: contract,
          }
          //this.property.buildings
        );
      } else {
        console.log("UNIIT ID Pole");
        response = await axios.post(
          `/api/property/building/unit/contract/${this.form.selectedUnit}`,
          {
            propertyId: this.propertyId,
            buildingId: this.form.selectedBuilding,
            contract: contract,
          }
          //this.property.buildings
        );
        this.showUnitSelection = false;
      }

      console.log("RESponse contract", response.data);
      if (response.data === 0) {
        this.toastError(this.$t("ErrorCreating"));
      } else {
        this.$emit("saveResponse", response.data);
        this.closeModal();
        if (!this.noRefresh) {
          await this.$router.go(0);
        }
        this.toastSuccess(
          this.$t("Contract") + " " + this.$t("Created").toLowerCase()
        );
      }
    },

    calculateRentPerMeter2(value) {
      console.log("calculating...");

      if (!this.unit.unitSize) {
        return;
      }
      if (value && this.form.rentPrice) {
        this.form.rentPricePerSquareMeter = (
          this.form.rentPrice / this.unit.unitSize
        ).toFixed(2);
        return;
      }
      if (!value && this.form.rentPricePerSquareMeter) {
        this.form.rentPrice = (
          this.form.rentPricePerSquareMeter * this.unit.unitSize
        ).toFixed(2);
        return;
      }

      if (value && this.form.rentPricePerSquareMeter) {
        console.log(value);
      }

      /*
      if (value && !this.form.rentPrice && this.unit.unitSize) {
        this.previousPrice = this.form.rentPrice;
        this.form.rentPricePerSquareMeter = this.previousPrice;
        this.form.rentPrice = 0;
        this.form.rentPerM2 = true;
        console.log("HERE !");
      }
      if (value && !this.unit.unitSize && !this.form.rentPrice) {
        this.previousPrice = this.form.rentPrice;
        this.form.rentPricePerSquareMeter = this.previousPrice;
        this.form.rentPrice = 0;
        this.form.rentPerM2 = true;
        console.log("SIIN1");
      }
      if (value && !this.unit.unitSize && this.form.rentPrice) {
        this.form.rentPerM2 = true;
        this.previousPrice = this.form.rentPrice;
        this.form.rentPricePerSquareMeter = this.previousPrice;

        this.form.rentPrice = 0;
        console.log("SIIN2");
      }
      if (value && this.unit.unitSize && this.form.rentPrice) {
        this.previousPrice = this.form.rentPrice;
        this.form.rentPricePerSquareMeter = this.previousPrice;

        this.form.rentPrice = (
          this.form.rentPrice / this.unit.unitSize
        ).toFixed(2);
        this.form.rentPerM2 = true;
        console.log("SIIN3");
      }
      if (!value) {
        console.log("GOTBACK1");
        this.form.rentPrice = this.previousPrice;
        console.log("PREVIOUS PRICE1", this.form.rentPrice);
        this.form.rentPerM2 = false;
        if (!this.form.rentPrice) {
          //document.getElementById("rent").blur();
          this.form.rentPrice = 0;
        }
      }

       */
    },
    async updateContract() {
      if (!this.isFormValid()) {
        this.showErrors = true;
        return;
      }
      let contract = this.form;
      const response = await axios.patch(
        `/api/property/building/unit/contract/${this.unitId}`,
        {
          propertyId: this.propertyId,
          buildingId: this.buildingId,
          contract: contract,
        }
      );
      if (response.data === 0) {
        this.toastError(this.$t("ErrorUpdating"));
        return;
      } else {
        /*   if (!this.noRefresh && !this.isEdit) {
          await this.$router.go(0);
        } */
        await this.$emit("closed");
        this.toastSuccess(
          this.$t("Contract") + " " + this.$t("Updated").toLowerCase()
        );
      }
    },
    async deleteContract() {
      let contract = this.form;
      contract.statusCode = 0;
      const deleteResponse = await axios.patch(
        `/api/property/building/unit/contract/${this.unitId}`,
        {
          propertyId: this.propertyId,
          buildingId: this.buildingId,
          contract: contract,
        }
      );
      if (deleteResponse.data === 0) {
        this.toastError(this.$t("ErrorDeleting"));
        return;
      } else {
        await this.$router.go(0);
        this.toastSuccess(
          this.$t("Contract") + " " + this.$t("Deleted").toLowerCase()
        );
      }
    },
    isFormValid() {
      this.errors = this.getEmptyErrors();
      return this.validate
        .map((field) => {
          return this.isFieldValid(field);
        })
        .every((item) => item === true);
    },
    isFieldValid(fieldName) {
      console.log(fieldName);
      if (!this.selectItems) {
        switch (fieldName) {
          case "contactPerson":
            if (!this.form.contactPerson) {
              this.errors.contactPerson = "FieldRequired";
              return false;
            }
            break;
          case "contractNumber":
            if (!this.form.contractNumber) {
              this.errors.contractNumber = "FieldRequired";
              return false;
            }
            break;
        }
        return true;
      } else {
        switch (fieldName) {
          case "contactPerson":
            if (!this.form.contactPerson) {
              this.errors.contactPerson = "FieldRequired";
              return false;
            }
            break;
          case "contractNumber":
            if (!this.form.contractNumber) {
              this.errors.contractNumber = "FieldRequired";
              return false;
            }
            break;
          case "selectedBuilding":
            if (!this.form.selectedBuilding) {
              this.errors.selectedBuilding = "FieldRequired";
              return false;
            }
            break;
          case "selectedUnit":
            if (!this.form.selectedUnit) {
              this.errors.selectedUnit = "FieldRequired";
              return false;
            }
            break;
        }
        return true;
      }
    },

    mapToFormData() {
      console.log("CONTRACT DATA", this.contractData);
      if (this.isEdit && this.contractData) {
        this.form = this.contractData;
      }
      if (this.contactPerson) {
        this.form.contactPerson = {
          _id: this.contactPerson._id,
          company: this.contactPerson.company_name,
          isPrivatePerson: true,
          contactId: this.contactPerson.contactId,
          contactName:
            this.contactPerson && this.contactPerson.customerName
              ? this.contactPerson.customerName
              : this.contactPerson.contactName,
          createdAt: this.contactPerson.createdAt,
        };
      }
    },
    getEmptyErrors() {
      return {
        contactPerson: "",
        contractNumber: "",
        selectedBuilding: "",
        selectedUnit: "",
      };
    },
    hideOverlay() {
      document.documentElement.style.overflow = "hidden";

      window.onclick = (e) => {
        if (e.target.classList[0] === "overlay") {
          this.closeModal();
          e.target.style.display = "none";
        }
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/global_variables";

#panel-wrapper-contract {
  -webkit-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
}

#wrapped-header-contract {
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  flex-direction: column;
  justify-content: flex-start;
  padding: 48px 24px 24px;
  border-right: 1px solid $grey500;

  h4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    text-align: center;
    position: relative;
    justify-self: center;
    align-self: center;
  }
}

.deal-type-lable {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #75787a !important;
}

.v-text-field--outlined .v-label {
  top: 20px;
}

::v-deep .delete {
  padding: 0 !important;
}

::v-deep .plus {
  display: none;
}

.calender-label {
  margin-top: -5px;
  left: auto;
  right: auto;
  position: absolute;
  font-size: 12px;
  font-family: Inter;
  background-color: white;
  margin-left: 12px;
}

.sticky-header {
  justify-content: flex-end;
  z-index: 111;
  padding-top: 40px;
  position: fixed;
  background: white;
  height: 97px;
  width: 560px;
}

.textarea:focus {
  border: 1px solid #e6e8ec;
  outline: none;
  border-radius: 8px;
}

.top-row {
  width: 100%;
  justify-content: space-between;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 9;
}

.flex-column-gen {
  margin-top: 17px !important;
}

::v-deep .mainMessage {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  //line-height: 20px;
  text-align: center;
  color: #000000;
}

::v-deep .beforeUpload .icon {
  height: 20px;
}

.image-uploader {
  //min-width: 896px;
  min-height: 96px;
  border: 1px dashed #c3c4c6;
  box-sizing: border-box;
  border-radius: 8px;
}

.project-type-selected-con-Sale {
  background: $lightgreen !important;
  color: $green !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-LeaseOut {
  background: $lightpink !important;
  color: $magenta !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-Purchase {
  background: $lightblue !important;
  color: $blue !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-AquireLease {
  background: $beige !important;
  color: $orange !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-Referral {
  background: $lightbrown !important;
  color: $brown !important;
  border: 0 !important;
  font-weight: bold !important;
}

.regular-wrapper {
  padding-bottom: 12px;
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 0px !important;
}

.edit-wrapper {
  padding-bottom: 12px;
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 12px;
}

.project-type-con {
  font-style: normal;
  font-weight: normal;
  border: 1px solid $grey500;
  box-sizing: border-box;
  border-radius: 8px;

  background: #fff;
  color: #000;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
}

.modal-wrapper {
  position: absolute;
  right: 0;
  top: 0%;
  bottom: 0%;
  background-color: #ffffff;
  padding-left: 0px;
  z-index: 7;
  width: 560px;
  border: 1px solid #e6e8ec;
  overflow-y: scroll;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

.modal-header {
  justify-content: flex-end;
  z-index: 111;
  padding-top: 40px;
  position: fixed;
  background: white;
  height: 97px;
  width: 560px;
}

.modal-content {
  font-size: 14px;
  padding: 110px 32px 140px 32px;
}

.modal-footer {
  justify-content: flex-end;
  padding-bottom: 64px;
  z-index: 110;
  position: fixed;
  background: white;
  bottom: 0;
  height: 104px;
  width: 560px;
}

.faded-title {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $grey950;
}

.buttons-row {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 24px 50px 64px;
  height: 112px;
  font-family: Inter !important;
  color: #000000;
  width: 560px;
  background-color: white;

  .cancel {
    background-color: transparent !important;
    box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
    text-transform: none;
    padding: 12px;
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
}

::v-deep .theme--light.v-select .v-select__selections {
  color: #000000;
  line-height: 20px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  z-index: 55;
  left: 16px;
  cursor: pointer;
}

input::-webkit-datetime-edit {
  position: relative;
  left: 18px;
}

input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
  left: 18px;
}

.birth-class {
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  width: 49.2%;
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  height: 56px;
  padding: 0px !important;

  &:focus {
    outline: none !important;
  }
}

.save-button {
  color: #ffffff;
  background: #000000;
  border: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  line-height: 16px;
  text-transform: none;
  width: 103px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.cancel-button {
  background-color: transparent !important;
  box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400 !important;
  line-height: 16px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}

.close-button {
  background: #f4f5f7;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
}

.textarea {
  width: 100%;
  height: 96px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #e6e8ec;
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
}

.delete-button {
  margin-right: 168px;
  background-color: transparent !important;
  box-shadow: 0px 0 2px #ff1e24;
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  border-radius: 8px;
  color: #ff1e24;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 40px;
}

::v-deep .not-valid fieldset {
  border: 2px solid #ff1e24 !important;
}

.input-edit {
  margin-top: 12px;
}

::v-deep .v-messages {
  display: none;
}

::v-deep .radio-group-label .v-label {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}
</style>
