var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.property ? _c('div', {
    staticClass: "content",
    attrs: {
      "id": "property-content"
    }
  }, [_c('div', {
    staticClass: "panel-wrapper",
    attrs: {
      "id": "panel-wrapper-deal"
    }
  }, [!_vm.property ? _c('div', {
    staticClass: "overlay"
  }, [_vm._m(0)]) : _vm._e(), _c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "24px",
      "flex-wrap": "nowrap",
      "padding-bottom": "24px"
    }
  }, [_c('BaseDetailviewContainer', [_c('BaseAccordion', {
    attrs: {
      "counter-bg-color-red": true,
      "padding-top": '0px',
      "title": _vm.$t('Property'),
      "parent-name": 'property',
      "button-icon": 'edit_16_16.svg',
      "identifier": 'generalData',
      "show-bot-divider": true,
      "button-name": _vm.$t('Edit')
    },
    on: {
      "buttonPressed": function ($event) {
        return _vm.openSidepanelAdd(_vm.property);
      }
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "16px",
      "flex-direction": "column"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "12px",
      "flex-direction": "column"
    }
  }, [_vm.property.area ? _c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('p', {
    staticClass: "property-value-name content-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("PropertySize")) + " ")]), _c('p', {
    staticClass: "property-value"
  }, [_vm._v(_vm._s(_vm.property.area) + " m²")])]) : _vm._e(), _vm.property.value ? _c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('p', {
    staticClass: "property-value-name content-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("Value")) + " ")]), _c('p', {
    staticClass: "property-value"
  }, [_vm._v(_vm._s(_vm.property.value) + " €")])]) : _vm._e()]), _c('div', {
    staticStyle: {
      "cursor": "pointer",
      "text-decoration": "underline",
      "gap": "8px",
      "display": "flex"
    },
    on: {
      "click": function ($event) {
        return _vm.openDetailPanel(_vm.property);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/infoMark20.svg")
    }
  }), _c('p', {
    staticClass: "link",
    staticStyle: {
      "width": "176px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ViewFullInfo")) + " ")])])])]), _c('BaseAccordion', {
    attrs: {
      "title": _vm.$t('Immovables'),
      "button-name": _vm.$t('Add'),
      "button-icon": 'plus.svg',
      "show-bot-divider": true,
      "parent-name": 'property',
      "identifier": 'immovables',
      "counter": _vm.property.buildings.length
    },
    on: {
      "buttonPressed": function ($event) {
        return _vm.openBuildingPanel(_vm.property);
      }
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "24px"
    }
  }, _vm._l(_vm.property.buildings, function (building) {
    return _c('div', {
      key: building._id,
      staticClass: "building-info"
    }, [_c('div', {
      staticStyle: {
        "display": "flex",
        "gap": "8px",
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          return _vm.openBuildingDetailPanel(building);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/images/building20x20.svg"),
        "alt": ""
      }
    }), _c('p', {
      staticStyle: {
        "text-decoration": "underline"
      }
    }, [_vm._v(" " + _vm._s(building.buildingName ? building.buildingName : building.addressObjects[0].address) + " ")])]), _c('div', {
      staticStyle: {
        "display": "flex",
        "flex-direction": "column",
        "gap": "12px"
      }
    }, [_c('div', {
      staticStyle: {
        "display": "flex"
      }
    }, [_c('div', {
      staticClass: "property-value-name"
    }, [_vm._v(_vm._s(_vm.$t("Units")))]), _c('div', {
      staticClass: "property-value"
    }, [_vm._v(" " + _vm._s(building.units.length) + " ")])]), _c('div', {
      staticStyle: {
        "display": "flex"
      }
    }, [_c('div', {
      staticClass: "property-value-name"
    }, [_vm._v(_vm._s(_vm.$t("Floors")))]), _c('div', {
      staticClass: "property-value"
    }, [_vm._v(" " + _vm._s(_vm.countMaxFloors(building.units)) + " ")])]), _c('div', {
      staticStyle: {
        "display": "flex"
      }
    }, [_c('div', {
      staticClass: "property-value-name"
    }, [_vm._v(" " + _vm._s(_vm.$t("ActiveTenants")) + " ")]), _c('div', {
      staticClass: "property-value"
    }, [_vm._v(" " + _vm._s(building.tenantsCounter) + " ")])]), _c('div', {
      staticStyle: {
        "display": "flex"
      }
    }, [_c('div', {
      staticClass: "property-value-name"
    }, [_vm._v(_vm._s(_vm.$t("Vacancy")))]), _c('div', {
      staticClass: "property-value"
    }, [_vm._v(" " + _vm._s(_vm.countLeasedUnits(building.units)) + "% ")])])])]);
  }), 0), _vm.property.buildings.length === 0 ? _c('v-row', {
    staticClass: "content",
    staticStyle: {
      "margin-top": "0px",
      "background-color": "white",
      "padding-left": "0px",
      "padding-top": "0px",
      "padding-right": "0px"
    }
  }, [_c('p', {
    staticStyle: {
      "color": "#75787a"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("NoBuildingsAdded")) + " ")])]) : _vm._e()], 1), _c('BaseAccordion', {
    attrs: {
      "button-opens-accordion": true,
      "title": _vm.$t('TasksAndEvents'),
      "identifier": 'tasks',
      "counter-bg-color-red": true,
      "parent-name": 'property',
      "show-bot-divider": true,
      "counter": _vm.tasksCounter,
      "button-icon": 'plus.svg',
      "button-name": _vm.$t('Add')
    },
    on: {
      "buttonPressed": function ($event) {
        _vm.openTaskAddWidgetCounter++;
      }
    }
  }, [_c('BaseTasksAndEvents', {
    attrs: {
      "show-toggle-button": true,
      "open-new-task-add-widget": _vm.openTaskAddWidgetCounter,
      "labels-and-order": {
        overDueTasks: {
          label: false,
          order: 2
        },
        noDueDateTasks: {
          label: false,
          order: 1
        },
        todayTasks: {
          label: false,
          order: 3
        },
        upcomingTasks: {
          label: false,
          order: 4
        }
      },
      "view": {
        name: 'property',
        itemId: _vm.$route.params.id
      }
    },
    on: {
      "tasks": _vm.tasksUpdated
    }
  })], 1), _c('BaseAccordion', {
    attrs: {
      "title": _vm.$t('Notes'),
      "button-opens-accordion": true,
      "button-name": _vm.$t('Add'),
      "button-icon": 'plus.svg',
      "parent-name": 'property',
      "show-bot-divider": true,
      "counter": _vm.totalNotesCounter,
      "identifier": 'notes'
    },
    on: {
      "buttonPressed": function ($event) {
        _vm.addNewNoteCounter++;
      }
    }
  }, [_c('BaseNotes', {
    attrs: {
      "add-new-note-counter": _vm.addNewNoteCounter,
      "view": {
        name: 'property',
        itemId: _vm.$route.params.id,
        itemName: _vm.property.name
      }
    },
    on: {
      "notesCounter": function (value) {
        _this.totalNotesCounter = value;
      }
    }
  })], 1), _c('BaseAccordion', {
    attrs: {
      "title": _vm.$t('Files'),
      "button-opens-accordion": true,
      "button-name": _vm.$t('Add'),
      "button-icon": 'plus.svg',
      "counter": _vm.property.files.length,
      "parent-name": 'property',
      "identifier": 'files'
    },
    on: {
      "buttonPressed": function ($event) {
        return _vm.fileUpload();
      }
    }
  }, [_c('FileUpload', {
    attrs: {
      "files": _vm.property.files,
      "targetId": _vm.property._id,
      "target-object": 'property',
      "showButtons": true,
      "id": 'property-general-upload'
    },
    on: {
      "success": _vm.getProperty,
      "update:files": function ($event) {
        return _vm.$set(_vm.property, "files", $event);
      }
    }
  }), _vm.property.files.length === 0 ? _c('v-row', {
    staticClass: "content",
    staticStyle: {
      "margin-top": "0px",
      "background-color": "white",
      "padding-left": "0px",
      "padding-top": "0px",
      "padding-right": "0px"
    }
  }, [_c('p', {
    staticStyle: {
      "color": "#75787a"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("NoFilesAdded")) + " ")])]) : _vm._e()], 1)], 1), _vm.property.buildings.length > 0 ? _c('v-col', {
    key: _vm.buildingsRefresher,
    staticStyle: {
      "padding": "0"
    }
  }, [_c('div', [_c('PropertyBuildingsTable', {
    attrs: {
      "property": _vm.property,
      "property-buildings": _vm.property.buildings,
      "clear-selected-units": _vm.clearSelectedUnits,
      "delete-selected-units": _vm.deleteSelectedUnits
    },
    on: {
      "addUnit": _vm.openUnitForm,
      "updateUnit": _vm.updateUnit,
      "updateUnits": _vm.updateUnits,
      "openContractPanel": _vm.openContractForm,
      "openContractDetailPanel": _vm.openContractDetailForm,
      "selectedUnits": _vm.emitSelectedUnits
    }
  })], 1)]) : _vm._e(), _vm.property.buildings.length === 0 ? _c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "center",
      "width": "100%",
      "align-items": "center"
    }
  }, [_c('p', {
    staticClass: "heading-geo32"
  }, [_vm._v(_vm._s(_vm.$t("AddABuildingToStart")))])]) : _vm._e()], 1)]), _vm.detailPanelOpen ? _c('ClientSidepanel', {
    attrs: {
      "customerData": _vm.sidePanelData,
      "from-project-general": true
    },
    on: {
      "panelClosed": function ($event) {
        return _vm.closeCustomerDetailPanel();
      }
    }
  }) : _vm._e(), _vm.editPropertyPanelOpen ? _c('AddPropertySidepanel', {
    attrs: {
      "propertyData": _vm.property,
      "from-another-panel": _vm.editPanelNested,
      "isEdit": true
    },
    on: {
      "closed": function ($event) {
        return _vm.closeSidepanelAdd();
      }
    }
  }) : _vm._e(), _vm.detailPanelOpened ? _c('PropertyDetailPanel', {
    key: _vm.propertyUpdated,
    attrs: {
      "propertyId": _vm.clickedProperty._id
    },
    on: {
      "overlayClicked": _vm.closeDetailPanel,
      "editProperty": _vm.editProperty
    }
  }) : _vm._e(), _vm.buildingDetailPanelOpen ? _c('BuildingDetailPanel', {
    attrs: {
      "building": _vm.clickedBuilding
    },
    on: {
      "overlayClicked": _vm.closeBuildingDetailPanel,
      "editBuilding": _vm.editBuilding
    }
  }) : _vm._e(), _vm.unitFormOpened ? _c('BuildingUnitsAdd', {
    attrs: {
      "select-building": true,
      "buildings": _vm.property.buildings,
      "property-id": _vm.property._id,
      "building-id": _vm.buildingId
    },
    on: {
      "saved": _vm.unitsAdded,
      "closed": function ($event) {
        return _vm.closeUnitForm();
      }
    }
  }) : _vm._e(), _vm.unitFormOpenedAddButton ? _c('BuildingUnitsAdd', {
    attrs: {
      "select-building": true,
      "property-id": _vm.property._id,
      "building-id": _vm.buildingId,
      "buildings": _vm.property.buildings
    },
    on: {
      "saved": _vm.unitsAdded,
      "closed": function ($event) {
        return _vm.unitFormClosedAddButton();
      }
    }
  }) : _vm._e(), _vm.contractFormOpened ? _c('ContractSidepanelAdd', {
    attrs: {
      "unitId": _vm.unitId,
      "propertyId": _vm.property._id,
      "buildingId": _vm.buildingId,
      "unit": _vm.unitItem
    },
    on: {
      "closed": function ($event) {
        return _vm.closeContractForm();
      }
    }
  }) : _vm._e(), _vm.editContractPanelOpen ? _c('ContractSidepanelAdd', {
    attrs: {
      "propertyId": _vm.property._id,
      "isEdit": true,
      "unitId": _vm.unit._id,
      "contractData": _vm.contract,
      "buildingId": _vm.building._id,
      "unit": _vm.unitItem
    },
    on: {
      "closed": function ($event) {
        return _vm.closeContractEditPanel();
      }
    }
  }) : _vm._e(), _vm.contractFormOpenedAddButton ? _c('ContractSidepanelAdd', {
    attrs: {
      "unitId": _vm.unitId,
      "select-items": true,
      "propertyId": _vm.property._id,
      "buildingId": _vm.buildingId,
      "buildings": _vm.property.buildings,
      "unit": _vm.unitItem
    },
    on: {
      "closed": function ($event) {
        return _vm.closeContractFormAddButton();
      }
    }
  }) : _vm._e(), _vm.contractDetailPanelOpened ? _c('ContractDetailPanel', {
    attrs: {
      "contractId": _vm.contractId,
      "contract": _vm.contract,
      "building": _vm.building,
      "unit": _vm.unit
    },
    on: {
      "editContract": _vm.editContract,
      "overlayClicked": function ($event) {
        return _vm.closeContractDetailPanel();
      }
    }
  }) : _vm._e(), _vm.buildingPanelOpened ? _c('BuildingSidepanelAdd', {
    attrs: {
      "property": _vm.property
    },
    on: {
      "closed": function ($event) {
        return _vm.closeBuildingSidepanelAdd();
      }
    }
  }) : _vm._e(), _vm.editBuildingPanelOpen ? _c('BuildingSidepanelAdd', {
    attrs: {
      "property": _vm.property,
      "buildingData": _vm.clickedBuilding,
      "isEdit": true
    },
    on: {
      "closed": function ($event) {
        return _vm.closeBuildingEditPanel();
      }
    }
  }) : _vm._e(), _vm.widgetOpen ? _c('CalendarWidget', {
    attrs: {
      "taskValue": _vm.taskValue,
      "property": _vm.property
    },
    on: {
      "update": function ($event) {
        _vm.taskUpdater++;
      },
      "close": function ($event) {
        _vm.getTasks();
        _vm.taskValue = null;
        _vm.widgetOpen = false;
      }
    }
  }) : _vm._e(), _vm.taskWidgetOpen ? _c('EditPanel', {
    attrs: {
      "clicked-task": _vm.taskWidgetTask
    },
    on: {
      "deleteTask": _vm.deleteTask,
      "updateKey": _vm.getTasks,
      "closePanel": function ($event) {
        _vm.taskWidgetOpen = false;
      }
    }
  }) : _vm._e(), _vm.clientAddOpen ? _c('AddModal', {
    staticStyle: {
      "z-index": "1200"
    },
    on: {
      "closed": function ($event) {
        _vm.clientAddOpen = false;
      },
      "newAddedContact": _vm.addContactToRelatedPeople
    }
  }) : _vm._e(), _vm.undoButton ? _c('UndoButton', {
    attrs: {
      "units-view": true,
      "building-to-undo": _vm.recentlyAddedBuildingId,
      "property-to-undo": _vm.property._id,
      "items-to-undo": _vm.recentlyAddedItems
    },
    on: {
      "timerDone": _vm.handleTimerDone,
      "undone": function ($event) {
        _vm.undoButton = false;
        _vm.$router.go(0);
      }
    }
  }) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay__inner"
  }, [_c('div', {
    staticClass: "overlay__content"
  }, [_c('span', {
    staticClass: "spinner"
  })])]);

}]

export { render, staticRenderFns }