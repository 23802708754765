var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-wrap": "nowrap",
      "justify-content": "space-between"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "4px"
    }
  }, _vm._l(_vm.buildings, function (building, index) {
    return _c('div', {
      key: 'building-' + building._id,
      staticClass: "building-tab",
      class: {
        'selected-building': building === _vm.selectedBuilding
      },
      on: {
        "click": function ($event) {
          return _vm.clickOnBuildingTab(building, index);
        }
      }
    }, [_vm._v(" " + _vm._s(building.buildingName ? building.buildingName : building.addressObjects[0].address) + " ")]);
  }), 0), _c('div', {
    staticStyle: {
      "display": "flex",
      "height": "24px",
      "gap": "8px",
      "cursor": "pointer"
    },
    on: {
      "click": _vm.addUnitButton
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/add_24_24.svg")
    }
  }), _c('div', {
    staticClass: "link",
    staticStyle: {
      "white-space": "nowrap"
    }
  }, [_vm._v(_vm._s(_vm.$t("AddUnit")))])])]), _c('div', {
    key: _vm.tableUpdateKey,
    staticClass: "table-units-container"
  }, [_c('div', {
    staticClass: "table-units-title-row"
  }, [_c('div', {
    on: {
      "click": _vm.selectAllBuildingUnits
    }
  }, [_c('Checkbox', {
    attrs: {
      "checked": _vm.selectedBuildingUnits.length === _vm.buildingUnits.length && _vm.buildingUnits.length !== 0
    }
  })], 1), _vm._l(_vm.propertyUnitFields, function (unitField) {
    return _c('div', {
      key: unitField.name,
      staticClass: "table-units-row-title label12",
      style: unitField.style,
      attrs: {
        "id": 'unit-' + unitField.className + '-title'
      },
      on: {
        "click": function () {
          return _vm.sortUnitsBy(unitField);
        }
      }
    }, [_c('div', {
      class: {
        'bold-title': unitField.sortingStep !== 0
      }
    }, [_vm._v(" " + _vm._s(_vm.$t(unitField.name)) + " ")]), _c('img', {
      class: {
        'sorting-arrow-up': unitField.sortingStep === 1,
        'sorting-arrow-down': unitField.sortingStep === 2,
        'sorting-arrow-hidden': unitField.sortingStep === 0
      },
      attrs: {
        "src": require("../../assets/images/direction=up.svg"),
        "alt": ""
      }
    })]);
  })], 2), _vm._l(_vm.buildingUnits, function (unit, index) {
    return _c('div', {
      key: 'unit-' + unit._id,
      staticClass: "table-units-row"
    }, [_c('div', {
      staticStyle: {
        "padding": "10px 0 10px 8px"
      },
      on: {
        "click": function ($event) {
          return _vm.selectBuildingUnit(unit);
        }
      }
    }, [_c('Checkbox', {
      attrs: {
        "checked": _vm.selectedBuildingUnits.includes(unit._id)
      }
    })], 1), !_vm.openedUnitEdit || _vm.openedUnitEdit.field !== 'name' ? _c('div', {
      staticClass: "content table-unit-content",
      style: _vm.propertyUnitFields[0].style,
      attrs: {
        "id": 'unit-name-' + index
      },
      on: {
        "click": function ($event) {
          return _vm.openUnitEdit(unit, 'name', index);
        }
      }
    }, [_vm._v(" " + _vm._s(unit.name ? unit.name : "-") + " ")]) : _vm._e(), _vm.openedUnitEdit && _vm.openedUnitEdit.field === 'name' ? _c('div', {
      staticStyle: {
        "width": "100%"
      },
      style: {
        minWidth: _vm.propertyUnitFields[0].style.minWidth,
        maxWidth: _vm.propertyUnitFields[0].style.minWidth,
        display: 'flex',
        alignItems: 'center'
      },
      attrs: {
        "id": "name"
      }
    }, [_c('v-text-field', {
      ref: "unitname",
      refInFor: true,
      attrs: {
        "hide-details": "",
        "height": "16",
        "outlined": "",
        "dense": ""
      },
      on: {
        "keydown": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return $event.target.blur();
        }
      },
      model: {
        value: _vm.buildingUnits[index].name,
        callback: function ($$v) {
          _vm.$set(_vm.buildingUnits[index], "name", $$v);
        },
        expression: "buildingUnits[index].name"
      }
    })], 1) : _vm._e(), !_vm.openedUnitEdit || _vm.openedUnitEdit.field !== 'floor' ? _c('div', {
      staticClass: "content table-unit-content",
      style: _vm.propertyUnitFields[1].style,
      attrs: {
        "id": 'unit-floor-' + index
      },
      on: {
        "click": function ($event) {
          return _vm.openUnitEdit(unit, 'floor', index);
        }
      }
    }, [_vm._v(" " + _vm._s(unit.floor ? unit.floor : "-") + " ")]) : _vm._e(), _vm.openedUnitEdit && _vm.openedUnitEdit.field === 'floor' ? _c('div', {
      staticStyle: {
        "width": "100%"
      },
      style: {
        minWidth: _vm.propertyUnitFields[1].style.minWidth,
        maxWidth: _vm.propertyUnitFields[1].style.minWidth,
        display: 'flex',
        alignItems: 'center'
      },
      attrs: {
        "id": "floor"
      }
    }, [_c('v-text-field', {
      ref: "unitname",
      refInFor: true,
      attrs: {
        "hide-details": "",
        "height": "16",
        "outlined": "",
        "dense": ""
      },
      on: {
        "keydown": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return $event.target.blur();
        }
      },
      model: {
        value: _vm.buildingUnits[index].floor,
        callback: function ($$v) {
          _vm.$set(_vm.buildingUnits[index], "floor", $$v);
        },
        expression: "buildingUnits[index].floor"
      }
    })], 1) : _vm._e(), !_vm.openedUnitEdit || _vm.openedUnitEdit.field !== 'size' ? _c('div', {
      staticClass: "content table-unit-content",
      style: _vm.propertyUnitFields[2].style,
      attrs: {
        "id": 'unit-size-' + index
      },
      on: {
        "click": function ($event) {
          return _vm.openUnitEdit(unit, 'size', index);
        }
      }
    }, [_vm._v(" " + _vm._s(unit.unitSize ? unit.unitSize : "-") + " ")]) : _vm._e(), _vm.openedUnitEdit && _vm.openedUnitEdit.field === 'size' ? _c('div', {
      staticStyle: {
        "width": "100%"
      },
      style: {
        minWidth: _vm.propertyUnitFields[2].style.minWidth,
        maxWidth: _vm.propertyUnitFields[2].style.minWidth,
        display: 'flex',
        alignItems: 'center'
      },
      attrs: {
        "id": "size"
      }
    }, [_c('v-text-field', {
      ref: "unitname",
      refInFor: true,
      attrs: {
        "hide-details": "",
        "height": "16",
        "outlined": "",
        "dense": ""
      },
      on: {
        "keydown": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return $event.target.blur();
        }
      },
      model: {
        value: _vm.buildingUnits[index].unitSize,
        callback: function ($$v) {
          _vm.$set(_vm.buildingUnits[index], "unitSize", $$v);
        },
        expression: "buildingUnits[index].unitSize"
      }
    })], 1) : _vm._e(), !_vm.openedUnitEdit || _vm.openedUnitEdit.field !== 'leasable' || _vm.openedUnitEdit.unit !== unit ? _c('div', {
      staticClass: "content table-unit-content",
      style: _vm.propertyUnitFields[3].style,
      attrs: {
        "id": 'unit-leasable-' + index
      },
      on: {
        "click": function ($event) {
          return _vm.openUnitEdit(unit, 'leasable', index);
        }
      }
    }, [_vm._v(" " + _vm._s(unit.leasable ? _vm.$t("Yes") : _vm.$t("No")) + " ")]) : _vm._e(), _vm.openedUnitEdit && _vm.openedUnitEdit.unit === unit && _vm.openedUnitEdit.field === 'leasable' ? _c('div', {
      staticStyle: {
        "width": "100%"
      },
      style: {
        minWidth: _vm.propertyUnitFields[3].style.minWidth,
        maxWidth: _vm.propertyUnitFields[3].style.minWidth,
        display: 'flex',
        alignItems: 'center'
      },
      attrs: {
        "id": "leasable"
      }
    }, [_c('v-select', {
      ref: "unitname",
      refInFor: true,
      attrs: {
        "items": [{
          name: _vm.$t('Yes'),
          value: true
        }, {
          name: _vm.$t('No'),
          value: false
        }],
        "item-text": "name",
        "item-value": "value",
        "hide-details": "",
        "height": "16",
        "outlined": "",
        "dense": ""
      },
      model: {
        value: _vm.buildingUnits[index].leasable,
        callback: function ($$v) {
          _vm.$set(_vm.buildingUnits[index], "leasable", $$v);
        },
        expression: "buildingUnits[index].leasable"
      }
    })], 1) : _vm._e(), unit.leasable ? [_vm._l(unit.contract, function (contract, contractIndex) {
      return _c('div', {
        key: contract._id,
        staticStyle: {
          "display": "flex"
        }
      }, [contract && !contract.contactPerson.company_name && contract.statusCode === 1 ? _c('div', {
        staticClass: "content table-unit-content",
        staticStyle: {
          "text-decoration": "underline",
          "cursor": "pointer"
        },
        style: _vm.propertyUnitFields[4].style,
        attrs: {
          "id": 'unit-tenant-' + index
        },
        on: {
          "click": function ($event) {
            return _vm.openCustomerDetailpanel(contract.contactPerson);
          }
        }
      }, [_vm._v(" " + _vm._s(contract.contactPerson.contactName) + " ")]) : _vm._e(), contract && contract.contactPerson.company_name && contract.statusCode === 1 ? _c('div', {
        staticClass: "content table-unit-content",
        staticStyle: {
          "text-decoration": "underline",
          "cursor": "pointer"
        },
        style: _vm.propertyUnitFields[4].style,
        attrs: {
          "id": 'unit-tenant-' + index
        },
        on: {
          "click": function ($event) {
            return _vm.openOrganization(contract.contactPerson);
          }
        }
      }, [_vm._v(" " + _vm._s(contract.contactPerson.company_name) + " ")]) : _vm._e(), !contract || unit.contract.every(function (cont) {
        return cont.statusCode === 0;
      }) && contractIndex === 0 ? _c('div', {
        staticClass: "content table-unit-content",
        staticStyle: {
          "white-space": "nowrap",
          "color": "#75787a !important",
          "cursor": "pointer"
        },
        style: _vm.propertyUnitFields[4].style,
        attrs: {
          "id": 'unit-tenant-' + index
        },
        on: {
          "click": function ($event) {
            return _vm.openContractPanel(unit);
          }
        }
      }, [_vm._v(" + " + _vm._s(_vm.$t("AddTenant")) + " ")]) : _vm._e(), contract.statusCode === 1 && contract.contractNumber ? _c('div', {
        staticClass: "content table-unit-content",
        staticStyle: {
          "text-decoration": "underline",
          "cursor": "pointer"
        },
        style: _vm.propertyUnitFields[5].style,
        attrs: {
          "id": 'unit-contractNumber-' + index
        },
        on: {
          "click": function ($event) {
            return _vm.openContractDetailPanel(unit, contractIndex);
          }
        }
      }, [_vm._v(" " + _vm._s(contract.contractNumber) + " ")]) : _vm._e(), unit.contract.every(function (cont) {
        return cont.statusCode === 0;
      }) && contractIndex === 0 || contract.statusCode === 1 && !contract.contractNumber ? _c('div', {
        staticClass: "content table-unit-content",
        style: _vm.propertyUnitFields[5].style,
        attrs: {
          "id": 'unit-contractNumber-' + index
        }
      }, [_vm._v(" " + _vm._s("-") + " ")]) : _vm._e(), contract.statusCode === 1 && contract.rentPrice ? _c('div', {
        staticClass: "content table-unit-content",
        style: _vm.propertyUnitFields[6].style,
        attrs: {
          "id": 'unit-rent-' + index
        }
      }, [_vm._v(" " + _vm._s(contract.rentPrice) + " ")]) : _vm._e(), unit.contract.every(function (cont) {
        return cont.statusCode === 0;
      }) && contractIndex === 0 || contract.statusCode === 1 && !contract.rentPrice ? _c('div', {
        staticClass: "content table-unit-content",
        style: _vm.propertyUnitFields[6].style,
        attrs: {
          "id": 'unit-rent-' + index
        }
      }, [_vm._v(" " + _vm._s("-") + " ")]) : _vm._e(), contract.endOfRentalPeriod && contract.statusCode === 1 ? _c('div', {
        staticClass: "content table-unit-content",
        style: _vm.propertyUnitFields[7].style,
        attrs: {
          "id": 'unit-contract-exp-' + index
        }
      }, [_vm._v(" " + _vm._s(_vm.formatDate(contract.endOfRentalPeriod)) + " ")]) : _vm._e(), unit.contract.every(function (cont) {
        return cont.statusCode === 0;
      }) && contractIndex === 0 || contract.statusCode === 1 && !contract.endOfRentalPeriod ? _c('div', {
        staticClass: "content table-unit-content",
        style: _vm.propertyUnitFields[7].style,
        attrs: {
          "id": 'unit-contract-exp-' + index
        }
      }, [_vm._v(" " + _vm._s("-") + " ")]) : _vm._e()]);
    }), unit.contract.length === 0 && unit.contract ? _c('div', {
      staticStyle: {
        "width": "100%",
        "display": "flex",
        "flex-wrap": "nowrap"
      }
    }, [_c('div', {
      staticClass: "content table-unit-content",
      staticStyle: {
        "white-space": "nowrap",
        "color": "#75787a !important",
        "cursor": "pointer"
      },
      style: _vm.propertyUnitFields[4].style,
      attrs: {
        "id": 'unit-tenant-' + index
      },
      on: {
        "click": function ($event) {
          return _vm.openContractPanel(unit);
        }
      }
    }, [_vm._v(" + " + _vm._s(_vm.$t("AddTenant")) + " ")]), _c('div', {
      staticClass: "table-unit-content",
      style: _vm.propertyUnitFields[5].style,
      attrs: {
        "id": 'unit-contractNumber-' + index
      }
    }, [_vm._v(" " + _vm._s("-") + " ")]), _c('div', {
      staticClass: "table-unit-content",
      style: _vm.propertyUnitFields[6].style,
      attrs: {
        "id": 'unit-rent-' + index
      }
    }, [_vm._v(" " + _vm._s("-") + " ")]), _c('div', {
      staticClass: "table-unit-content",
      style: _vm.propertyUnitFields[7].style,
      attrs: {
        "id": 'unit-contract-exp-' + index
      }
    }, [_vm._v(" " + _vm._s("-") + " ")])]) : _vm._e()] : _vm._e()], 2);
  }), _vm.buildingUnits.length === 0 ? _c('div', {
    staticClass: "content",
    staticStyle: {
      "height": "240px",
      "display": "flex",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("NoUnitsAddedInThisBuilding")) + " ")]) : _vm._e()], 2), _vm.orgDetailOpen ? _c('OrganizationSidepanel', {
    attrs: {
      "selected-organization": _vm.clickedOrg,
      "project-view": true
    },
    on: {
      "panelClosed": function ($event) {
        return _vm.orgDetailClose();
      }
    }
  }) : _vm._e(), _vm.detailPanelOpen ? _c('ClientSidepanel', {
    attrs: {
      "customerData": _vm.sidePanelData,
      "from-project-general": true
    },
    on: {
      "panelClosed": function ($event) {
        return _vm.closeCustomerDetailPanel();
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }