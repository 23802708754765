<template>
  <div>
    <div style="display: flex; flex-wrap: nowrap; justify-content: space-between">
      <div style="display: flex; gap: 4px">
        <div
            v-for="(building, index) in buildings"
            :key="'building-' + building._id"
            @click="clickOnBuildingTab(building, index)"
            class="building-tab"
            :class="{ 'selected-building': building === selectedBuilding }">
          {{
            building.buildingName
                ? building.buildingName
                : building.addressObjects[0].address
          }}
        </div>
      </div>
      <div
          @click="addUnitButton"
          style="display: flex; height: 24px; gap: 8px; cursor: pointer"
      >
        <img src="../../assets/images/add_24_24.svg"/>
        <div class="link" style="white-space: nowrap">{{ $t("AddUnit") }}</div>
      </div>
    </div>
    <div class="table-units-container" :key="tableUpdateKey">
      <div class="table-units-title-row">
        <div @click="selectAllBuildingUnits">
          <Checkbox
              :checked="
              selectedBuildingUnits.length === buildingUnits.length &&
              buildingUnits.length !== 0
            "
          />
        </div>
        <div
            v-for="unitField in propertyUnitFields"
            @click="() => sortUnitsBy(unitField)"
            :style="unitField.style"
            class="table-units-row-title label12"
            :id="'unit-' + unitField.className + '-title'"
            :key="unitField.name"
        >
          <div :class="{ 'bold-title': unitField.sortingStep !== 0 }">
            {{ $t(unitField.name) }}
          </div>
          <img
              :class="{
              'sorting-arrow-up': unitField.sortingStep === 1,
              'sorting-arrow-down': unitField.sortingStep === 2,
              'sorting-arrow-hidden': unitField.sortingStep === 0,
            }"
              src="../../assets/images/direction=up.svg"
              alt=""
          />
        </div>
      </div>
      <div
          class="table-units-row"
          v-for="(unit, index) in buildingUnits"
          :key="'unit-' + unit._id"
      >
        <div @click="selectBuildingUnit(unit)" style="padding: 10px 0 10px 8px">
          <Checkbox :checked="selectedBuildingUnits.includes(unit._id)"/>
        </div>
        <div
            v-if="!openedUnitEdit || openedUnitEdit.field !== 'name'"
            @click="openUnitEdit(unit, 'name', index)"
            class="content table-unit-content"
            :id="'unit-name-' + index"
            :style="propertyUnitFields[0].style"
        >
          {{ unit.name ? unit.name : "-" }}
        </div>
        <div
            style="width: 100%"
            :style="{
            minWidth: propertyUnitFields[0].style.minWidth,
            maxWidth: propertyUnitFields[0].style.minWidth,
            display: 'flex',
            alignItems: 'center',
          }"
            id="name"
            v-if="openedUnitEdit && openedUnitEdit.field === 'name'"
        >
          <v-text-field
              v-model="buildingUnits[index].name"
              @keydown.enter="$event.target.blur()"
              ref="unitname"
              hide-details
              height="16"
              outlined
              dense
          ></v-text-field>
        </div>
        <div
            v-if="!openedUnitEdit || openedUnitEdit.field !== 'floor'"
            @click="openUnitEdit(unit, 'floor', index)"
            class="content table-unit-content"
            :id="'unit-floor-' + index"
            :style="propertyUnitFields[1].style"
        >
          {{ unit.floor ? unit.floor : "-" }}
        </div>
        <div
            style="width: 100%"
            :style="{
            minWidth: propertyUnitFields[1].style.minWidth,
            maxWidth: propertyUnitFields[1].style.minWidth,
            display: 'flex',
            alignItems: 'center',
          }"
            id="floor"
            v-if="openedUnitEdit && openedUnitEdit.field === 'floor'"
        >
          <v-text-field
              v-model="buildingUnits[index].floor"
              @keydown.enter="$event.target.blur()"
              ref="unitname"
              hide-details
              height="16"
              outlined
              dense
          ></v-text-field>
        </div>
        <div
            v-if="!openedUnitEdit || openedUnitEdit.field !== 'size'"
            @click="openUnitEdit(unit, 'size', index)"
            class="content table-unit-content"
            :id="'unit-size-' + index"
            :style="propertyUnitFields[2].style"
        >
          {{ unit.unitSize ? unit.unitSize : "-" }}
        </div>
        <div
            style="width: 100%"
            :style="{
            minWidth: propertyUnitFields[2].style.minWidth,
            maxWidth: propertyUnitFields[2].style.minWidth,
            display: 'flex',
            alignItems: 'center',
          }"
            id="size"
            v-if="openedUnitEdit && openedUnitEdit.field === 'size'"
        >
          <v-text-field
              v-model="buildingUnits[index].unitSize"
              @keydown.enter="$event.target.blur()"
              ref="unitname"
              hide-details
              height="16"
              outlined
              dense
          ></v-text-field>
        </div>
        <div
            v-if="!openedUnitEdit || openedUnitEdit.field !== 'leasable' || openedUnitEdit.unit !== unit"
            @click="openUnitEdit(unit, 'leasable', index)"
            class="content table-unit-content"
            :id="'unit-leasable-' + index"
            :style="propertyUnitFields[3].style"
        >
          {{ unit.leasable ? $t("Yes") : $t("No") }}
        </div>
        <div
            style="width: 100%"
            :style="{
            minWidth: propertyUnitFields[3].style.minWidth,
            maxWidth: propertyUnitFields[3].style.minWidth,
            display: 'flex',
            alignItems: 'center',
          }"
            id="leasable"
            v-if="openedUnitEdit && openedUnitEdit.unit === unit && openedUnitEdit.field === 'leasable'"
        >
          <v-select
              v-model="buildingUnits[index].leasable"
              :items="[{name: $t('Yes'), value: true}, {name: $t('No'), value: false}]"
              item-text="name"
              item-value="value"
              ref="unitname"
              hide-details
              height="16"
              outlined
              dense
          ></v-select>
        </div>
        <template v-if="unit.leasable">
          <div
              style="display: flex"
              v-for="(contract, contractIndex) of unit.contract"
              :key="contract._id"
          >
            <div
                v-if="
                contract &&
                !contract.contactPerson.company_name &&
                contract.statusCode === 1
              "
                class="content table-unit-content"
                style="text-decoration: underline; cursor: pointer"
                @click="openCustomerDetailpanel(contract.contactPerson)"
                :id="'unit-tenant-' + index"
                :style="propertyUnitFields[4].style"
            >
              {{ contract.contactPerson.contactName }}
            </div>
            <div
                v-if="
                contract &&
                contract.contactPerson.company_name &&
                contract.statusCode === 1
              "
                @click="openOrganization(contract.contactPerson)"
                class="content table-unit-content"
                style="text-decoration: underline; cursor: pointer"
                :id="'unit-tenant-' + index"
                :style="propertyUnitFields[4].style"
            >
              {{ contract.contactPerson.company_name }}
            </div>
            <div
                v-if="
                !contract ||
                  (unit.contract.every((cont) => cont.statusCode === 0) && contractIndex === 0)
              "
                class="content table-unit-content"
                style="
                white-space: nowrap;
                color: #75787a !important;
                cursor: pointer;
              "
                @click="openContractPanel(unit)"
                :id="'unit-tenant-' + index"
                :style="propertyUnitFields[4].style"
            >
              + {{ $t("AddTenant") }}
            </div>
            <div
                v-if="contract.statusCode === 1 && contract.contractNumber"
                class="content table-unit-content"
                :id="'unit-contractNumber-' + index"
                :style="propertyUnitFields[5].style"
                @click="openContractDetailPanel(unit, contractIndex)"
                style="text-decoration: underline; cursor: pointer"
            >
              {{ contract.contractNumber }}
            </div>
            <div
                v-if="(unit.contract.every((cont) => cont.statusCode === 0) && contractIndex === 0) || (contract.statusCode === 1 && !contract.contractNumber)"
                class="content table-unit-content"
                :id="'unit-contractNumber-' + index"
                :style="propertyUnitFields[5].style"
            >
              {{ "-" }}
            </div>
            <div
                v-if="contract.statusCode === 1 && contract.rentPrice"
                class="content table-unit-content"
                :id="'unit-rent-' + index"
                :style="propertyUnitFields[6].style"
            >
              {{ contract.rentPrice }}
            </div>
            <div
                v-if="(unit.contract.every((cont) => cont.statusCode === 0) && contractIndex === 0) || (contract.statusCode === 1 && !contract.rentPrice)"
                class="content table-unit-content"
                :id="'unit-rent-' + index"
                :style="propertyUnitFields[6].style"
            >
              {{ "-" }}
            </div>
            <div
                v-if="contract.endOfRentalPeriod && contract.statusCode === 1"
                class="content table-unit-content"
                :id="'unit-contract-exp-' + index"
                :style="propertyUnitFields[7].style"
            >
              {{ formatDate(contract.endOfRentalPeriod) }}
            </div>
            <div
                v-if="(unit.contract.every((cont) => cont.statusCode === 0) && contractIndex === 0) || (contract.statusCode === 1 && !contract.endOfRentalPeriod)"
                class="content table-unit-content"
                :id="'unit-contract-exp-' + index"
                :style="propertyUnitFields[7].style">
              {{ "-" }}
            </div>
          </div>
          <div
              v-if="unit.contract.length === 0 && unit.contract"
              style="width: 100%; display: flex; flex-wrap: nowrap"
          >
            <div
                class="content table-unit-content"
                style="
                white-space: nowrap;
                color: #75787a !important;
                cursor: pointer;
              "
                @click="openContractPanel(unit)"
                :id="'unit-tenant-' + index"
                :style="propertyUnitFields[4].style"
            >
              + {{ $t("AddTenant") }}
            </div>
            <div
                class="table-unit-content"
                :id="'unit-contractNumber-' + index"
                :style="propertyUnitFields[5].style"
            >
              {{ "-" }}
            </div>
            <div
                class="table-unit-content"
                :id="'unit-rent-' + index"
                :style="propertyUnitFields[6].style"
            >
              {{ "-" }}
            </div>
            <div
                class="table-unit-content"
                :id="'unit-contract-exp-' + index"
                :style="propertyUnitFields[7].style"
            >
              {{ "-" }}
            </div>
          </div>
        </template>
      </div>
      <div
          class="content"
          style="
          height: 240px;
          display: flex;
          align-items: center;
          justify-content: center;
        "
          v-if="buildingUnits.length === 0"
      >
        {{ $t("NoUnitsAddedInThisBuilding") }}
      </div>
    </div>
    <OrganizationSidepanel
        :selected-organization="clickedOrg"
        v-if="orgDetailOpen"
        :project-view="true"
        @panelClosed="orgDetailClose()"
    />
    <ClientSidepanel
        v-if="detailPanelOpen"
        @panelClosed="closeCustomerDetailPanel()"
        :customerData="sidePanelData"
        :from-project-general="true"
    />
  </div>
</template>

<script>
import Checkbox from "@/components/common/Checkbox";
import moment from "moment";
import OrganizationSidepanel from "@/components/Organization/OrganizationSidepanel";
import ClientSidepanel from "@/components/Kliendiregister/ClientSidepanel.vue";
import axios from "axios";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "PropertyBuildingsTable",
  components: {
    Checkbox,
    OrganizationSidepanel,
    ClientSidepanel,
  },
  props: ["property", "propertyBuildings", "clearSelectedUnits", "deleteSelectedUnits"],
  data() {
    return {
      buildings: [],
      orgDetailOpen: false,
      detailPanelOpen: false,
      sidePanelData: null,
      clickedOrg: null,
      tableUpdateKey: 0,
      selectedBuilding: null,
      buildingUnits: [],
      buildingUnitsUnsorted: [],
      selectedBuildingUnits: [],
      openedUnitEdit: null,
      propertyUnitFields: [
        {
          name: "UnitName",
          className: "name",
          sortingStep: 0,
          sortingField: "name",
          style: {
            cursor: "pointer",
          },
        },
        {
          name: "Floor",
          className: "floor",
          sortingStep: 0,
          sortingField: "floor",
          style: {
            cursor: "pointer",
          },
        },
        {
          name: "SizeMeter",
          className: "size",
          sortingStep: 0,
          sortingField: "unitSize",
          style: {
            cursor: "pointer",
          },
        },
        {
          name: "Leasable",
          className: "leasable",
          sortingStep: 0,
          sortingField: "leasable",
          style: {
            cursor: "pointer",
          },
        },
        {
          name: "Tenant",
          className: "tenant",
          sortingStep: 0,
          sortingField: "tenant",
          style: {},
        },
        {
          name: "ContractNumber",
          className: "contractNumber",
          sortingStep: 0,
          sortingField: "contractNumber",
          style: {},
        },
        {
          name: "MonthlyRentInEur",
          className: "rent",
          sortingStep: 0,
          sortingField: "rentPrice",
          style: {},
        },
        {
          name: "ContractExp",
          className: "contract-exp",
          sortingStep: 0,
          sortingField: "contractExp",
          style: {},
        },
      ],
    };
  },
  watch: {
    clearSelectedUnits: function () {
      this.selectedBuildingUnits = [];
      this.emitSelectedUnits(false)
    },
    deleteSelectedUnits: async function () {
      console.log("delete units", this.selectedBuildingUnits);
      const reqBody = {
        unitIds: this.selectedBuildingUnits,
        buildingId: this.selectedBuilding._id,
      }
      await axios.post(`/api/property/units/undo/${this.property._id}`, reqBody).then((res) => {
        if (res.status === 200) {
          console.log("deleted successfully")
          const deletedUnitIds = [...this.selectedBuildingUnits];
          this.buildingUnits = this.buildingUnits.filter((unit) => !deletedUnitIds.includes(unit._id));
          this.selectedBuildingUnits = [];
          this.emitSelectedUnits(false);
          this.toastSuccess(this.$t("Unit") + " " + this.$t("Deleted").toLowerCase());
        } else {
          this.toastError(this.$t("ErrorDeleting"));
        }
      })
    },
  },
  created() {
    this.setupTable();
    console.log("UNDER MEEE");
    console.log(this.propertySelectedBuildingIndex);
  },
  mounted() {
    document.addEventListener("click", (event) => {
      if (!this.openedUnitEdit) {
        return;
      }
      let pathExists = false;
      event.path.forEach((path) => {
        if (path.id === this.openedUnitEdit.field) {
          pathExists = true;
        }
      });
      if (pathExists) {
        return;
      }
      this.saveEditedUnits();
    })
  },
  beforeDestroy() {
    document.removeEventListener("click", null);
  },
  computed: {
    ...mapGetters(["propertySelectedBuildingIndex"]),
  },
  methods: {
    ...mapActions(["setPropertySelectedBuildingIndex"]),
    async setupTable() {
      if (this.propertyBuildings && this.propertyBuildings.length) {
        if (this.propertyBuildings[this.propertySelectedBuildingIndex]) {
          await this.selectBuilding(this.propertyBuildings[this.propertySelectedBuildingIndex]);
        } else {
          await this.selectBuilding(this.propertyBuildings[0]);
        }
        this.buildings = this.propertyBuildings;
        await this.setupUnitWidths(true);
      }
    },
    async selectBuilding(building = null) {
      this.buildingUnitsUnsorted = building.units.slice(0);
      this.buildingUnits = building.units;
      this.selectedBuilding = building;
      this.selectedBuildingUnits = [];
      this.propertyUnitFields.forEach((field) => {
        field.sortingStep = 0;
      });
      // PUT BUILDING INDEX TO STATE
    },
    selectAllBuildingUnits() {
      if (this.selectedBuildingUnits.length !== this.buildingUnits.length) {
        this.buildingUnits.forEach((unit) => {
          this.selectedBuildingUnits.push(unit._id);
        });
        //remove duplicates
        this.selectedBuildingUnits = [...new Set(this.selectedBuildingUnits)];
      } else {
        this.selectedBuildingUnits = [];
      }
      this.emitSelectedUnits();
    },
    emitSelectedUnits(contractExists = true) {
      let contractPresent = false;
      if (contractExists) {
        this.buildingUnits.forEach((unit) => {
          if (this.selectedBuildingUnits.includes(unit._id)) {
            unit.contract.forEach((contract) => {
              if (contract.statusCode === 1) {
                contractPresent = true;
              }
            })
          }
        })
      }
      const emittedObject = {
        ids: this.selectedBuildingUnits,
        contractPresent: contractPresent,
      }
      this.$emit("selectedUnits", emittedObject);
    },
    selectBuildingUnit(unit) {
      if (this.selectedBuildingUnits.includes(unit._id)) {
        this.selectedBuildingUnits = this.selectedBuildingUnits.filter(
            (selectedId) => selectedId !== unit._id
        );
      } else {
        this.selectedBuildingUnits.push(unit._id);
      }
      this.emitSelectedUnits();
    },
    sortUnitsBy(unitField) {
      console.log(unitField);
      unitField.sortingStep++;
      this.propertyUnitFields.forEach((field) => {
        if (field.sortingStep === 0) {
          return;
        }
        if (
            field.sortingStep > 0 &&
            field.sortingField !== unitField.sortingField
        ) {
          field.sortingStep = 0;
        }
      });
      if (unitField.sortingStep === 1) {
        if (unitField.sortingField === "tenant") {
          this.sortUnitsByContractField("contactPerson")
        } else if (unitField.sortingField === "contractNumber") {
          this.sortUnitsByContractField("contractNumber")
        } else if (unitField.sortingField === "rentPrice") {
          this.sortUnitsByContractField("rentPrice")
        } else if (unitField.sortingField === "contractExp") {
          this.sortUnitsByContractField("endOfRentalPeriod")
        } else {
          this.sortUnitsByFieldName(unitField);
        }
        if (this.buildingUnits === this.buildingUnitsUnsorted) {
          unitField.sortingStep = 0;
        }
        setTimeout(() => this.tableUpdateKey++, 100)
        return;
      }
      if (unitField.sortingStep === 2) {
        this.buildingUnits.reverse();
        setTimeout(() => this.tableUpdateKey++, 100)
        return;
      }
      if (unitField.sortingStep > 2) {
        this.buildingUnits = [...this.buildingUnitsUnsorted];
        unitField.sortingStep = 0;
      }
      setTimeout(() => this.tableUpdateKey++, 100)
    },
    isNumeric(value) {
      return /^-?\d+$/.test(value);
    },
    openContractPanel(unit) {
      this.$emit("openContractPanel", {
        unitId: unit._id,
        buildingId: this.selectedBuilding._id,
        unitItem: unit,
      });
    },
    openContractDetailPanel(unit, contractIndex) {
      let contract = null;
      if (contractIndex) {
        contract = unit.contract[contractIndex];
      } else {
        contract = unit.contract[0];
      }

      this.$emit("openContractDetailPanel", {
        contract: contract,
        building: this.selectedBuilding,
        unit: unit,
      });
    },

    setupUnitWidths(initial = false, changedFieldIndex = null) {
      if (changedFieldIndex === null) {
        const maxList = [];
        this.buildingUnits.forEach((unit, index) => {
          this.propertyUnitFields.forEach((unitField, fieldIndex) => {
            const unitFieldElement = document.getElementById(
                "unit-" + unitField.className + "-" + index
            );
            if (unitFieldElement) {
              if (
                  !maxList[fieldIndex] ||
                  maxList[fieldIndex] < unitFieldElement.offsetWidth
              ) {
                maxList[fieldIndex] = unitFieldElement.offsetWidth;
              }
            }
          });
        });
        const sortingArrowWidth = 34;
        this.propertyUnitFields.forEach((field, index) => {
          const unitElementTitleWidth = document.getElementById(
              "unit-" + field.className + "-title"
          ).offsetWidth;
          if (initial) {
            field.initialWidth = unitElementTitleWidth + sortingArrowWidth;
            if (
                !maxList[index] ||
                maxList[index] < unitElementTitleWidth + sortingArrowWidth
            ) {
              maxList[index] = unitElementTitleWidth + sortingArrowWidth;
            }
          } else if (
              !maxList[index] ||
              maxList[index] < unitElementTitleWidth
          ) {
            maxList[index] = unitElementTitleWidth;
          }
        });
        for (let i = 0; i < this.propertyUnitFields.length; i++) {
          this.propertyUnitFields[i].style.minWidth = maxList[i] + "px";
        }
      } else {
        let max = 0;
        this.buildingUnits.forEach((unit, index) => {
          const unitFieldElement = document.getElementById(
              "unit-" +
              this.propertyUnitFields[changedFieldIndex].className +
              "-" +
              index
          );
          unitFieldElement.style.minWidth = "unset";
          if (max < unitFieldElement.offsetWidth) {
            max = unitFieldElement.offsetWidth;
          }
        });
        if (max > this.propertyUnitFields[changedFieldIndex].initialWidth) {
          this.propertyUnitFields[changedFieldIndex].style.minWidth =
              max + "px";
        } else {
          this.propertyUnitFields[changedFieldIndex].style.minWidth =
              this.propertyUnitFields[changedFieldIndex].initialWidth + "px";
        }
      }
    },
    addUnitButton() {
      this.$emit("addUnit", this.selectedBuilding._id);
    },
    openUnitEdit(unit, field, index) {
      this.openedUnitEdit = {
        unit: unit,
        field: field,
        index: index,
      };
      if (field === "leasable") {
        return;
      }
      this.$nextTick(() => {
        this.$refs.unitname[index].focus();
      })
    },
    saveEditedUnit(unit) {
      this.$emit("updateUnit", {buildingId: this.selectedBuilding._id, unit: unit})
      this.openedUnitEdit = null;
      this.tableUpdateKey++;
      setTimeout(() => {
        this.setupUnitWidths(false, 0);
        this.tableUpdateKey++;
      }, 100);
      this.tableUpdateKey++;
    },
    saveEditedUnits() {
      this.openedUnitEdit = null;
      this.$emit("updateUnits", {buildingId: this.selectedBuilding._id, units: this.selectedBuilding.units})
      this.tableUpdateKey++;
      setTimeout(() => {
        this.setupUnitWidths(false, 0);
        this.tableUpdateKey++;
      }, 100);
      this.tableUpdateKey++;
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    openOrganization(orgRegCode) {
      if (/^\d+$/.test(orgRegCode.reg_code)) {
        this.clickedOrg = orgRegCode.reg_code;
        this.orgDetailOpen = true;
        document.documentElement.style.overflow = "hidden";
      } else {
        this.clickedOrg = orgRegCode;
        this.orgDetailOpen = true;
        document.documentElement.style.overflow = "hidden";
      }
    },
    orgDetailClose() {
      this.orgDetailOpen = false;
    },
    openCustomerDetailpanel(client) {
      this.sidePanelData = client;
      this.detailPanelOpen = true;
    },
    closeCustomerDetailPanel() {
      document.documentElement.style.overflow = "hidden";
      this.detailPanelOpen = false;
    },
    async clickOnBuildingTab(building, index) {
      await this.resetSorting();
      console.log("resorted!!");
      console.log(this.buildingUnits[0]);
      console.log(this.buildingUnitsUnsorted[0]);
      await this.selectBuilding(building);
      this.tableUpdateKey++;
      setTimeout(() => {
        this.setupUnitWidths(false, 0);
        this.tableUpdateKey++;
      }, 100);
      this.tableUpdateKey++;
      this.emitSelectedUnits(false)
      this.setPropertySelectedBuildingIndex(index);
    },
    resetSorting() {
      this.selectedBuilding.units = [...this.buildingUnitsUnsorted];
      this.propertyUnitFields.forEach((field) => {
        field.sortingStep = 0;
      })
    },
    sortUnitsByFieldName(unitField) {
      this.buildingUnits = this.buildingUnits.sort((a, b) => {
        let aField = a[unitField.sortingField];
        let bField = b[unitField.sortingField];
        if (this.isNumeric(aField) || this.isNumeric(bField)) {
          aField = a[unitField.sortingField]
              ? parseInt(a[unitField.sortingField])
              : 0;
          bField = b[unitField.sortingField]
              ? parseInt(b[unitField.sortingField])
              : 0;
        } else {
          aField = a[unitField.sortingField]
              ? String(a[unitField.sortingField])
              : "";
          bField = b[unitField.sortingField]
              ? String(b[unitField.sortingField])
              : "";
        }
        if (aField < bField) {
          return 1;
        }
        if (aField > bField) {
          return -1;
        }
        return 0;
      });
    },
    sortUnitsByContractField(contractField) {
      this.buildingUnits = this.buildingUnits.sort((a, b) => {
        let aField = a["contract"] ? a["contract"].find((contract) => contract.statusCode === 1) : null;
        let bField = b["contract"] ? b["contract"].find((contract) => contract.statusCode === 1) : null;
        if (aField && bField) {
          if (contractField === "contactPerson") {
            aField = aField.contactPerson.company_name ? aField.contactPerson.company_name : aField.contactPerson.contactName;
            bField = bField.contactPerson.company_name ? bField.contactPerson.company_name : bField.contactPerson.contactName;
          } else {
            aField = aField[contractField];
            bField = bField[contractField];
            if (this.isNumeric(aField) || this.isNumeric(bField)) {
              aField = parseInt(aField);
              bField = parseInt(bField);
            } else {
              aField = String(aField);
              bField = String(bField);
            }
          }
          console.log(aField, bField);
          if (aField < bField) {
            return -1;
          }
          if (aField > bField) {
            return 1;
          }
          return 0;
        }
        if (aField && !bField) {
          return -1;
        }
        if (!aField && bField) {
          return 1;
        }
        return 0;
      })
    },
  },
};
</script>

<style scoped>
.building-tab {
  width: 144px;
  text-align: center;
  cursor: pointer;
  background-color: #f4f5f7;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 12px;
  border-top: 2px solid white;
  border-right: 2px solid white;
  border-left: 2px solid white;
  border-radius: 8px 8px 0 0;
  white-space: nowrap;
}

.selected-building {
  background-color: white;
  cursor: default;
  font-weight: 700;
}

.table-units-container {
  border: 2px solid white;
  background-color: white;
  border-radius: 0 8px 8px 8px;
}

.table-units-title-row {
  display: flex;
  height: 40px;
  padding: 4px 8px;
  border-bottom: 1px solid #e6e8ec;
}

.table-units-row {
  display: flex;
  height: 52px;
}

.table-units-row:not(:last-child) {
  border-bottom: 1px solid #e6e8ec;
}

.table-units-row-title {
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  gap: 8px;
  white-space: nowrap;
}

.table-unit-content {
  padding: 16px;
  white-space: nowrap;
}

.sorting-arrow-up {
  display: initial;
}

.sorting-arrow-down {
  display: initial;
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sorting-arrow-hidden {
  display: none;
}

.bold-title {
  font-weight: 700;
}

</style>