var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay",
    attrs: {
      "id": "overlay-contract"
    }
  }, [_c('div', {
    staticClass: "modal-wrapper",
    attrs: {
      "id": "panel-wrapper-contract"
    }
  }, [_c('div', {
    staticStyle: {
      "padding-left": "24px"
    },
    attrs: {
      "id": "wrapped-header-contract"
    },
    on: {
      "click": _vm.closeContactAdd
    }
  }, [_c('h4', {
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.isEdit ? _vm.$t("EditContract") : _vm.$t("AddContract")) + " ")])]), _c('div', {
    staticClass: "content-overlay",
    attrs: {
      "id": "content-wrapper"
    }
  }, [_c('div', {
    staticClass: "sticky-header",
    staticStyle: {
      "z-index": "126"
    }
  }, [_c('v-row', {
    staticClass: "top-row",
    staticStyle: {
      "height": "24px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [!_vm.isEdit ? _c('h4', {
    staticStyle: {
      "height": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddContract")) + " ")]) : _vm._e(), _vm.isEdit ? _c('h4', {
    staticStyle: {
      "height": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ChangeContract")) + " ")]) : _vm._e()])], 1), _c('v-divider', {
    staticStyle: {
      "top": "97px",
      "z-index": "126",
      "position": "fixed",
      "width": "560px"
    }
  }), _c('div', {
    staticStyle: {
      "padding": "121px 0px"
    }
  }, [_c('v-row', {
    class: {
      'regular-wrapper': !_vm.isEdit,
      'edit-wrapper': _vm.isEdit
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("GeneralInformation")))])]), _c('v-row', {
    staticStyle: {
      "gap": "8px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_vm.selectItems ? _c('BaseDropdown', {
    attrs: {
      "items": _vm.buildingItems,
      "show-dropdown-arrow": true,
      "dropdown-allow-null": false,
      "placeholder": _vm.$t('SelectBuilding'),
      "type": 'dropdown',
      "error": _vm.showErrors && !_vm.isFieldValid('selectedBuilding') ? 'not-valid' : '',
      "error-message": _vm.$t('FormErrors.' + _vm.errors.selectedBuilding)
    },
    model: {
      value: _vm.form.selectedBuilding,
      callback: function ($$v) {
        _vm.$set(_vm.form, "selectedBuilding", $$v);
      },
      expression: "form.selectedBuilding"
    }
  }) : _vm._e(), _vm.showUnitSelection && _vm.unitItems.length > 0 ? _c('BaseDropdown', {
    key: _vm.updateUnitList,
    attrs: {
      "items": _vm.unitItems,
      "show-dropdown-arrow": true,
      "dropdown-allow-null": false,
      "placeholder": _vm.$t('SelectUnit'),
      "type": 'dropdown',
      "error": _vm.showErrors && !_vm.isFieldValid('selectedUnit') ? 'not-valid' : '',
      "error-message": _vm.$t('FormErrors.' + _vm.errors.selectedUnit)
    },
    model: {
      value: _vm.form.selectedUnit,
      callback: function ($$v) {
        _vm.$set(_vm.form, "selectedUnit", $$v);
      },
      expression: "form.selectedUnit"
    }
  }) : _vm._e(), _c('BaseContactSearch', {
    attrs: {
      "icon-left": 'search20.svg',
      "show-dropdown-arrow": true,
      "placeholder": _vm.$t('Tenant'),
      "required": true,
      "dropdown-offset-y": 52,
      "error": _vm.showErrors && !_vm.isFieldValid('contactPerson'),
      "error-message": _vm.errors ? _vm.$t('FormErrors.' + _vm.errors.contactPerson) : ''
    },
    on: {
      "addNew": _vm.openContactAdd
    },
    model: {
      value: _vm.form.contactPerson,
      callback: function ($$v) {
        _vm.$set(_vm.form, "contactPerson", $$v);
      },
      expression: "form.contactPerson"
    }
  }), _c('BaseInput', {
    attrs: {
      "id": 'property-owner-size-field',
      "placeholder": _vm.$t('ContractNumber'),
      "required": true,
      "error": _vm.showErrors && !_vm.isFieldValid('contractNumber') ? 'not-valid' : '',
      "error-message": _vm.$t('FormErrors.' + _vm.errors.contractNumber)
    },
    model: {
      value: _vm.form.contractNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "contractNumber", $$v);
      },
      expression: "form.contractNumber"
    }
  }), _c('BaseInput', {
    attrs: {
      "id": 'property-owner-closedNetArea-field',
      "placeholder": _vm.$t('BillingEmail')
    },
    model: {
      value: _vm.form.billingEmail,
      callback: function ($$v) {
        _vm.$set(_vm.form, "billingEmail", $$v);
      },
      expression: "form.billingEmail"
    }
  }), _c('v-row', {
    staticStyle: {
      "flex-wrap": "nowrap",
      "gap": "8px",
      "margin-top": "0px"
    }
  }, [_c('BaseDropdown', {
    attrs: {
      "icon-left": "calendar_20_20.svg",
      "placeholder": _vm.$t('SigningDate'),
      "show-dropdown-arrow": true,
      "type": "date"
    },
    model: {
      value: _vm.form.signingDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "signingDate", $$v);
      },
      expression: "form.signingDate"
    }
  }), _c('BaseDropdown', {
    attrs: {
      "icon-left": "calendar_20_20.svg",
      "placeholder": _vm.$t('TransferDate'),
      "show-dropdown-arrow": true,
      "type": "date"
    },
    model: {
      value: _vm.form.transferDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "transferDate", $$v);
      },
      expression: "form.transferDate"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "flex-wrap": "nowrap",
      "gap": "8px",
      "margin-top": "0px"
    }
  }, [_c('BaseDropdown', {
    attrs: {
      "icon-left": "calendar_20_20.svg",
      "placeholder": _vm.$t('StartOfRentPayments'),
      "show-dropdown-arrow": true,
      "type": "date"
    },
    model: {
      value: _vm.form.startOfRentPayments,
      callback: function ($$v) {
        _vm.$set(_vm.form, "startOfRentPayments", $$v);
      },
      expression: "form.startOfRentPayments"
    }
  }), _c('BaseDropdown', {
    attrs: {
      "icon-left": "calendar_20_20.svg",
      "placeholder": _vm.$t('EndOfRentalPeriod'),
      "show-dropdown-arrow": true,
      "type": "date"
    },
    model: {
      value: _vm.form.endOfRentalPeriod,
      callback: function ($$v) {
        _vm.$set(_vm.form, "endOfRentalPeriod", $$v);
      },
      expression: "form.endOfRentalPeriod"
    }
  })], 1), _c('BaseDropdown', {
    attrs: {
      "icon-left": "calendar_20_20.svg",
      "placeholder": _vm.$t('GuaranteeEnds'),
      "show-dropdown-arrow": true,
      "type": "date"
    },
    model: {
      value: _vm.form.guaranteeEnds,
      callback: function ($$v) {
        _vm.$set(_vm.form, "guaranteeEnds", $$v);
      },
      expression: "form.guaranteeEnds"
    }
  }), _c('BaseDropdown', {
    attrs: {
      "icon-left": "calendar_20_20.svg",
      "placeholder": _vm.$t('InsuranceEnds'),
      "show-dropdown-arrow": true,
      "type": "date"
    },
    model: {
      value: _vm.form.insuranceEnds,
      callback: function ($$v) {
        _vm.$set(_vm.form, "insuranceEnds", $$v);
      },
      expression: "form.insuranceEnds"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "margin-top": "32px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("RentAndFees")))])]), _c('v-row', {
    staticStyle: {
      "gap": "8px",
      "margin-top": "24px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('BaseToggle', {
    attrs: {
      "label": _vm.$t('RentPerM2')
    },
    on: {
      "click": _vm.calculateRentPerMeter2
    },
    model: {
      value: _vm.form.rentPerM2,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rentPerM2", $$v);
      },
      expression: "form.rentPerM2"
    }
  }), _c('v-row', {
    staticStyle: {
      "flex-wrap": "nowrap",
      "gap": "8px",
      "margin-top": "0px"
    }
  }, [_c('BaseDropdown', {
    attrs: {
      "items": _vm.paymentIntervals,
      "placeholder": _vm.$t('PaymentInterval'),
      "show-dropdown-arrow": true
    },
    model: {
      value: _vm.form.paymentInterval,
      callback: function ($$v) {
        _vm.$set(_vm.form, "paymentInterval", $$v);
      },
      expression: "form.paymentInterval"
    }
  }), _c('BaseDropdown', {
    attrs: {
      "icon-left": "calendar_20_20.svg",
      "placeholder": _vm.$t('PaymentDate'),
      "show-dropdown-arrow": true,
      "type": "date"
    },
    model: {
      value: _vm.form.paymentDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "paymentDate", $$v);
      },
      expression: "form.paymentDate"
    }
  })], 1), !_vm.form.rentPerM2 ? _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('RentPrice'),
      "icon-right": 'euroSign.svg',
      "id": 'rent'
    },
    model: {
      value: _vm.form.rentPrice,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rentPrice", $$v);
      },
      expression: "form.rentPrice"
    }
  }) : _vm._e(), _vm.form.rentPerM2 ? _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('RentPrice'),
      "icon-right": 'euroSign.svg',
      "id": 'rent'
    },
    model: {
      value: _vm.form.rentPricePerSquareMeter,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rentPricePerSquareMeter", $$v);
      },
      expression: "form.rentPricePerSquareMeter"
    }
  }) : _vm._e(), _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('TargetRent'),
      "icon-right": 'euroSign.svg'
    },
    model: {
      value: _vm.form.targetPrice,
      callback: function ($$v) {
        _vm.$set(_vm.form, "targetPrice", $$v);
      },
      expression: "form.targetPrice"
    }
  }), _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('CleaningFee'),
      "icon-right": 'euroSign.svg'
    },
    model: {
      value: _vm.form.cleaningFee,
      callback: function ($$v) {
        _vm.$set(_vm.form, "cleaningFee", $$v);
      },
      expression: "form.cleaningFee"
    }
  }), _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('SpecialFee'),
      "icon-right": 'euroSign.svg'
    },
    model: {
      value: _vm.form.specialFee,
      callback: function ($$v) {
        _vm.$set(_vm.form, "specialFee", $$v);
      },
      expression: "form.specialFee"
    }
  })], 1), _c('div', {
    staticClass: "modal-footer"
  }, [_c('v-divider', {
    staticStyle: {
      "z-index": "111",
      "position": "fixed !important",
      "width": "560px"
    }
  }), _c('div', {
    staticClass: "buttons-row",
    staticStyle: {
      "padding-left": "24px"
    }
  }, [_vm.isEdit ? _c('button', {
    staticClass: "delete-button",
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.isDeleteModalOpen = true;
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/TrashBin.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Delete")) + " ")]) : _vm._e(), !_vm.isEdit ? _c('button', {
    staticClass: "cancel",
    on: {
      "click": function ($event) {
        return _vm.closeModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]) : _vm._e(), _vm.isEdit ? _c('button', {
    staticClass: "cancel",
    on: {
      "click": function ($event) {
        return _vm.closeModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]) : _vm._e(), _vm.isEdit ? _c('button', {
    staticClass: "save-button",
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.updateContract();
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/Submit.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")]) : _vm._e(), !_vm.isEdit ? _c('button', {
    staticClass: "save-button",
    on: {
      "click": function ($event) {
        return _vm.saveContract();
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/Submit.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")]) : _vm._e()])], 1)], 1)], 1)]), _vm.isDeleteModalOpen ? _c('DeleteModal', {
    attrs: {
      "removing": false
    },
    on: {
      "canceled": function ($event) {
        _vm.isDeleteModalOpen = false;
      },
      "approved": _vm.deleteContract
    }
  }) : _vm._e(), _vm.clientAddOpen ? _c('AddModal', {
    staticStyle: {
      "z-index": "120"
    },
    attrs: {
      "not-contact-view-route": true,
      "accordion": true
    },
    on: {
      "newAdded": _vm.chooseNewClient,
      "closed": _vm.closeContactAdd
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }