<template>
  <div class="content" id="property-content" v-if="property">
    <div id="panel-wrapper-deal" class="panel-wrapper">
      <div v-if="!property" class="overlay">
        <div class="overlay__inner">
          <div class="overlay__content"><span class="spinner"></span></div>
        </div>
      </div>
      <div
        style="
          display: flex;
          gap: 24px;
          flex-wrap: nowrap;
          padding-bottom: 24px;
        "
      >
        <BaseDetailviewContainer>
          <BaseAccordion
            @buttonPressed="openSidepanelAdd(property)"
            :counter-bg-color-red="true"
            :padding-top="'0px'"
            :title="$t('Property')"
            :parent-name="'property'"
            :button-icon="'edit_16_16.svg'"
            :identifier="'generalData'"
            :show-bot-divider="true"
            :button-name="$t('Edit')"
          >
            <div style="display: flex; gap: 16px; flex-direction: column">
              <div style="display: flex; gap: 12px; flex-direction: column">
                <div v-if="property.area" style="display: flex">
                  <p class="property-value-name content-normal">
                    {{ $t("PropertySize") }}
                  </p>
                  <p class="property-value">{{ property.area }} m²</p>
                </div>
                <div v-if="property.value" style="display: flex">
                  <p class="property-value-name content-normal">
                    {{ $t("Value") }}
                  </p>
                  <p class="property-value">{{ property.value }} €</p>
                </div>
              </div>
              <div
                style="
                  cursor: pointer;
                  text-decoration: underline;
                  gap: 8px;
                  display: flex;
                "
                @click="openDetailPanel(property)"
              >
                <img src="@/assets/images/infoMark20.svg" />
                <p class="link" style="width: 176px">
                  {{ $t("ViewFullInfo") }}
                </p>
              </div>
            </div>
          </BaseAccordion>
          <BaseAccordion
            @buttonPressed="openBuildingPanel(property)"
            :title="$t('Immovables')"
            :button-name="$t('Add')"
            :button-icon="'plus.svg'"
            :show-bot-divider="true"
            :parent-name="'property'"
            :identifier="'immovables'"
            :counter="property.buildings.length"
          >
            <div style="display: flex; flex-direction: column; gap: 24px">
              <div
                v-for="building in property.buildings"
                :key="building._id"
                class="building-info"
              >
                <div
                  @click="openBuildingDetailPanel(building)"
                  style="display: flex; gap: 8px; cursor: pointer"
                >
                  <img src="@/assets/images/building20x20.svg" alt="" />
                  <p style="text-decoration: underline">
                    {{
                      building.buildingName
                        ? building.buildingName
                        : building.addressObjects[0].address
                    }}
                  </p>
                </div>
                <div style="display: flex; flex-direction: column; gap: 12px">
                  <div style="display: flex">
                    <div class="property-value-name">{{ $t("Units") }}</div>
                    <div class="property-value">
                      {{ building.units.length }}
                    </div>
                  </div>
                  <div style="display: flex">
                    <div class="property-value-name">{{ $t("Floors") }}</div>
                    <div class="property-value">
                      {{ countMaxFloors(building.units) }}
                    </div>
                  </div>
                  <div style="display: flex">
                    <div class="property-value-name">
                      {{ $t("ActiveTenants") }}
                    </div>
                    <div class="property-value">
                      {{ building.tenantsCounter }}
                    </div>
                  </div>
                  <div style="display: flex">
                    <div class="property-value-name">{{ $t("Vacancy") }}</div>
                    <div class="property-value">
                      {{ countLeasedUnits(building.units) }}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <v-row
              class="content"
              style="
                margin-top: 0px;
                background-color: white;
                padding-left: 0px;
                padding-top: 0px;
                padding-right: 0px;
              "
              v-if="property.buildings.length === 0"
            >
              <p style="color: #75787a">
                {{ $t("NoBuildingsAdded") }}
              </p></v-row
            >
          </BaseAccordion>
          <BaseAccordion
            @buttonPressed="openTaskAddWidgetCounter++"
            :button-opens-accordion="true"
            :title="$t('TasksAndEvents')"
            :identifier="'tasks'"
            :counter-bg-color-red="true"
            :parent-name="'property'"
            :show-bot-divider="true"
            :counter="tasksCounter"
            :button-icon="'plus.svg'"
            :button-name="$t('Add')"
          >
            <BaseTasksAndEvents
              @tasks="tasksUpdated"
              :show-toggle-button="true"
              :open-new-task-add-widget="openTaskAddWidgetCounter"
              :labels-and-order="{
                overDueTasks: {
                  label: false,
                  order: 2,
                },
                noDueDateTasks: {
                  label: false,
                  order: 1,
                },
                todayTasks: {
                  label: false,
                  order: 3,
                },
                upcomingTasks: {
                  label: false,
                  order: 4,
                },
              }"
              :view="{ name: 'property', itemId: $route.params.id }"
            />
          </BaseAccordion>
          <BaseAccordion
            @buttonPressed="addNewNoteCounter++"
            :title="$t('Notes')"
            :button-opens-accordion="true"
            :button-name="$t('Add')"
            :button-icon="'plus.svg'"
            :parent-name="'property'"
            :show-bot-divider="true"
            :counter="totalNotesCounter"
            :identifier="'notes'"
          >
            <BaseNotes
              @notesCounter="
                (value) => {
                  this.totalNotesCounter = value;
                }
              "
              :add-new-note-counter="addNewNoteCounter"
              :view="{
                name: 'property',
                itemId: $route.params.id,
                itemName: property.name,
              }"
            />
          </BaseAccordion>
          <BaseAccordion
            :title="$t('Files')"
            :button-opens-accordion="true"
            @buttonPressed="fileUpload()"
            :button-name="$t('Add')"
            :button-icon="'plus.svg'"
            :counter="property.files.length"
            :parent-name="'property'"
            :identifier="'files'"
          >
            <FileUpload
              @success="getProperty"
              :files.sync="property.files"
              :targetId="property._id"
              :target-object="'property'"
              :showButtons="true"
              :id="'property-general-upload'"
            />
            <v-row
              class="content"
              style="
                margin-top: 0px;
                background-color: white;
                padding-left: 0px;
                padding-top: 0px;
                padding-right: 0px;
              "
              v-if="property.files.length === 0"
            >
              <p style="color: #75787a">
                {{ $t("NoFilesAdded") }}
              </p></v-row
            >
          </BaseAccordion>
        </BaseDetailviewContainer>
        <v-col
          v-if="property.buildings.length > 0"
          :key="buildingsRefresher"
          style="padding: 0"
        >
          <div>
            <PropertyBuildingsTable
              @addUnit="openUnitForm"
              :property="property"
              :property-buildings="property.buildings"
              :clear-selected-units="clearSelectedUnits"
              :delete-selected-units="deleteSelectedUnits"
              @updateUnit="updateUnit"
              @updateUnits="updateUnits"
              @openContractPanel="openContractForm"
              @openContractDetailPanel="openContractDetailForm"
              @selectedUnits="emitSelectedUnits"
            />
          </div>
        </v-col>
        <div
          style="
            display: flex;
            justify-content: center;
            width: 100%;
            align-items: center;
          "
          v-if="property.buildings.length === 0"
        >
          <p class="heading-geo32">{{ $t("AddABuildingToStart") }}</p>
        </div>
      </div>
    </div>
    <ClientSidepanel
      v-if="detailPanelOpen"
      @panelClosed="closeCustomerDetailPanel()"
      :customerData="sidePanelData"
      :from-project-general="true"
    />
    <AddPropertySidepanel
      v-if="editPropertyPanelOpen"
      :propertyData="property"
      :from-another-panel="editPanelNested"
      :isEdit="true"
      @closed="closeSidepanelAdd()"
    />
    <PropertyDetailPanel
      v-if="detailPanelOpened"
      @overlayClicked="closeDetailPanel"
      @editProperty="editProperty"
      :key="propertyUpdated"
      :propertyId="clickedProperty._id"
    />
    <BuildingDetailPanel
      v-if="buildingDetailPanelOpen"
      @overlayClicked="closeBuildingDetailPanel"
      @editBuilding="editBuilding"
      :building="clickedBuilding"
    />
    <BuildingUnitsAdd
      v-if="unitFormOpened"
      :select-building="true"
      :buildings="property.buildings"
      :property-id="property._id"
      :building-id="buildingId"
      @saved="unitsAdded"
      @closed="closeUnitForm()"
    />
    <BuildingUnitsAdd
      v-if="unitFormOpenedAddButton"
      :select-building="true"
      :property-id="property._id"
      :building-id="buildingId"
      :buildings="property.buildings"
      @saved="unitsAdded"
      @closed="unitFormClosedAddButton()"
    />
    <ContractSidepanelAdd
      v-if="contractFormOpened"
      @closed="closeContractForm()"
      :unitId="unitId"
      :propertyId="property._id"
      :buildingId="buildingId"
      :unit="unitItem"
    />
    <ContractSidepanelAdd
      v-if="editContractPanelOpen"
      @closed="closeContractEditPanel()"
      :propertyId="property._id"
      :isEdit="true"
      :unitId="unit._id"
      :contractData="contract"
      :buildingId="building._id"
      :unit="unitItem"
    />
    <ContractSidepanelAdd
      v-if="contractFormOpenedAddButton"
      @closed="closeContractFormAddButton()"
      :unitId="unitId"
      :select-items="true"
      :propertyId="property._id"
      :buildingId="buildingId"
      :buildings="property.buildings"
      :unit="unitItem"
    />

    <ContractDetailPanel
      v-if="contractDetailPanelOpened"
      :contractId="contractId"
      :contract="contract"
      :building="building"
      :unit="unit"
      @editContract="editContract"
      @overlayClicked="closeContractDetailPanel()"
    />
    <BuildingSidepanelAdd
      v-if="buildingPanelOpened"
      :property="property"
      @closed="closeBuildingSidepanelAdd()"
    />
    <BuildingSidepanelAdd
      v-if="editBuildingPanelOpen"
      :property="property"
      :buildingData="clickedBuilding"
      :isEdit="true"
      @closed="closeBuildingEditPanel()"
    />

    <CalendarWidget
      @update="taskUpdater++"
      v-if="widgetOpen"
      @close="
        getTasks();
        taskValue = null;
        widgetOpen = false;
      "
      :taskValue="taskValue"
      :property="property"
    />
    <EditPanel
      v-if="taskWidgetOpen"
      :clicked-task="taskWidgetTask"
      @deleteTask="deleteTask"
      @updateKey="getTasks"
      @closePanel="taskWidgetOpen = false"
    />
    <AddModal
      v-if="clientAddOpen"
      style="z-index: 1200"
      @closed="clientAddOpen = false"
      @newAddedContact="addContactToRelatedPeople"
    />
    <UndoButton
      @timerDone="handleTimerDone"
      @undone="
        undoButton = false;
        $router.go(0);
      "
      :units-view="true"
      :building-to-undo="recentlyAddedBuildingId"
      :property-to-undo="property._id"
      :items-to-undo="recentlyAddedItems"
      v-if="undoButton"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import AddPropertySidepanel from "../../../components/PropertyOwner/AddPropertySidepanel.vue";
import FileUpload from "../../common/FileUpload.vue";
import nanoid from "nanoid";
import ClientSidepanel from "../../../components/Kliendiregister/ClientSidepanel.vue";
import BuildingSidepanelAdd from "@/components/PropertyOwner/BuildingSidepanelAdd.vue";
import CalendarWidget from "@/components/Tasks/CalendarWidget";
import AddModal from "@/components/Kliendiregister/AddModal";
import EditPanel from "@/components/Calendar/EditPanel";
import moment from "moment";
import PropertyDetailPanel from "@/components/PropertyOwner/PropertyDetailPanel";
import BuildingDetailPanel from "@/components/PropertyOwner/BuildingDetailPanel";
import PropertyBuildingsTable from "@/components/PropertyOwner/PropertyBuildingsTable";
import BuildingUnitsAdd from "@/components/PropertyOwner/BuildingUnitsAdd";
import UndoButton from "@/components/common/UndoButton";
import ContractSidepanelAdd from "@/components/PropertyOwner/ContractSidepanelAdd";
import ContractDetailPanel from "@/components/PropertyOwner/ContractDetailPanel";
import BaseAccordion from "@/components/common/BaseAccordion";
import BaseDetailviewContainer from "@/components/common/BaseDetailviewContainer";
import BaseNotes from "@/components/common/BaseNotes";
import BaseTasksAndEvents from "@/components/common/BaseTasksAndEvents";

export default {
  name: "SidepanelDetail",
  props: [
    "addBuilding",
    "addUnit",
    "addContract",
    "clearSelectedUnits",
    "deleteSelectedUnits",
  ],
  components: {
    BaseTasksAndEvents,
    BaseNotes,
    BaseDetailviewContainer,
    BaseAccordion,
    PropertyBuildingsTable,
    EditPanel,
    FileUpload,
    AddPropertySidepanel,
    ClientSidepanel,
    BuildingSidepanelAdd,
    CalendarWidget,
    PropertyDetailPanel,
    AddModal,
    BuildingDetailPanel,
    BuildingUnitsAdd,
    UndoButton,
    ContractSidepanelAdd,
    ContractDetailPanel,
  },
  data: () => ({
    openTaskAddWidgetCounter: 0,
    addNewNoteCounter: 0,
    taskDropdownItems: [
      { name: "Task", value: "task", _id: nanoid(8) },
      { name: "Event", value: "event", _id: nanoid(8) },
    ],
    detailPanelOpened: false,
    clickedProperty: null,
    clickedBuilding: null,
    clickedContract: null,
    templateId: "",
    documentSidepanelOpen: false,
    documentTemplates: [],
    showCompletedTasks: false, // to state
    tasksCounter: 0,
    taskWidgetOpen: false,
    buildingDetailPanelOpen: false,
    unitFormOpened: false,
    editBuildingPanelOpen: false,
    editContractPanelOpen: false,
    taskWidgetTask: "",
    selectedPipelineStep: null,
    propertyToEdit: null,
    contractFormOpenedAddButton: false,
    stageChange: false,
    sidePanelData: null,
    clientAddOpen: false,
    taskUpdater: 0,
    taskValue: null,
    widgetOpen: false,
    dealId: 0,
    editPanelNested: false,
    interests: [],
    interestsLoaded: false,
    interestsOpen: false,
    listingPanelCloser: 0,
    interestsUpdater: 0,
    propertyLoaded: false,
    objectRefresher: 0,
    addObjectOpen: false,
    position: {},
    customer: null,
    allListings: [],
    selectedRelation: null,
    notesWithContent: [],
    noListings: false,
    archivedListingsEmpty: false,
    //listing: null,
    showFullText: false,
    marketingExpenseOptions: [],
    marketingExpensesCounter: 0,
    unfinishedTasksCounter: 0,
    allTasksCounter: 0,
    totalNotesCounter: 0,
    rowCount: 0,
    orgDetailOpen: false,
    clickedOrg: null,
    fieldReset: 0,
    notes: null,
    unitFormOpenedAddButton: false,
    showArchived: false,
    buildingPanelOpened: false,
    isDeleteModalOpen: false,
    deletable: null,
    editModalOpen: false,
    editPropertyPanelOpen: false,
    openWarningModal: false,
    timeout: null,
    undoButton: false,
    units: [],
    loading: false,
    recentlyAddedItems: null,
    recentlyAddedBuildingId: null,
    relatedCustomersModalOpen: false,
    buildingsRefresher: 1,
    searchLoaded: false,
    detailPanelOpen: false,
    notarySidepanelOpen: false,
    notaryBaseDocSidepanelOpen: false,
    contractDetailPanelOpened: false,
    contract: null,
    contractId: null,
    contractFormOpened: false,
    building: null,
    unitItem: null,
    unit: null,
    tenantsCounter: 0,
    openAmlPanel: false,
    openAmlDetail: false,
    buildingId: null,
    unitId: null,
    newNote: {
      id: null,
      content: "",
      linkedTo: null,
      parentName: null,
      createdAt: null,
      isDisabled: true,
    },
    addNewRelatedPerson: false,
    newRelatedPerson: {
      customer: null,
    },
    newNoteOpen: false,
    newTaskOpen: false,
    tasks: null,
    newTask: {
      id: nanoid(20),
      content: "",
      linkedTo: null,
      createdAt: null,
      isCompleted: false,
      parentName: null,
    },
    task: null,
    property: null,
    menu: false,
    deletedUser: null,
    editMode: false,
    dealRelatedCustomers: null,
    form: {
      name: null,
      dealType: null,
      description: null,
      amount: null,
      currency: "EUR",
      service: null,
      probability: null,
      doneDate: null,
      estimatedCloseDate: null,
      pipelineStep: null,
      contactPerson: null,
      organization: null,
      objects: [],
      objectName: null,
    },
    /* newSelfServiceUser: {
        name: "",
        contactId: "",
        email: "",
        companyId: ""
      }, */
    arrow: null,
    addMarketingExpense: null,
    chosenObject: null,
    openHouse: null,
  }),
  async created() {
    this.task = this.getEmptyTask();
    //this.showCompletedTasks = this.showCompletedProjectTasks;
    //await this.getTasks();
    this.chosenObject = this.$route.params.id;
    await Promise.all([this.getTasks(), this.getProperty()]);
  },
  mounted() {
    window.onclick = function (event) {
      if (!event.target.matches(".dropbtn")) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains("show")) {
            openDropdown.classList.remove("show");
          }
        }
      }
    };
    const ps = document.querySelectorAll("p");
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        entry.target.classList[
          entry.target.scrollHeight > entry.contentRect.height
            ? "add"
            : "remove"
        ]("truncated");
      }
    });

    ps.forEach((p) => {
      observer.observe(p);
    });
  },
  beforeDestroy() {
    this.aadressSearch = undefined;
    document.documentElement.style.overflow = "visible";
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters([
      "clientSidepanelStatus",
      "propertyAccordionsStates",
      "showCompletedProjectTasks",
    ]),
  },
  watch: {
    /*   "$route.params.id": function () {
      this.getProperty();
    }, */
    "property.notes": function () {
      this.countNotes();
    },
    addBuilding: function () {
      if (this.addBuilding) {
        this.openBuildingPanel();
      }
    },
    addUnit: function () {
      if (this.addUnit) {
        this.openUnitFormAddButton();
      }
    },
    addContract: function () {
      if (this.addContract) {
        this.openContractFormAddButton();
      }
    },
  },

  methods: {
    ...mapActions([
      "changeSidepanelStateProperty",
      "addNotesDeal",
      "setShowCompletedProjectTasks",
      "setClientSidepanel",
      "setAccordionState",
    ]),
    async unitsAdded(timeout, body) {
      this.unitFormOpened = false;
      this.unitFormClosedAddButton();
      if (timeout) {
        this.timeout = timeout;
        let buildingId = body.buildingId;
        await this.getPropertyBuildings(this.$route.params.id);
        this.recentlyAddedBuildingId = buildingId;
        this.recentlyAddedItems = body.savedUnitsIds;
        this.undoButton = true;
        //this.$emit("refresh");
        await this.getProperty(this.$route.params.id);
        this.buildingsRefresher++;
      } else {
        //this.$emit("refresh");
        await this.getProperty(this.$route.params.id);
        this.buildingsRefresher++;
      }
    },
    async getPropertyBuildings(id) {
      const resp = await axios.get(`/api/property/buildings/${id}`);
      this.units = resp.data.data.buildings;
      this.loading = false;
    },
    /*  async getUnits() {
      const resp = await axios.get(`/api/property/buildings/${this.propertyId}`);
      this.units = resp.data.data.buildings;
    }, */
    async handleTimerDone() {
      this.undoButton = false;
    },
    addEvent() {
      this.taskValue = null;
      this.widgetOpen = true;
    },
    showDropdown() {
      document.getElementById("myDropdown").classList.toggle("show");
    },
    openBuildingDetailPanel(building) {
      this.clickedBuilding = building;

      this.buildingDetailPanelOpen = true;
    },
    closeBuildingDetailPanel() {
      this.buildingDetailPanelOpen = false;
    },
    openBuildingPanel() {
      this.buildingPanelOpened = true;
    },
    closeBuildingSidepanelAdd() {
      this.buildingPanelOpened = false;
      this.$emit("closeBuilding");
    },
    editProperty() {
      this.editPanelNested = true;
      this.editPropertyPanelOpen = true;
    },
    closeBuildingEditPanel() {
      this.editBuildingPanelOpen = false;
    },
    editBuilding() {
      this.editBuildingPanelOpen = true;
    },
    editContract(value) {
      this.unitItem = value.unitItem;
      this.editContractPanelOpen = true;
    },
    closeContractEditPanel() {
      this.editContractPanelOpen = false;
    },
    openDetailPanel(property) {
      this.clickedProperty = property;

      this.detailPanelOpened = true;
    },
    closeDetailPanel() {
      this.detailPanelOpened = false;
    },
    openDeleteModal(customerId) {
      this.isDeleteModalOpen = true;
      this.deletable = customerId;
    },
    closeDeleteModal() {
      this.isDeleteModalOpen = false;
    },
    openUnitForm(id) {
      this.unitFormOpened = true;
      this.buildingId = id;
    },
    openUnitFormAddButton() {
      this.unitFormOpenedAddButton = true;
    },
    openContractForm(value) {
      this.contractFormOpened = true;
      this.unitId = value.unitId;
      this.buildingId = value.buildingId;
      this.unitItem = value.unitItem;
    },
    openContractDetailForm(value) {
      this.contractDetailPanelOpened = true;
      this.contract = value.contract;
      this.contractId = value.contract._Id;
      this.building = value.building;
      this.unit = value.unit;
    },
    closeContractDetailPanel() {
      this.contractDetailPanelOpened = false;
    },
    closeContractForm() {
      this.contractFormOpened = false;
    },
    closeUnitForm() {
      this.unitFormOpened = false;
      //this.$emit("closeUnit");
    },
    unitFormClosedAddButton() {
      this.unitFormOpenedAddButton = false;
      this.$emit("closeUnit");
    },
    closeContractFormAddButton() {
      this.contractFormOpenedAddButton = false;
      this.$emit("closeContract");
    },
    openContractFormAddButton() {
      this.contractFormOpenedAddButton = true;
    },
    countNotes() {
      let notesCounter = 0;
      this.property.notes.forEach((note) => {
        if (note.statusCode !== 0) {
          notesCounter++;
        }
      });
      this.totalNotesCounter = notesCounter;
      return this.totalNotesCounter;
    },
    countMaxFloors(units) {
      let max = Math.max.apply(
        Math,
        units.map(function (o) {
          return o.floor;
        })
      );
      if (max === Infinity || max === -Infinity) {
        return 0;
      } else {
        return max;
      }
    },
    countLeasedUnits(units) {
      let totalUnits = 0;
      let leasableUnits = 0;
      let result = 0;
      units.forEach((unit) => {
        totalUnits++;
        if (unit.leasable) {
          leasableUnits++;
        }
      });
      result = 100 - (leasableUnits / totalUnits) * 100;
      if (result.toFixed(2) === "NaN") {
        return 0;
      } else {
        return parseFloat(result.toFixed(2));
      }
    },
    /*   formatDate() {
      var allDate = this.deal.estimatedCloseDate.split(" ");
      var thisDate = allDate[0].split("-");
      var newDate = [thisDate[2], thisDate[1], thisDate[0]].join(".");
      return newDate;
    }, */
    openCustomerDetailpanel(client) {
      this.sidePanelData = client;
      this.detailPanelOpen = true;
    },
    closeCustomerDetailPanel() {
      this.detailPanelOpen = false;
    },
    openSidepanelAdd() {
      document.documentElement.style.overflow = "hidden";
      this.editPanelNested = false;
      this.editPropertyPanelOpen = true;
    },
    closeSidepanelAdd() {
      this.editPropertyPanelOpen = false;
      document.getElementById("panel-wrapper-property").style.display =
        "initial";
      this.getProperty(this.$route.params.id);
    },
    openField(note) {
      this.$set(note, "isDisabled", false);
      this.$forceUpdate();
    },
    closeFields() {
      this.notes.forEach((e) => {
        e.isDisabled = true;
      });
      this.$forceUpdate();
    },
    addNewNote(newNote) {
      if (newNote.content != "") {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear();

        today = dd + "/" + mm + "/" + yyyy;
        newNote.createdAt = today;
        this.property.notes.push(newNote);
      } else {
        return;
      }
    },

    async getNotes() {
      let propertyId = this.$route.params.id;
      const resp = await axios.get(`/api/property/notes/${propertyId}`);
      this.notes = resp.data.propertyNotes;
      let notesWithContent = [];
      this.notes.forEach((el) => {
        if (/* el.content &&  */ el.content !== "") {
          notesWithContent.push(el);
        }
      });
      this.notes = notesWithContent;
      this.notes.sort((b, a) =>
        a.createdAt > b.createdAt ? 1 : b.createdAt > a.createdAt ? -1 : 0
      );
      this.notes.forEach((e) => {
        e.isDisabled = true;
      });
      this.getProperty();
    },
    async saveNotes(notes) {
      this.notes = notes;
      try {
        this.notes.forEach((el) => {
          if (el.content == "") {
            el.statusCode = 0;
          }
        });
        this.property.notes = this.notes;

        const response = await axios.post(
          `/api/property/notes/${this.property._id}`,
          this.notes
        );
        console.log("vastus: ", response);

        this.newNote.content = "";
        await this.getNotes();
      } catch (err) {
        console.log(err);
      }
    },

    fileUpload() {
      const fileContentLoaded = setInterval(() => {
        if (document.getElementById("property-general-upload")) {
          document.getElementById("property-general-upload").click();
          clearInterval(fileContentLoaded);
        }
      }, 100);
    },
    tasksUpdated(emittedTasks) {
      this.tasks = emittedTasks;
      this.countTasks();
    },
    async saveTask(task) {
      let res = await axios.patch(`/api/task/${task._id}`, task);
      if (res.status !== 200) {
        this.toastError(this.$t("Error") + " " + res.status);
      }
      this.countTasks();
    },
    showCompletedToggleClicked() {
      this.getTasks();
      this.setShowCompletedPropertyTasks(this.showCompletedTasks);
    },
    async getTasks() {
      let response;
      if (this.showCompletedTasks) {
        response = await axios.get(
          `/api/tasks/property/${this.$route.params.id}`
        );
      } else {
        response = await axios.get(
          `/api/tasks/property/unfinished/${this.$route.params.id}`
        );
      }
      console.log(response.data);
      this.tasks = response.data;
      this.groupTasks();
      this.countTasks();
    },
    groupTasks() {
      const dateNow = moment().add(-1, "d");
      this.tasks.forEach((task) => {
        if (task.isCompleted && task.category === "task") {
          return;
        }
        if (!task.dates.endDate || task.type === "open_house") {
          task.dueDate = "none";
        } else if (this.isDateOver(task.dates, dateNow)) {
          task.dueDate = "over";
        } else {
          task.dueDate = "upcoming";
        }
      });
    },
    countTasks() {
      let taskCounter = 0;
      let currentDate = new Date(Date.now());
      const currentTime =
        currentDate.getHours() + ":" + currentDate.getMinutes();
      currentDate = currentDate.toISOString().replace("Z", "+03:00");
      this.tasks.forEach((task) => {
        if (task.category === "open_house") {
          if (!task.numberOfParticipants) {
            taskCounter++;
          }
        }
        if (task.category === "task") {
          if (!task.isCompleted) {
            taskCounter++;
          }
        }
        if (task.category === "event") {
          if (
            (task.dates.endDate >= currentDate &&
              task.dates.endTime > currentTime) ||
            (task.dates.endDate >= currentDate && !task.dates.endTime) ||
            (task.dates.endDate > currentDate && task.dates.endTime)
          ) {
            taskCounter++;
          }
        }
      });
      this.tasksCounter = taskCounter;
    },
    async deleteTask(taskId) {
      let msToken = null;
      let nylasAccessToken = null;
      if (this.user.microsoft) {
        const access = await this.getMsalAccessToken();
        if (access) {
          msToken = access.accessToken;

        }
      }
      if (this.user.nylas) {
        const accessToken = await this.getNylasAccessToken();
        if (accessToken) {
          nylasAccessToken = accessToken;
        }
      }
      const res = await axios.post(`/api/task/delete/${taskId}`, {
        accessToken: msToken,
        nylasAccessToken
      });

      if (res.status === 200) {
        this.tasks = this.tasks.filter((task) => task._id !== taskId);
        this.taskUpdater++;
        this.toastSuccess(this.$t("Deleted"));
        this.countTasks();
      } else {
        this.toastError(this.$t("ErrorDeleting"));
      }
    },
    openNewTask() {
      this.tasks.splice(0, 0, this.task);
      this.newTaskOpen = true;
    },
    async addNewTask(taskName) {
      this.newTaskOpen = false;

      if (taskName === "" || /^\s*$/.test(taskName)) {
        this.tasks.splice(0, 1);
        return;
      }

      this.mapTask(taskName);

      const saveResponse = await axios.post("/api/task", this.task);

      if (saveResponse.status === 200) {
        console.log(saveResponse.data);
      } else {
        console.log("task add failed");
      }
      this.getTasks();
      this.task = this.getEmptyTask();
    },
    mapTask(taskName) {
      this.task.content = taskName;
      this.task.linkedProperty.id = this.$route.params.id;
      this.task.linkedProperty.name = this.property.name;
      this.task.createdAt = moment().format();
    },
    getEmptyTask() {
      return {
        _id: null,
        createdAt: null,
        content: "",
        assignedTo: {
          userId: null,
          name: null,
        },
        isCompleted: false,
        type: "generic",
        category: "task",
        dates: {
          startDate: null,
          startTime: null,
          endDate: null,
          endTime: null,
        },
        linkedProperty: {
          id: null,
          name: null,
        },
        linkedContact: {
          id: null,
          name: null,
        },
        isPrivate: false,
      };
    },
    isDateOver(obsDates, dateNow) {
      const date = moment(obsDates.endDate);
      if (obsDates.endTime) {
        const endTime = obsDates.endTime.split(":");
        date.set({ h: endTime[0], m: endTime[1] });
      } else {
        date.set({ h: 0, m: 0 });
      }
      return date.isBefore(dateNow);
    },
    setUnfinishedTasksCounter(count) {
      this.unfinishedTasksCounter = count;
    },
    setAllTasksCounter(count) {
      this.allTasksCounter = count;
    },
    setMarketingExpenseCounter(count) {
      this.marketingExpensesCounter = count;
    },
    async getProperty() {
      const response = await axios.get(`/api/property/${this.chosenObject}`);
      this.property = response.data;
      this.countBuildingContracts();
      let tasks = await axios.get(`/api/tasks/property/${this.property._id}`);
      tasks = tasks.data;
      if (tasks) {
        let unfinishedCounter = 0;
        tasks.forEach((task) => {
          if (!task.isCompleted && task.content !== "") {
            unfinishedCounter++;
          }
        });
        this.unfinishedTasksCounter = unfinishedCounter;
      }
      // get tasks here
      this.propertyLoaded = true;

      //await this.getNotes();

      // this.dealId = this.deal.contactPerson.contactId;
      // console.log("DEALID SIIN", this.dealId);
      this.newNote.linkedTo = this.property._id;
      this.newTask.linkedTo = this.property._id;
      this.newNote.parentName = this.property.name;
      this.newTask.parentName = this.property.name;
    },
    countBuildingContracts() {
      this.property.buildings.forEach((building) => {
        building.tenantsCounter = 0;
        if (building.units.length) {
          building.units.forEach((unit) => {
            if (unit.contract && unit.contract.length) {
              unit.contract.forEach((contract) => {
                if (contract.length !== 0 && contract.statusCode !== 0) {
                  building.tenantsCounter++;
                }
              });
            }
          });
        }
      });
    },
    async updateUnit(value) {
      await axios
        .post(`/api/property/building/unit/${this.property._id}`, value)
        .then((res) => {
          if (res.status === 200) {
            console.log("updated successfully");
          } else {
            console.log("something went wrong");
          }
        });
    },
    async updateUnits(value) {
      await axios
        .post(`/api/property/building/units/${this.property._id}`, value)
        .then((res) => {
          if (res.status === 200) {
            console.log("Updated successfully");
          } else {
            console.log("Something went wrong");
          }
        });
    },
    emitSelectedUnits(value) {
      this.$emit("selectedUnits", value);
    },
    taskItemSelected(item) {
      console.log("here", item);
      if (item.value === "task") {
        this.openNewTask();
        return;
      }
      if (item.value === "event") {
        this.addEvent();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/global_variables";

.project-second-header {
  height: 64px;
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
}

.related-person-row {
  margin-top: 12px;
  gap: 12px !important;
  padding: 0 24px 0 0;
}

.relationIcon {
  border-radius: 50%;
  padding: 0 4px;
  background: $blue;
  height: 32px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.related-person-name {
  display: flex;
  align-items: center;
  gap: 8px;
}

.related-person-customer-name {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

#arrow-destination {
  width: 144px;
  height: 27px;
  position: absolute;
  right: 0;
  top: 0;
}

.dropdown-content {
  display: none;
  margin-top: 10px;
  margin-left: 22px;
  position: absolute;
  background-color: white;
  width: 160px;
  max-width: 160px;
  overflow: auto;
  z-index: 3;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.03),
    0px 0px 1px rgba(0, 0, 0, 0.04);

  p {
    padding: 12px 12px 12px 24px;
    font-size: 14px;
    line-height: 16px;

    &:hover {
      background-color: #f4f5f7;
      cursor: pointer;
    }
  }
}

.show {
  display: block;
}

.building-sub-info {
  padding-bottom: 24px;
}

.content {
  display: block;
  padding-left: 104px;
  padding-right: 50px;
  padding-top: 24px;
  width: 100%;
  background-color: #f4f5f7;
}

#autoresizing {
  display: block;
  overflow: hidden;
  resize: none;
}

.note-row {
  flex-flow: row;
  margin-top: 16px !important;
  cursor: pointer;
  max-width: 100%;
}

.note-text {
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 100%;
  padding-left: 4px;
  box-sizing: border-box;
}

.note-text:hover {
  cursor: pointer;
  /*
  box-shadow: inset 0px 0px 0px 1px rgb(230, 232, 236);
*/
  border-radius: 8px;
}

.createdAt {
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  margin-bottom: 0 !important;

  /* grey/grey900 */

  color: #939597;
}

.add-field {
  margin-bottom: 0 !important;
  width: 100% !important;
}

/* .notes {
  padding-left: 12px;
  padding-right: 28px;
  padding-bottom: 12px;
} */
.related-content {
  padding-left: 24px;
  padding-right: 56px;
  padding-bottom: 24px;
}

.add-notes {
  height: 50px;
  flex-direction: row;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-family: Inter;
  border-radius: 8px;
  height: 40px;
  padding: 0;
  justify-content: space-between;
}

.add-note-inside {
  width: 73px;
  height: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
}

.text-area {
  height: fit-content !important;
}

.delete {
  background-color: #ff1e24;
  color: white;
}

.theme--light.v-input--is-disabled,
.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: black !important;
}

.v-input__slot {
}

.buttons-row {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 12px 12px 12px;
  background-color: white;
  border-radius: 8px;

  .cancel {
    background-color: transparent !important;
    box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
    text-transform: none;
    padding: 12px;
    display: flex;
    align-items: center;
    height: 40px;
  }
}

.pipeline-header {
  margin-top: 48px;
  width: 30%;

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
  }
}

/* .inputFields {
  font-size: 14px;
  font-family: Inter;
  line-height: 1.2;
  gap: 8px 24px;
  display: flex;
  flex-flow: row wrap;
} */
.stageSelector {
  font-size: 14px;
  font-family: Inter;
  line-height: 1.2;
  width: 100%;
  display: flex;
  background: #ffffff;
  border-radius: 8px;
}

.stageSelector > input {
  margin: 0;
  padding: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.stageSelector > * {
  margin-right: 0px;
}

.arrowLabel {
  width: 173px;
  cursor: pointer;
  height: 40px;
  display: flex;
  position: relative;
  font-size: 12px;
  background: #e6e8ec;
  align-items: center;
  justify-content: center;
}

.stageSelector > input:checked + label:before {
  border-left-color: #75787a;
}

.arrowLabel:before {
  right: -10px;
  z-index: 2;
}

.arrowLabel:before {
  width: 0;
  bottom: 0;
  height: 0;
  content: "";
  position: absolute;
  border-top: 20px solid transparent;
  border-left: 10px solid #e6e8ec;
  border-bottom: 20px solid transparent;
}

.stageSelector > input:checked + label {
  color: #ffffff;
  background: #75787a;
}

/* .row {
  gap: 24px;
} */
.notes-info-row {
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: #ffffff;
}

.files-info-row {
  padding: 0;
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: #ffffff;
}

.notes-col {
  padding: 0;
  padding-top: 12px;
}

.top-row {
  width: 100%;
  justify-content: flex-end;
  padding: 12px;
}

.close-button {
  background: #f4f5f7;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider {
  width: 100%;

  border: 1px solid #e6e8ec;
  margin-bottom: 24px;
  margin-top: 16px;
  left: 0;
  right: 0;
}

.description {
  /* padding-left: 24px;
  padding-top: 0px;
  padding-bottom: 24px;
  padding-right: 56px; */
  border: none !important;
  outline: none !important;
  width: 100%;
  resize: none;
}

.main-wrapper {
  margin: 12px;
  border: 1px solid #e6e8ec;
  border-radius: 8px;
}

.sub-block {
  padding: 0px;
  border-bottom: 1px solid #e6e8ec;
}

.sub-block-wrapper {
  padding: 12px;
}

.no-active {
  height: 40px;
  max-width: 65%;
}

.inputFields {
  font-size: 14px;
  font-family: Inter;
  line-height: 1.2;
  gap: 8px 24px;
  display: flex;
  flex-flow: row wrap;
  padding-top: 16px;
  padding-bottom: 24px;
}

.no-active.v-input.v-input__slot {
  border-radius: 20px;
  border: 1px solid #e6e8ec !important;
}

.related-persons {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-bottom: 0 !important;
}

.contact-type {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #75787a;
}

.related-person-info {
  text-align: right;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: #000000;
}

.stageSelector {
  font-size: 14px;
  font-family: Inter;
  line-height: 1.2;
  width: 100%;
  display: flex;
}

.stageSelector > input {
  margin: 0;
  padding: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.pipeline-name {
  display: none;
  white-space: nowrap;
  margin-left: 5px;
}

.pipeline-name-white {
  display: none;
  color: white !important;
  white-space: nowrap;
  margin-left: 5px;
}

.pipeline-middle {
  display: flex;
  flex-direction: row;
  flex: 0;
  align-items: center;
  width: 52.48px;
  height: 40px;
  padding-right: 0px;
  background-color: #e6e8ec;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.deal-item-name {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: right;

  /* black */

  color: #000000;
}

.client-name {
  &:hover {
    text-decoration: underline;
  }

  cursor: pointer;
}

.pipeline-image-front {
  width: 12px;

  path {
    fill: #e6e8ec !important;
  }
}

/* ::v-deep .sub-heading {
  padding: 0;
  padding-bottom: 12px;
} */
/* ::v-deep .add-person {
  padding: 0;
  padding-top: 12px;
} */
.stageSelector > input:checked + label {
  color: #ffffff;
  background: #75787a;
}

.stageSelector > input:checked + label:before {
  border-left-color: #75787a;
}

.pipeline-left {
  position: absolute;
  left: 0px;
}

.pipeline-left-black {
  background-color: #000000 !important;
}

.pipeline-right-black {
  background-color: #000000 !important;
}

.pipeline-right {
  position: absolute;
  right: -1.2px;
}

.chosen-step-nr {
  color: white !important;
  width: 8.4px;
}

.step-nr-white {
  color: white !important;
}

.chosen-step-name {
  display: initial;
  color: white !important;
}

.chosen-step-container {
  background-color: black !important;
  width: fit-content;
  padding-right: 20px;
}

.hover-step-container {
  background-color: #fff0d4;
  width: fit-content;
  padding-right: 20px;
}

.probability {
  padding-right: 20%;
}

.customer-row {
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: #ffffff;
}

.main-info-row {
  padding: 24px;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: #ffffff;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

/* .add-person {
  padding-left: 24px;
  padding-right: 56px;
  padding-bottom: 24px;
  padding-top: 12px;
} */
.files {
  padding: 0 !important;
}

.add-persons {
  flex-direction: row;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-family: Inter;
  font-size: 12px;
  line-height: 16px;
  border-radius: 8px;
  height: 28px;
  gap: 8px;
  padding: 0;
  justify-content: space-between;
  border: 1px solid #e6e8ec;
  padding: 8px;
}

.sub-heading {
  padding: 24px;
  padding-right: 56px;
}

.deal-item {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #75787a !important;
}

.register-code {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.edit-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  height: 32px;
  gap: 8px;
  /* grey/grey500 */

  border: 1px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 8px;

  &:hover {
    background-color: #e7e7e7;
    cursor: pointer;
  }
}

.edit-button-text {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0 !important;
}

.vertical-line {
  background-color: #e6e8ec;
  width: 1px;
  height: 16px;
}

.description-item {
  text-overflow: ellipsis;
}

.description-item1 {
  border: none !important;
  outline: none !important;
  width: 376px;
  height: 52px;
  font-size: 14px;
  resize: none;
  padding-right: 16px;
  padding-bottom: 58px;
  padding-top: 0px;
  white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

::v-deep .v-text-field__slot textarea {
  padding-right: 40px !important;
}

.autoscale-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.description-item2 {
  border: none !important;
  outline: none !important;
  width: 592px;
  height: 300px;
  font-size: 14px;
  resize: none;
  padding: 12px;
  overflow: hidden;
}

::v-deep .description-textarea > .v-text-field--outlined fieldset {
  border: 0px solid red !important;
}

::v-deep .v-text-field > .v-input__control > .v-input__slot:before {
  width: 0%;
}

.activities {
  padding-left: 24px;
  padding-right: 56px;
  padding-bottom: 24px;
}

.modal-open {
  overflow-x: hidden;
  overflow-y: hidden;
}

.modal-close {
  overflow-x: auto;
  overflow-y: auto;
}

.object-link:hover {
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

::v-deep
  .ui-popup-container
  ::v-deep.ui-popup-hidden
  ::v-deep.ui-popup-truncate {
  display: none !important;
}

.amount {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  height: 24px;
  line-height: 24px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;

  /* black */

  color: #000000;
}

.hover-open-delete {
  background-image: url("../../../assets/images/trash-red.svg");
}

.save {
  background-color: #0aaf60;
  color: white;
  border-color: #e6e8ec;
}

.contactperson {
  font-size: 14px !important;
  line-height: 16px;

  &:hover {
    text-decoration: underline;
  }
}

.separator {
  border-top: 1px solid #e6e8ec;
  width: 100%;
  height: 1px;
}

.initials-text {
  display: initial;
}

.content-small-green {
  color: #0aaf60;
  max-width: 90% !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.self-service-button {
  color: #ffffff;
  background: #000000;
  border: none;
  cursor: pointer;
  //padding: 12px 12px;
  font-size: 14px;
  width: 100px;
  height: 32px;
  font-family: Inter;
  text-transform: none;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.content-small-orange {
  color: #ff5c01;
  max-width: 90% !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.content-small-yellow {
  color: #a89963;
  max-width: 90% !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.content-small-blue {
  color: #4c8dd9;
  max-width: 90% !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.content-small-pink {
  color: #f81ca0;
  max-width: 90% !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.green-font {
  background: #f2ffe0;
  border-radius: 4px;
  padding: 0px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.blue-font {
  background: #dbe8f7;
  border-radius: 4px;
  padding: 0px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.pink-font {
  background: #fff2f7;
  border-radius: 4px;
  padding: 0px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.orange-font {
  background: #fff0d4;
  border-radius: 4px;
  padding: 0px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.yellow-font {
  background: #fff0d4;
  border-radius: 4px;
  padding: 0px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.gray-font {
  background: #f4f5f7;
  border-radius: 4px;
  padding: 0px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.customer-field-row {
  gap: 24px;
}

.top16 {
  margin-top: 16px;
}

.top24 {
  margin-top: 24px;
}

.add-object-to-start {
  font-family: "Geomanist-Light", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;
  text-align: center;

  /* black */

  color: #000000;
}

.right-col {
  gap: 16px;
  max-width: calc(100% - 464px);
  background: #fff;
  border-radius: 8px;
  padding: 0;
  margin-bottom: 48px;
}

.right-col-header {
  display: flex;
  justify-content: space-between;
  padding: 32px 0px;
  align-items: center;
}

.objects-wrapper {
  display: flex;
  position: relative;
  min-width: 560px;
  //margin-top: 12px;
  padding-left: 32px;
  flex-direction: column;
  width: 100%;
  min-height: 502px;
  background-color: #f4f5f7;
}

.align-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.align-center {
  justify-content: center;
  align-items: center;
}

.generate-doc-button {
  color: #ffffff;
  background: #000000;
  border: none;
  cursor: pointer;
  //padding: 12px 12px;
  font-size: 14px;
  width: 200px;
  height: 40px;
  font-family: Inter;
  text-transform: none;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.property-value-name {
  width: 60%;
  color: #75787a;
}

.property-value {
  width: 40%;
}

.building-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
